import moment from 'moment'
import _ from 'lodash'
import I18n from 'i18n/i18n'

export const genBatchTrackingToken = () => (Math.random().toString(36).substr(2))

export const autoGenerateObjectNameWithDate = (isBatch) => {
  const now = moment(new Date(), 'X').format(I18n.t('batches.mapping_key.batch_name_format_time'))
  if (isBatch) {
    return I18n.t('batches.label.unnamed_batch_with_date', { date: now })
  }
  return I18n.t('batches.label.unnamed_booking_with_date', { date: now })
}

export const atStepThreeOrUseCredit = (bookings) => {
  let bookingsAtStepThree = false
  let bookingsUseCredit = false

  _.forEach(bookings, ({ currentStep, use_credit: useCredit }) => {
    if (currentStep === 3) {
      bookingsAtStepThree = true
    }

    if (useCredit) {
      bookingsUseCredit = true
    }
  })

  return {
    bookingsAtStepThree,
    bookingsUseCredit,
  }
}

export const getAllBookingsCreditAmount = ({ bookings, currentCustomer }) => {
  const {
    allow_post_payment: allowPostPayment,
    current_company_id: companyID,
  } = currentCustomer
  const isBpPostPaid = companyID && allowPostPayment
  const key = isBpPostPaid ? 'cashback_credit_used' : 'credit_amount'
  const totalCreditAmount = _.reduce(bookings, (total, booking) => (total + booking[key]), 0)

  return totalCreditAmount
}

export const isSmartPlanner = () => window.location.pathname?.split('/').includes('smart_load_planner')

export const calculateCreditAndCashback = (booking, currentCustomer, credit) => {
  const { creditBalance, creditWalletAmount } = credit
  if (!creditWalletAmount && !creditBalance) return booking
  const fees = booking.display_total_fees
  const companyID = currentCustomer.current_company_id
  const isBpPostPaid = companyID && currentCustomer?.allow_post_payment

  const newBooking = { ...booking }

  if (companyID) {
    // case post payment
    if (isBpPostPaid) {
      // if isBpPostPaid then creditWalletAmount is cashback
      const cashbackAmount = creditWalletAmount
      if (booking.use_credit) {
        const feesAfterCashback = fees - cashbackAmount
        if (cashbackAmount - fees >= 0) {
          newBooking.total_fees = 0
          newBooking.cashback_credit_used = fees
          newBooking.business_credit_amount = 0

          newBooking.creditWalletAmount = cashbackAmount - fees
          newBooking.creditBalance = creditBalance
        } else if (creditBalance - feesAfterCashback >= 0) {
          newBooking.total_fees = 0
          newBooking.cashback_credit_used = cashbackAmount
          newBooking.business_credit_amount = feesAfterCashback

          newBooking.creditBalance = creditBalance - feesAfterCashback
          newBooking.creditWalletAmount = 0
        } else {
          newBooking.total_fees = fees - (cashbackAmount + creditBalance)
          newBooking.cashback_credit_used = cashbackAmount
          newBooking.business_credit_amount = creditBalance

          newBooking.creditBalance = 0
          newBooking.creditWalletAmount = 0
        }
      } else {
        newBooking.cashback_credit_used = 0

        if (creditBalance - fees >= 0) {
          newBooking.total_fees = 0
          newBooking.business_credit_amount = fees

          newBooking.creditBalance = creditBalance - fees
        } else {
          newBooking.total_fees = fees - creditBalance
          newBooking.business_credit_amount = creditBalance

          newBooking.creditBalance = 0
        }

        newBooking.creditWalletAmount = cashbackAmount
      }
    // else bp
    } else if (creditWalletAmount - fees >= 0) {
      newBooking.total_fees = 0
      newBooking.business_credit_amount = fees

      newBooking.creditWalletAmount = creditWalletAmount - fees
    } else {
      newBooking.total_fees = fees - creditWalletAmount
      newBooking.business_credit_amount = creditWalletAmount

      newBooking.creditWalletAmount = 0
    }
  // Case: non-bp and use_credit is true
  } else if (booking.use_credit) {
    if (creditWalletAmount - fees >= 0) {
      newBooking.total_fees = 0
      newBooking.credit_amount = fees

      newBooking.creditWalletAmount = creditWalletAmount - fees
    } else {
      newBooking.total_fees = fees - creditWalletAmount
      newBooking.credit_amount = creditWalletAmount

      newBooking.creditWalletAmount = 0
    }
  // Case: non-bp and use_credit is false
  } else {
    newBooking.total_fees = fees
    newBooking.credit_amount = 0

    newBooking.creditWalletAmount = creditWalletAmount
  }
  return newBooking
}

export const emptyAttachment = () => {
  const attachment = {
    tmpID: _.uniqueId(),
    id: undefined,
    documentURL: undefined,
    documentType: undefined,
    documentFileName: undefined,
    allowToDelete: true,
    file: undefined,
    isAutoAttachment: false
  }
  return attachment
}
