import React, { FC, useState } from 'react'

import {
  LeftComponentContainer,
  FieldContainer,
  Field,
  Error,
  RightComponentContainer,
  WrapSecured,
  SInputWrapper,
} from './SInput'
import STypography from '../../STypography'
import { ISInput } from './ISInput'
import SIcon from '../../SIcon'
import { theme } from '../../STheme'
import { Controller, get, useFormContext } from 'react-hook-form'
import SButton from '../../SButton'

const SInput: FC<ISInput> = (props): JSX.Element => {
  const {
    id,
    type,
    label,
    name,
    disabled = false,
    placeholder,
    secured,
    LeftComponent = null,
    RightComponent = null,
    className = undefined,
    onBlur = () => undefined,
    onFocus = () => undefined,
    onPaste,
    onKeyDown = () => undefined,
    autoFocus = false,
    height = '36px',
    LeftComponentWidth,
    autoComplete = 'on',
    staticText,
    RightComponentWidth,
    onClickIcon = () => undefined,
    isShowSecured = false,
    noBorder = false,
  } = props

  const [showSecuredText, setShowSecuredText] = useState(isShowSecured)

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   onChange(event)
  //   onChangeText(event.target.value)
  // }

  const defaultInputType = type || 'text'
  const {
    formState: { errors },
    control,
  } = useFormContext()
  const error = get(errors, `${name}.message`) || ''
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <SInputWrapper className={className}>
          {label && (
            <STypography fs={15} mb={5}>
              {label}
            </STypography>
          )}
          <FieldContainer>
            {LeftComponent && (
              <LeftComponentContainer
                LeftComponentWidth={LeftComponentWidth}
                onClick={onClickIcon}
                clickable={!!onClickIcon}
              >
                {LeftComponent}
              </LeftComponentContainer>
            )}
            {staticText && <STypography mr={5}>{staticText}</STypography>}
            <WrapSecured noBorder={noBorder} error={!!error}>
              <Field
                style={{ height: height }}
                id={id}
                autoFocus={autoFocus}
                disabled={disabled}
                type={secured && !showSecuredText ? 'password' : defaultInputType}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onPaste={onPaste}
                onKeyDown={onKeyDown}
              />
              {secured && (
                <SButton type='button' w="36px" mw={36} onClick={() => setShowSecuredText((v) => !v)}>
                  <SIcon
                    onClick={() => setShowSecuredText((v) => !v)}
                    value="visibility"
                    fs={18}
                    color={showSecuredText ? theme.colors.green[1] : theme.colors.gray[5]}
                  />
                </SButton>
              )}
            </WrapSecured>
            {RightComponent && (
              <RightComponentContainer
                onClick={onClickIcon}
                clickable={!!onClickIcon}
                RightComponentWidth={RightComponentWidth}
              >
                {RightComponent}
              </RightComponentContainer>
            )}
          </FieldContainer>
          {error?.length > 0 && (
            <Error mt={5} color={theme.colors.red[1]} variant="e4">
              {error}
            </Error>
          )}
        </SInputWrapper>
      )}
    />
  )
}

export default SInput
