import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import {
  ICON_LTL_POPUP_LOGO
} from 'constants/imageConstants'
import {
  POPUP_OUT_OF_SERVICE_LTL
} from 'constants/common/popupConstants'

class OutServiceLTLPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.onS1CancelHandler = this.onS1CancelHandler.bind(this)
  }

  componentDidUpdate() {
    const { isOutServiceLTL } = this.props
    if (isOutServiceLTL) {
      $(`#${POPUP_OUT_OF_SERVICE_LTL}`).addClass('visible')
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onS1CancelHandler() {
    const { changeStatusOutService } = this.props
    $(`#${POPUP_OUT_OF_SERVICE_LTL}`).removeClass('visible')
    changeStatusOutService(false)
  }

  render() {
    const { isOutServiceLTL } = this.props
    const { fullLoadView } = this.props
    if (!fullLoadView && isOutServiceLTL) {
      return (
        <div id={POPUP_OUT_OF_SERVICE_LTL} className="visible vertical-scroll Modal modal-outOfService modal-outservice-ltl" onClick={this.onS1CancelHandler}>
          <div>
            <div className="Box Modified-Popup">
              <div className="Box-Icon">
                <img src={ICON_LTL_POPUP_LOGO} alt="icon" className="icon-modified-style" />
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal">
                <div className="title">
                  Outside Service Area
                </div>
                <div className="content">
                  Selected Address is outside our service area. Call CS for more info.
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <span />
  }
}

OutServiceLTLPopup.propTypes = {
  fullLoadView: PropTypes.bool.isRequired,
  isOutServiceLTL: PropTypes.bool,
  changeStatusOutService: PropTypes.func
}

OutServiceLTLPopup.defaultProps = {
  isOutServiceLTL: false,
  changeStatusOutService: () => undefined
}

export default OutServiceLTLPopup
