import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILocation } from 'interface/IBooking'
interface IwaypointOrder {
  order: number[]
  prevLocations: ILocation[]
}
const initialState = {} as IwaypointOrder

const waypointOrderSlice = createSlice({
  name: 'waypointOrder',
  initialState,
  reducers: {
    updateWaypointOrder: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const waypointOrderActionsCreator = waypointOrderSlice.actions

export default waypointOrderSlice
