import BatchesAPI from 'api/batches'
import { BATCH_STATUS_UNCONFIRMED, STEP_MAPPING, CREATED_FROM, DRAFT, LOADING } from 'constants/ezSpreadSheetConstants'
import * as utilBuildBookings from 'utils/ez_spread_sheet/buildBookings'
import _ from 'lodash'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import { batchTemplateActionsCreator } from 'store/toolkit/batchTemplate/batchTemplate.reducer'
import { pickupContactsActionsCreator } from 'store/toolkit/pickupContacts/pickupContacts.reducer'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
import store from 'store/store'

export const getBatchExtraInfos = async ({
  batchType,
  areaId,
  companyId,
  editDraft = false,
  loadFromSaved = false,
}) => {
  const dispatch = store.dispatch
  const params = {
    batch_type: batchType,
    area_id: areaId,
    edit_draft: editDraft,
    book_again: loadFromSaved,
    ...(companyId && { company_id: companyId }),
  }

  const { data } = await BatchesAPI.getExtraInfos(params)
  if (!_.isEmpty(data)) {
    const {
      pickup_contacts: pickupContacts,
      dynamic_texts: dynamicTexts,
      having_draft_batches: havingDraftBatches,
      having_saved_batches: havingSavedBatches,
      event,
    } = data
    const extraInfosEzSheet = {
      ...data.object,
      ezSheetExtraInfo: {
        pickupContacts,
        dynamicTexts,
        havingDraftBatches,
        havingSavedBatches,
        event,
      },
    }
    dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosEzSheet))
    dispatch(pickupContactsActionsCreator.updatePickupContacts(pickupContacts))
  }
}

export const buildBookingSaved = (batchData) => (dispatch, getState) => {
  const { extraInfos, currentCustomer } = getState()
  let tempBookings = []
  const { bookings, batch_bookings: tempData, status } = batchData
  const tempStatus = status !== DRAFT ? LOADING : status
  const options = { added_from: CREATED_FROM.batchez_again }
  if (!_.isEmpty(tempData)) {
    tempBookings = _.map(tempData, (booking) => ({
      ...booking,
      use_credit: null,
    }))
  } else if (!_.isEmpty(bookings)) {
    tempBookings = _.map(bookings, (booking) => ({
      ...booking,
      booking_extra_requirements: [],
    }))
  }
  const batchBookings = utilBuildBookings.buildBatchEzInfo(tempBookings, extraInfos, currentCustomer, options)

  dispatch(
    batchActionsCreator.updateBatch({
      attrs: {
        ...batchData,
        bookings: batchBookings,
        step: 'batch_4',
        status: tempStatus,
      },
    })
  )
}

export const getBatchInfos = async ({ batchId, loadFromSaved }) => {
  const dispatch = store.dispatch
  const { status, data } = await BatchesAPI.getBatchEzSheet(batchId)

  if (status === 200 && !_.isEmpty(data)) {
    const { object: batchRes } = data
    const stepToLoad = loadFromSaved ? STEP_MAPPING.batch_4 : STEP_MAPPING[batchRes.step]
    if (loadFromSaved) {
      delete batchRes.id
      delete batchRes.marked_as_favorite_batch
      dispatch(buildBookingSaved(batchRes))
    } else {
      dispatch(
        batchActionsCreator.updateBatch({
          attrs: {
            ...batchRes,
            status: BATCH_STATUS_UNCONFIRMED,
          },
        })
      )
    }
    dispatch(currentStepActionsCreator.changeStep(stepToLoad))

    const templateRes = await BatchesAPI.getBatchTemplate({
      templateId: batchRes.batch_template_id,
    })

    if (templateRes.status === 200 && !_.isEmpty(templateRes.data)) {
      const { object: batchTemplate } = data
      dispatch(batchTemplateActionsCreator.updateBatchTemplate(batchTemplate))
    }
  }
}

export const getMultipleExtraInfos = (areaId) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('access_token')

  const resMultiple = await BatchesAPI.getMultipleExtraInfos(areaId, accessToken)

  if (!_.isEmpty(resMultiple)) {
    const {
      pickup_contacts: pickupContacts,
      having_draft_batches: havingDraftBatches,
      having_saved_batches: havingSavedBatches,
      event,
    } = resMultiple
    const extraInfosMultiple = {
      extraInfosMultiple: {
        pickupContacts,
        havingDraftBatches,
        havingSavedBatches,
        event,
      },
    }
    dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosMultiple))
    dispatch(pickupContactsActionsCreator.updatePickupContacts(pickupContacts))
  }
}

export const updateExtraInfos = (data) => async (dispatch) => {
  dispatch(extraInfosActionsCreator.updateExtraInfos(data))
}
