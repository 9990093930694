import _ from 'lodash'
import moment from 'moment'

/* eslint-disable import/prefer-default-export */
export const findPickupContact = (pickupContacts, contactId) => {
  const pickupContact = _.find(pickupContacts, { id: contactId })
  return pickupContact
}

export const currentPickupContact = (state) => {
  const {
    pickupContacts,
    stepUploadTabsData,
    batchTemplate
  } = state
  const pickupContact = findPickupContact(pickupContacts, batchTemplate.contact_id)

  if (pickupContact) {
    return pickupContact
  }

  return _.get(stepUploadTabsData[0], 'pickupLocationSelectedObject', {})
}

export const generateColorIdx = idx => (idx < 20 ? idx : idx % 20)

export const getManualVehicles = (manualSettingVehicles, extraServices) => {
  if (_.isEmpty(manualSettingVehicles)) return []

  const { vehicleTypes } = extraServices
  const vehiclesMapping = _.reduce(vehicleTypes, (result, vehicleType) => (
    {
      ...result,
      [vehicleType.id]: { ...vehicleType }
    }
  ), {})
  const result = _.map(manualSettingVehicles, id => vehiclesMapping[id])

  return _.compact(result)
}

export const getPickupTime = (pickupTime, bufferTime) => {
  if (moment(pickupTime).isBefore(moment())) {
    return moment().add(bufferTime, 'minutes').format()
  }

  return pickupTime
}

export const isSamePickupTime = (bookings) => {
  const groupByPickup = _.groupBy(bookings, booking => booking.pickup_date_time)
  return Object.keys(groupByPickup).length === 1
}

export const correctTransiTime = (seconds) => {
  const hh = Number.parseInt(seconds / 3600, 10)
  const mm = Number.parseInt(seconds / 60, 10) - hh * 60
  return hh * 3600 + mm * 60
}

export const getPickupLocation = (pickupContact, bookings, loadDraftBatch, extraInfos) => {
  const firstLocation = _.get(bookings[0], 'locations[0]', {})
  const isLoadFromDraft = loadDraftBatch || extraInfos.is_edit_draft

  if (isLoadFromDraft || !pickupContact) {
    return {
      address: _.get(firstLocation, 'name'),
      latitude: _.get(firstLocation, 'lat'),
      longitude: _.get(firstLocation, 'lng')
    }
  }

  return pickupContact
}

export const removeCommaAndDot = (number, country) => {
  const decimalChar = _.includes(['id', 'vi'], country) ? ',' : '.'
  const numberArr = number.split(decimalChar)
  const int = numberArr[0].replace(/[.,\s]/g, '')
  const dec = numberArr.length > 1 ? numberArr[1].replace(/[.,\s]/g, '') : ''

  return Math.round(`${int}.${dec}`)
}
