import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

// UTILS
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// COMPONENTS
import DynamicPopup from '../../components/common/DynamicPopup'

// CONSTANTS
import {
  WALLET_SHOPPING,
  COPY_SHOPPING,
} from 'constants/imageConstants'
import {
  COUNTRY_ID,
  COUNTRY_TH,
  COUNTRY_PH,
  COUNTRY_VN,
} from 'constants/dashBoardAnalyticsConstants'
import _ from 'lodash'
import { copyToClipBoard } from './common'

export const ShoppingUtils = {
  minimumCreditBalance(obj) {
    const {
      rough_price_for_shopping_booking: roughPriceForShoppingBooking
    } = obj
    if (_.isUndefined(roughPriceForShoppingBooking)) {
      return 0
    }
    return (1 * roughPriceForShoppingBooking)
  },
  verifyCreditBalance(minumCredit, currentCustomer) {
    const { credit_balance: creditBalance } = currentCustomer
    if (_.isUndefined(minumCredit)) {
      return false
    }
    if (_.isUndefined(creditBalance)) {
      return true
    }
    return minumCredit > (1 * creditBalance)
  },
  verifyTimeOpenNextDay(time) {
    const currentHour = moment(new Date(), 'h:mma').unix()
    return currentHour > time
  },
  verifyTimeUtilOpen(time) {
    const currentHour = moment(new Date(), 'h:mma').unix()
    return currentHour < time
  },
  verifyTimeShopping(setting) {
    if (!_.size(setting) || !_.size(setting.shopping_hours)) {
      return false
    }

    const { shopping_hours: { closing, opening } } = setting
    return (ShoppingUtils.verifyTimeOpenNextDay(moment(closing, 'h:mma').unix())
      || ShoppingUtils.verifyTimeUtilOpen(moment(opening, 'h:mma').unix())
  )
  },
  verifyOpenShopping(setting, callback = () => undefined) {
    if (!_.size(setting)) {
      return callback(false, '')
    }
    const { closing, opening } = setting
    const formatOpening = moment(opening, 'h:mma').format('h:mma')
    if (ShoppingUtils.verifyTimeOpenNextDay(moment(closing, 'h:mma').unix())) {
      return callback(true, I18n.t('webapp.shopping.open_next_day', { time: formatOpening }))
    }
    if (ShoppingUtils.verifyTimeUtilOpen(moment(opening, 'h:mma').unix())) {
      return callback(true, I18n.t('webapp.shopping.wait_until_open_day', { time: formatOpening }))
    }
    return callback(false, '')
  },
  renderCopyIcon(value) {
    return (
      <span
        className="cur-pointer"
        onClick={() => {
          Promise.resolve(copyToClipBoard(value)).then(() => {
            toastr.success(I18n.t('webapp.action.copied'))
          })
        }}
      >
        <img src={COPY_SHOPPING} />
      </span>
    )
  },
  renderBCADefault(currentCustomer) {
    return [
      <div className="mar15">
        <p
          className="reset p m default-font White-text center"
          dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.bca_created_title_id') }}
        />
      </div>,
      currentCustomer.bca_virtual_account ? (
        <div className="center flex flex-center">
          <span className="larger-xs-font default-medium-font Yellow-text">
            {currentCustomer.bca_virtual_account}
          </span>
          <div className="ml10">
            {this.renderCopyIcon(currentCustomer.bca_virtual_account)}
          </div>
        </div>
      ) : null,
      <div className="mt15">
        <p
          className="reset p m default-font White-text center"
          dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.bca_created_description_id') }}
        />
      </div>
    ]
  },
  renderBCAPhilipines(currentCustomer) {
    return [
      <div className="mar15">
        <p
          className="reset p m default-font White-text Left-text"
          dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.bca_created_title_ph') }}
        />
      </div>,
      _.size(currentCustomer.dlvr_bank_information) ? [
        currentCustomer.dlvr_bank_information.beneficiary_name ? [
          <div className="flex flex-center relative h30px mar10">
            <div className="h1px White-bg flex-index" />
            <span className="default-font White-text center Green-bg pl15 pr15 default-title-absolute">
              {I18n.t('webapp.shopping.bca_bank_information_ph')}
            </span>
          </div>,
          <span className="default-font White-text">
            {I18n.t('webapp.shopping.bca_bank_information_name_ph')}
          </span>,
          <div className="flex flex-start mt10">
            <span className="default-font default-medium-font Yellow-text flex-index">
              {currentCustomer.dlvr_bank_information.beneficiary_name}
            </span>
            {this.renderCopyIcon(currentCustomer.dlvr_bank_information.beneficiary_name)}
          </div>
        ] : null,
        <div className="h1px White-bg mt15 mar15" />,
        <span className="default-font White-text">
          {I18n.t('webapp.shopping.bca_bank_information_account_ph')}
        </span>,
        currentCustomer.dlvr_bank_information.account_numbers.map(session => (
          <div className="flex flex-start mt15">
            <div className="flex-index">
              <span className="default-font default-medium-font White-text block pb5">
                {session.bank_name}
              </span>
              <span className="default-font default-medium-font Yellow-text">
                {session.bank_account}
              </span>
            </div>
            <div className="ml10">
              {this.renderCopyIcon(session.bank_account)}
            </div>
          </div>
        ))
      ] : null,
    ]
  },
  renderBCAThailand(currentCustomer) {
    return [
      <div className="mar15">
        <p
          className="reset p m default-font White-text center"
          dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.bca_created_title_th') }}
        />
      </div>,
      _.size(currentCustomer.dlvr_bank_information) ? [
        <div className="center">
          <p className="reset p m default-font White-text center">
            {currentCustomer.dlvr_bank_information.beneficiary_name}
          </p>
        </div>,
        currentCustomer.dlvr_bank_information.account_numbers.map(session => (
          <div className="center flex flex-center mt15">
            <div>
              <span className="default-font default-medium-font White-text block pb5">
                {session.bank_name}
              </span>
              <span className="default-font default-medium-font Yellow-text">
                {session.bank_account}
              </span>
            </div>
            <div className="ml10">
              {this.renderCopyIcon(session.bank_account)}
            </div>
          </div>
        ))
      ] : null,
      <div className="mt15">
        <p
          className="reset p m default-font White-text center"
          dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.bca_created_description_th') }}
        />
      </div>
    ]
  },
  showPopupShoppingWallet(total, currentCustomer, extraInfos, renderNode) {
    const {
      country_language: countryLanguage,
      country_code: countryCode,
      currency,
    } = extraInfos
    const { credit_balance: creditBalance } = currentCustomer
    const switchByCountry = () => {
      switch (countryCode) {
        case COUNTRY_PH:
          return this.renderBCAPhilipines(currentCustomer)
        case COUNTRY_TH:
          return this.renderBCAThailand(currentCustomer)
        default:
          return this.renderBCADefault(currentCustomer)
      }
    }

    const dynamicContent = () => (
      <div>
        <div className="h1px White-bg" />
        <div className="flex pt20">
          <span className="default-font White-text flex-index">
            {`${I18n.t('webapp.shopping.current_balance')}:`}
          </span>
          <span className="default-font White-text">
            {Utils.formatFee(creditBalance, countryLanguage, currency)}
          </span>
        </div>
        <div className="flex pt10 pb10">
          <span className="default-font White-text flex-index">
            {`${I18n.t('webapp.shopping.minimum_required')}:`}
          </span>
          <span className="default-font White-text">
            {Utils.formatFee(total, countryLanguage, currency)}
          </span>
        </div>
        <div className="Dark-Green-bg pt10 pb10 pr10 pl10 mt10 mar10 flex">
          <span className="larger-font Yellow-text flex-index">
            {`${I18n.t('webapp.shopping.deposit_at_least')}:`}
          </span>
          <span className="larger-font Yellow-text">
            {Utils.formatFee((total - creditBalance), countryLanguage, currency)}
          </span>
        </div>
        {switchByCountry()}
      </div>
    )

    const listDefaultCountry = [COUNTRY_ID, COUNTRY_VN]
    const dynamicButton = () => (
      <div className={`mt10 ${listDefaultCountry.indexOf(countryCode) !== 1 ? 'flex' : ''}`}>
        <button
          type="button"
          className={`Button gray Button-Default w100 Button-Back default-medium-font ${listDefaultCountry.indexOf(countryCode) !== 1 ? 'mr5' : ''}`}
          onClick={() => ReactDOM.unmountComponentAtNode(renderNode)}
        >
          {I18n.t('webapp.booking.back')}
        </button>
        {listDefaultCountry.indexOf(countryCode) === -1 && (
          <button
            type="button"
            className="Button white Button-Default w100 Green-text default-medium-font ml5"
            onClick={() => {
              if (countryCode === COUNTRY_TH) {
                window.fcWidget.open()
              } else {
                const url = `https://forms.transportifytech.com.ph/form/201066631839052?customerId=${currentCustomer.id}&customerName=${currentCustomer.name}&mobileNumber=${currentCustomer.phone}&source=1`
                window.open(url, '_blank')
              }
            }}
          >
            {countryCode === COUNTRY_TH ? I18n.t('webapp.tracking.chat') : I18n.t('webapp.shopping.verify_deposit')}
          </button>
        )}
      </div>
    )

    ReactDOM.render(
      <DynamicPopup
        icon={WALLET_SHOPPING}
        title={I18n.t('webapp.shopping.fund_your_shopping_wallet')}
        renderNode={renderNode}
        renderDynamicContent={dynamicContent}
        renderDynamicButton={dynamicButton}
        specialClass={countryCode === COUNTRY_PH ? 'mt60-imp pb20' : ''}
      />,
      renderNode
    )
  },

  createDebounceEventHandler(fn, delay = 300, options = {}) {
    return function debouncedHandler(event) {
      // cache target
      const target = event.target
      /* eslint-disable no-param-reassign */
      // create debounce only once
      if (!fn.cachedDebounce) {
        fn.cachedDebounce = _.debounce(() => fn(target), delay, options)
      }

      fn.cachedDebounce()
      /* eslint-enable no-param-reassign */
    }
  }
}


export { ShoppingUtils as default }
