import _ from 'lodash'
import {
  NOW,
  SCHEDULE,
  FULL_DAY,
  LONG_HAUL,
  ONE_MIN_TO_MS,
  ONE_HOUR_TO_MIN,
  QUICK_CHOICE,
} from 'constants/bookingConstants'
import { getParamFromURL } from '../booking/common'
import {
  NOW_ENABLED_SETTING,
  VISIBLE_CLASS,
  ERROR_DISTANCE_CLASS,
  FULL_DAY_MEGAZONE_CLASS,
} from 'constants/newBookingConstants'

import { TallyUtils } from '../booking/TallyUtils'
// find service type specified by "id" in list "serviceTypes"
export const serviceType = (serviceTypes, id) => _.find(serviceTypes, { id: _.toInteger(id) }) || serviceTypes[0]
export const serviceTypeIndex = (serviceTypes, id) => _.findIndex(serviceTypes, { id: _.toInteger(id) })
// find first vehicle type id of currentServiceType specified by "id" in list "serviceTypes"
export const firstVehicleTypeID = (serviceTypes, id) => {
  const currentServiceType = serviceType(serviceTypes, id)
  if (!_.isEmpty(currentServiceType) && !_.isEmpty(currentServiceType.vehicle_types)) {
    return currentServiceType.vehicle_types[0].id
  }
  return undefined
}
// find vehicle types of currentServiceType specified by "id"
export const vehicleTypes = (serviceTypes = [], id) => {
  const currentServiceType = serviceType(serviceTypes, id) || serviceTypes[0]
  if (!_.isEmpty(currentServiceType)) {
    return currentServiceType.vehicle_types
  }
  return []
}
// find vehicle types specified by "id" in list "vehicleTypes"
export const vehicleType = (vts = [], id) => _.find(vts, { id: _.toInteger(id) }) || vts[0]
// find vehiclr type by service_type_id and vehicle_type_id
export const selectedVehicleType = (serviceTypes, sid, id) => vehicleType(vehicleTypes(serviceTypes, sid), id)
// keep current time type or set it to DEFAULT when changing vehicle type
export const vehicleTypeTimeType = (st, vehicleID) => {
  const currentVehicleType = vehicleType(st.vehicle_types, vehicleID)
  const canBookFullDay = currentVehicleType?.settings?.full_day_enabled && !st.is_package
  const canBookSchedule = currentVehicleType?.settings?.schedule_enabled
  const canBookImmediate = currentVehicleType?.settings?.immediate_enabled
  let result = FULL_DAY
  const defaultTimeType = currentVehicleType.quick_choices.length > 0 ? QUICK_CHOICE : NOW
  if (canBookFullDay) {
    result = FULL_DAY
  }
  if (canBookSchedule) {
    result = SCHEDULE
  }
  if (canBookImmediate) {
    result = defaultTimeType
  }
  return result
}

export const verifyTimeType = (vehicleTypeTemp, timeType, quickChoiceID = 0) => {
  const settings = vehicleTypeTemp?.settings
  const quickChoices = vehicleTypeTemp?.quick_choices

  if (_.isEmpty(timeType) || timeType === QUICK_CHOICE) {
    return true
  }
  if (quickChoiceID > 0) {
    return quickChoices.length > 0
  }
  let enableKey
  if (timeType === NOW) {
    enableKey = NOW_ENABLED_SETTING
  } else {
    enableKey = `${timeType}_enabled`
  }
  return settings?.[enableKey]
}

export const verifyPickupTime = (settings={}, pickupTime, timeType) => {
  const minimumLongHaul = settings.minimum_pickup_time_longhaul || settings.minimum_pickup_time_fpr || 0
  let minutes = 0
  switch (timeType) {
    case SCHEDULE:
      minutes = settings.minimum_pickup_time_schedule
      break
    case FULL_DAY:
      minutes = settings.minimum_pickup_time_fullday
      break
    case LONG_HAUL:
      // Minimum Pickup Time Longhaul Unit is hour
      minutes = minimumLongHaul * ONE_HOUR_TO_MIN
      break
    default:
  }

  const pickupTimeTemp = new Date(pickupTime)
  const now = new Date()
  const comparingTime = pickupTimeTemp - now
  return (comparingTime > (minutes * ONE_MIN_TO_MS)
    || _.isEmpty(timeType)
    || timeType === NOW
    || timeType === QUICK_CHOICE)
}

export const quickChoice = (quickChoices, id) => _.find(quickChoices, { id: _.toInteger(id) })

// calculate price of booking basing on step
export const calculateDisplayTotalFeePerBooking = (booking, currentVehicleType, isTotal) => {
  const { currentStep } = booking
  let price = 0

  if ((currentStep === 1 || currentStep === 2)) {
    price = TallyUtils.calculateTallyPrice({
      locations: booking.locations_attributes,
      vehicleTypeID: booking.vehicle_type_id,
      timeType: booking.time_type,
    }, { booking, specificStep: booking.currentStep, vehicleType: currentVehicleType })
  } else if (isTotal) {
    price = booking.total_fees
  } else {
    price = booking.display_total_fees
  }

  price = _.isFinite(price) ? price : 0

  return price
}

export const passTallyBatchBookingToBooking = (bookings, batchBookings) => _.map(bookings, (booking) => {
  const batchBooking = _.find(batchBookings, { id: _.toInteger(booking.id) })
  if (_.isUndefined(batchBooking)) {
    return _.assign({}, booking)
  }

  let tallyField = {}
  if (batchBooking.tally_enabled === true) {
    tallyField = {
      tally_enabled: batchBooking.tally_enabled,
      original_estimate_values: batchBooking.original_estimate_values,
      current_estimate_values: batchBooking.current_estimate_values,
      current_estimate_status: batchBooking.current_estimate_status,
      vehicle_type_threshold_early_delay_status: batchBooking.vehicle_type_threshold_early_delay_status,
      last_drop_off_arrived_at: batchBooking.last_drop_off_arrived_at,
    }
  }
  return _.assign({}, booking, { ...tallyField })
})

export const generateRouteDetail = (booking) => {
  const { distance_fee_details: distanceDetail } = booking
  let numLocation = 0
  const result = _.groupBy(distanceDetail.routes, (route) => {
    numLocation += route.details.length
    return route.location_long_haul_area_id
  })
  const numZone = Object.keys(result).length

  if (numZone > 1) {
    return {
      numLocation,
      numZone,
    }
  }

  return {
    numLocation
  }
}

export const isBookMultipleAgain = () => getParamFromURL('book_again') || false
export const isBookDraftMultipleAgain = () => getParamFromURL('draft_booking_id') || false

export const checkCashbackUpdate = (index, prevBooking, bookings) => {
  const isChangeLocation = !_.isEqual(
    prevBooking[index]?.locations_attributes,
    bookings[index]?.locations_attributes
  )
  const isChangeTimeType = !_.isEqual(
    prevBooking[index]?.time_type,
    bookings[index]?.time_type
  )
  const isChangeVehicleTypeId = !_.isEqual(
    prevBooking[index]?.vehicle_type_id,
    bookings[index]?.vehicle_type_id
  )
  return isChangeLocation || isChangeTimeType || isChangeVehicleTypeId
}

export const toggleShowTooltipError = ({
  popupNormal, popupError, isError, isErrorDistance, isErrorFDMegazone
}) => {
  const normalClassName = popupNormal.className
  const errorClassName = _.replace(_.replace(popupError.className, ERROR_DISTANCE_CLASS, ''), FULL_DAY_MEGAZONE_CLASS, '')
  if (_.includes(normalClassName, 'visible') && isError) {
    popupNormal.className = _.replace(normalClassName, VISIBLE_CLASS, '')
    let popupErrorClassName = `${errorClassName}${VISIBLE_CLASS}`
    if (isErrorDistance || isErrorFDMegazone) {
      popupErrorClassName = `${popupErrorClassName}${isErrorDistance ? ERROR_DISTANCE_CLASS : FULL_DAY_MEGAZONE_CLASS}`
    }

    popupError.className = popupErrorClassName
    this.setState({
      isRerender: true,
    })
  }
  if (!_.includes(normalClassName, 'visible') && isError) {
    if (isErrorFDMegazone) {
      popupError.className = `${errorClassName}${FULL_DAY_MEGAZONE_CLASS}`
    } else {
      popupError.className = _.replace(errorClassName, FULL_DAY_MEGAZONE_CLASS, '')
    }
  }
  if (_.includes(errorClassName, 'visible') && !isError) {
    popupError.className = _.replace(_.replace(_.replace(errorClassName, ERROR_DISTANCE_CLASS, ''), FULL_DAY_MEGAZONE_CLASS, ''), VISIBLE_CLASS, '')
    popupNormal.className = `${normalClassName}${VISIBLE_CLASS}`
  }
}