import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {
  locationMarker: {},
  driverMarker: {},
  driverInfoBox: {},
  activeMarker: {},
  prevDriverLocation: {}
}
const batchTrackingMarkersSlice = createSlice({
  name: 'batchTrackingMarkers',
  initialState,
  reducers: {
    trackingUpdateBatchLocationMarker: (state, action: PayloadAction<any>) => {
      if (state.locationMarker[action.payload.bookingID]) {
        if (action.payload.isGoogleMap) {
          state.locationMarker[action.payload.bookingID].setMap(null)
        } else {
          state.locationMarker[action.payload.bookingID].remove()
        }
      }
      state.locationMarker[action.payload.bookingID] = action.payload.marker
    },
    trackingUpdateBatchDriverMarker: (state, action: PayloadAction<any>) => {
      if (state.driverMarker[action.payload.bookingID]) {
        if (action.payload.isGoogleMap) {
          state.driverMarker[action.payload.bookingID].setMap(null)
        } else {
          state.driverMarker[action.payload.bookingID].remove()
        }
      }
      state.driverMarker[action.payload.bookingID] = action.payload.marker
    },
    updateDriverInfoBox: (state, action: PayloadAction<any>) => {
      state.driverInfoBox[action.payload.bookingID] = action.payload.infoBox
    },
    trackingUpdateBatchPrevDriverLocation:(state, action: PayloadAction<any>) =>  {
      state.prevDriverLocation[action.payload.bookingID] = action.payload.value
    },
    trackingBatchUpdateActiveMarker: (state, action: PayloadAction<any>) =>{
      if (!_.isEmpty(state.activeMarker)) {
        if (action.payload.isGoogleMap) {
          state.activeMarker.setMap(null)
        } else {
          state.activeMarker.remove()
        }
      }
      state.activeMarker = action.payload.activeMarker
    }
  },
  extraReducers: () => {},
})

export const batchTrackingMarkersActions = batchTrackingMarkersSlice.actions

export default batchTrackingMarkersSlice
