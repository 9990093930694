import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import CustomerAPI from 'api/customers'
import FormContainer from 'components/common/FormInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SInput from 'components/common/Styled/Form/SInput'
import CommonUtils from 'utils/common'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import { ContentWrapper, IconModal } from './Sindex'
import {  useNavigate } from 'react-router-dom'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { useAppDispatch } from 'store/store'

const ConfirmNewPassword: React.FC<{
  prePassword: string
  isNoStep3?:boolean
}> = ({ prePassword, isNoStep3 }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const methods = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(
      yup.object({
        password: yup
          .string().trim()
          .required(t('validations.required_password'))
          .min(6, t('validations.minlength', { number: 6 })),
        confirmPassword: yup
          .string().trim()
          .required(t('validations.required_password'))
          .min(6, t('validations.minlength', { number: 6 }))
          .oneOf([yup.ref('password'), null], t('validations.equalTo')),
      })
    ),
  })
  const dispatch = useAppDispatch()

  const handleSubmit = async (e: { password: string }) => {
    const newParams = {
      password: e.password,
      current_password: prePassword,
    }
    await CustomerAPI.updateCustomer(newParams)
    dispatch(accountManageActions.updateModalAccountManage(''))
    if (isNoStep3) navigate('/')
  }
  const LOGO_ICON = CommonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  return (
    <ModalNormal topLevel={!isNoStep3} closeVariant="g1" customFixedWidth={'420px'} backgroundColor={theme.colors.white[1]} isOpen>
      <ContentWrapper>
        <IconModal>
          <img src={LOGO_ICON} width={75} alt="LOGO_ICON" />
        </IconModal>
        <STypography align="center" fw={700} mb={20} color={theme.colors.green[1]} fs={19}>
          {t('webapp.label.change_password')}
        </STypography>
        <FormContainer methods={methods} onSubmit={(e) => handleSubmit(e)}>
          <SInput label={t('devise_template.new_password')} secured isShowSecured name="password" />
          <SInput label={t('devise_template.confirm_new_password')} secured isShowSecured name="confirmPassword" />
          <SButton h="36px" mt={20} w="100%" variant="g2" type="submit">
            {t('devise_template.change_my_password')}
          </SButton>
        </FormContainer>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default ConfirmNewPassword
