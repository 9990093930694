import {
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_FLEET_ACCEPT_BOOKING,
  STATUS_CANCELLED,
} from '../constants/bookingConstants'
import CommonUtils from './common'

export const paymentMethodSelected = (paymentMethod, bankName) => {
  const eventParams = {
    'Payment method': paymentMethod,
    ...(bankName && { 'Bank name': bankName }),
  }
  window.Moengage.track_event('Payment method selected', eventParams)
}

export const clickVANumberCopy = () => window.Moengage.track_event('VA number copy')

export const paymentInstructionsRowExpanded = (type) =>
  window.Moengage.track_event('Payment Instructions row expanded', { Type: type })

export const paymentInstructionsTableExpanded = () => window.Moengage.track_event('Payment Instructions table expanded')

export const paymentBookingDetailsButton = async (isPaid) => {
  try {
    await CommonUtils.moengageTrackEvent('Payment - Booking Details button click', {
      Screen: isPaid ? 'VA paid success' : 'VA payment',
    })
  } catch (err) {
    console.error(err)
  }
}

export const payForBookingButtonClick = async (isBookingCard) => {
  const eventParams = {
    Screen: isBookingCard ? 'My Bookings' : 'Booking Detail',
  }
  try {
    await CommonUtils.moengageTrackEvent('Pay for booking button click', eventParams)
  } catch (err) {
    console.error(err)
  }
}

export const customerCancelsSingleBooking = async (booking) => {
  const eventParams = {
    'Is driver/fleet accepted': [STATUS_DRIVER_ACCEPT_BOOKING, STATUS_FLEET_ACCEPT_BOOKING].includes(booking.status),
    'Is after Devina timeout': booking.status === STATUS_CANCELLED,
  }
  try {
    await CommonUtils.moengageTrackEvent('Customer cancels single booking', eventParams)
  } catch (err) {
    console.error(err)
  }
}
