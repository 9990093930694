import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import useOutsideAlerter from 'hooks/commonHooks'

const Modal = (props) => {
  const { className, children, closeModal } = props
  const contentRef = useRef(null)
  useOutsideAlerter(contentRef, closeModal)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <div className={`popup-wrapper modal-zIndex ${className}`}>
      <div className="overlay" />
      <div ref={contentRef} className="popup-inner">
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  closeModal: PropTypes.func,
}

Modal.defaultProps = {
  className: '',
  children: null,
  closeModal: null,
}

export default Modal
