import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any[] = []

const subAccountTagListSlice = createSlice({
  name: 'subAccountTagList',
  initialState,
  reducers: {
    subAccountTagList: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const subAccountTagListActionsCreator = subAccountTagListSlice.actions

export default subAccountTagListSlice
