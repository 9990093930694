import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  LONG_HAUL,
  FULL_DAY,
  SCHEDULE,
  IMMEDIATE,
} from 'constants/bookingConstants'

import * as UtilsCommon from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
// IMAGE
import { SHOW_DATE_PICKER_BY_BOOKING } from 'constants/common/popupConstants'
import { ICON_TIMETYPE_FULLDAY, ICON_TIMETYPE_LONGHAUL, ICON_TIMETYPE_QUICK, ICON_TIMETYPE_SCHEDULE } from 'constants/imageConstants'
// UTILS
import I18n from 'i18n/i18n'

const ColorBanner = () => {
  const timeType = useSelector(state => state.timeType)
  const pickupTime = useSelector(state => state.pickupTime)
  const currentStep = useSelector(state => state.currentStep)
  const currentVehicleType = useSelector(state => UtilsCommon.currentVehicleType(state))
  const calendarBooking = useSelector(state => state.calendarBooking)
  const { data: arrIDBookingInvalid } = calendarBooking || {}

  const getStyle = () => {
    switch (timeType) {
      case FULL_DAY:
        return { color: 'purple', icon: ICON_TIMETYPE_FULLDAY }
      case SCHEDULE:
        return { color: 'blue', icon: ICON_TIMETYPE_SCHEDULE }
      case IMMEDIATE:
        return { color: 'green', icon: ICON_TIMETYPE_QUICK }
      case LONG_HAUL:
        return { color: 'brown', icon: ICON_TIMETYPE_LONGHAUL }
      default: return {}
    }
  }

  const checkRenderMessage = () => {
    const isQuickChoice = Utils.quickChoiceSelected()
    if (!isQuickChoice && currentStep !== 2) {
      const minimumPickupTime = UtilsCommon.miniMumMinutes(currentVehicleType, timeType, 0)
      const pickupTimeToMinutes = Utils.convertPickupTimeToMinutes(pickupTime)
      if (pickupTimeToMinutes < minimumPickupTime) {
        return true
      }
    }
    return false
  }

  const isShow = checkRenderMessage()
  if (!isShow) return null
  const style = getStyle()

  return (
    <div className={`color-banner__${style.color}`} style={{ display: isShow && arrIDBookingInvalid.indexOf(`${SHOW_DATE_PICKER_BY_BOOKING}-SINGLE`) >= 0 ? 'flex' : 'none' }}>
      <span className="icon-image">
        <img src={style.icon} />
      </span>
      <p className="default-timetype-text">
        {I18n.t('webapp.booking.message_color_banner')}
      </p>
    </div>
  )
}

ColorBanner.propTypes = {
  timeType: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  currentVehicleType: PropTypes.shape({}),
  pickupTime: PropTypes.string.isRequired
}

ColorBanner.defaultProps = {
  timeType: undefined,
  currentVehicleType: undefined,
}

export default ColorBanner
