import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const ContentWrapper = styled.div<ISBase>`
  margin: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  .info-account {
    flex: 1;
    padding: 0 10px;
  }
  button {
    min-height: 24px;
    min-width: 79px;
    border: 1px solid #7c7c7c;
    border-radius: 3px;
    height: 24px;
  }
  img {
    border-radius: 50%;
  }
`

const IconModal = styled.div<ISBase>`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`
const AccountWrapper = styled.div<ISBase>`
  padding: 20px 15px 15px 15px;
`

export { ContentWrapper, IconModal, AccountWrapper }
