/* eslint-disable react/no-danger */
import React, { forwardRef, useLayoutEffect } from 'react'
import useViewportResize from 'hooks/useViewportResize'

import { SHOW_MORE_LOCATION } from '../../../constants/imageConstants'
import { NO_RESULTS } from '../../../constants/common/locationConstants'
import { useTranslation } from 'react-i18next'
import Utils from 'utils/Utils'
const BUFFER_BOTTOM = 30
interface IDropdownList {
  listItems: any[]
  onClickItem: (data: any) => void
  currentPositionDropdown: number
  extraLeftSidePositionDropdown: number
  wrapperRect: {
    width: number
    bottom: number
    left: number
  }
  showMore: boolean
  onClickShowMore: (data: any) => void,
  classDropdown?: string
}
const DropdownList = forwardRef(
  (
    {
      listItems = [],
      onClickItem = () => undefined,
      wrapperRect = {
        width: 0,
        bottom: 0,
        left: 0,
      },
      extraLeftSidePositionDropdown = 0, // Extra start position dropdown from left side of the input
      currentPositionDropdown = -1,
      showMore,
      onClickShowMore = () => undefined,
      classDropdown
    }: IDropdownList,
    ref: any
  ) => {
    const { height: viewportHeight } = useViewportResize()
    const { t } = useTranslation()

    useLayoutEffect(() => {
      const { left, width, bottom } = wrapperRect
      if (!ref.current) return
      const extraWidth = 70
      const isZoomScreen = Utils.isZoomScreen()
      const percentZoom = isZoomScreen ? 0.8 : 1
      ref.current.style.setProperty('top', `${bottom/percentZoom}px`)
      ref.current.style.setProperty('left', `${left/percentZoom - extraLeftSidePositionDropdown}px`)
      ref.current.style.setProperty('width', `${width/percentZoom + extraWidth + extraLeftSidePositionDropdown}px`)
      ref.current.style.setProperty('max-height', `${viewportHeight - (bottom/percentZoom + BUFFER_BOTTOM)}px`)
    }, [extraLeftSidePositionDropdown, ref, viewportHeight, wrapperRect])

    return (
      <ul ref={ref} className={`dropdown-location Custom-Scroll-Group ${classDropdown}`}>
        {listItems.map((item, index) => {
          if (item.id === NO_RESULTS.id) {
            return (
              !!item.isShow && (
                <li key="no-results" className="dropdown-item no-results">
                  <div className="dropdown-item-line">
                    <span className="dropdown-title">{t('webapp.new_booking.step_1.location_not_found')}</span>
                  </div>
                </li>
              )
            )
          }
          return (
            <li
              key={item.id}
              className={`dropdown-item ${currentPositionDropdown === index && 'active'}`}
              onClick={() => onClickItem(item)}
            >
              <div className="dropdown-item-line">
                {item.contact_id && <span className="Place-Icon Place-Icon-Recent" />}
                <span className="dropdown-title" dangerouslySetInnerHTML={{ __html: item.htmlName || item.name }} />
              </div>
            </li>
          )
        })}
        {showMore && (
          <li className="show-more-autocomplete" key="show-more">
            <div>{t('webapp.label.dont_see_your_address')}</div>
            <div onClick={onClickShowMore}>
              <img src={SHOW_MORE_LOCATION} alt="shore more" />
              {t('webapp.label.show_more_address_results')}
            </div>
          </li>
        )}
        {/* {showContactCS && (
          <li className="show-contact-autocomplete" key="show-contact">
            <span className="icon-contact-cs">
              <img alt="icon-contact-cs" src={CONTACT_CS} />
            </span>
            {t('webapp.label.not_able_to_locate')}
            <span className="action-autocomplete" onClick={() => Utils.openFreshChat({ countryCode })}>
              {t('webapp.action.contact_cs')}
            </span>
          </li>
        )} */}
      </ul>
    )
  }
)
export default DropdownList
