import React from 'react'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY, FACEBOOK_ICON, GOOGLE_ICON } from 'constants/imageConstants'
import { FACEBOOK_ID, GOOGLE_KEY } from 'constants/appConstants'
import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import { useTranslation } from 'react-i18next'
import FormContainer from 'components/common/FormInput'
import SInput from 'components/common/Styled/Form/SInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import { IconModal, SignInWrapper } from './SSignIn'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import SCommon from 'components/common/Styled/SCommon/SCommon'
import SocialButton from '../SocialButton'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch, useAppSelector } from 'store/store'
import accountAction from 'store/actions/common/accountAction'
import {
  CONFIRM_PASSWORD_MODAL,
  RESET_PASSWORD_MODAL,
  SIGN_UP_MODAL,
} from 'components/new_booking/guest_flow/constants'
import SIcon from 'components/common/Styled/SIcon'
import { useNavigate } from 'react-router-dom'
import paths from 'routers/paths'
import CommonUtils from 'utils/common'
import { SocialInfo } from '..'
import toastr from 'utils/toast'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { signInProgressFinished } from 'store/actions/common/bookingActionCreators'
import { SIGN_IN, SIGN_IN_AFTER_BOOKING } from 'constants/trackingAppsFlyer'
import { appsFlyerTrackEvent } from 'utils/trackingAppsFlyer'

interface ISignInModal {
  countryCode?: string
  onCheckRedirectPage: () => void
  onSetPrePassword: React.Dispatch<React.SetStateAction<string>>
  onCheckBackToStep1: () => void
  onSocialLogin?: (
    socialInfo: SocialInfo,
    err: {
      error: string
    }
  ) => void
  isNoStep3?: boolean
}

const SignIn: React.FC<ISignInModal> = ({
  onSocialLogin,
  onCheckRedirectPage,
  onCheckBackToStep1,
  onSetPrePassword,
  isNoStep3,
}) => {
  const { t } = useTranslation()
  const { currentStep, extraInfos } = useAppSelector((s) => s)
  const methods = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(
      yup.object({
        email: yup.string().trim().required(t('fleet_partners.validations.required')),
        password: yup
          .string()
          .trim()
          .required(t('fleet_partners.validations.required'))
          .min(6, t('validations.minlength', { number: 6 })),
      })
    ),
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const LOGO_ICON = CommonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  const handleSubmitSignIn = async (e: { email: string; password: string }) => {
    showLoading()
    const params = {
      countryCode: extraInfos?.country_code?.toLowerCase() || '',
      login: e.email,
      password: e.password || '',
    }
    const response = await accountAction.signIn(params)(dispatch)
    if (response.id) {
      if (response.must_update_password) {
        onSetPrePassword(e.password)
        dispatch(accountManageActions.updateModalAccountManage(CONFIRM_PASSWORD_MODAL))
      } else {
        appsFlyerTrackEvent(isNoStep3 ? SIGN_IN : SIGN_IN_AFTER_BOOKING, { 	
          company_id: response?.last_login_employ_id ?? '',
          customer_id: response?.id,
        })
        CommonUtils.handleTrackMoengage(response, currentStep)
        dispatch(signInProgressFinished(true))
        dispatch(accountManageActions.updateModalAccountManage(''))
        onCheckBackToStep1()
        onCheckRedirectPage()
      }
    } else {
      toastr.error(response?.message || response?.error || '')
    }
    hideLoading()
  }

  return (
    <ModalNormal
      topLevel={!isNoStep3}
      mt={isNoStep3 ? 60 : 0}
      closeIcon={
        !isNoStep3 && (
          <SButton
            br={`1px solid ${theme.colors.green[1]}`}
            h="30px"
            w="30px"
            rds="50%"
            variant="g3"
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(''))}
          >
            <SIcon color={theme.colors.green[1]} fs={16} p={4} value="close" />
          </SButton>
        )
      }
      closeVariant="g3"
      customFixedWidth={'420px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <SignInWrapper>
        <IconModal>
          <img src={LOGO_ICON} width={75} alt="LOGO_ICON" />
        </IconModal>
        <FormContainer methods={methods} onSubmit={(e) => handleSubmitSignIn(e)}>
          <SInput label={t('devise_template.email_address_or_phone_number')} name="email" />
          <SFlex mb={5} between>
            <STypography fs={15}>{t('devise_template.password')}</STypography>
            <STypography
              className="cur-pointer"
              onClick={() => dispatch(accountManageActions.updateModalAccountManage(RESET_PASSWORD_MODAL))}
              color={theme.colors.green[1]}
              fs={15}
            >
              {t('devise_template.i_forgot')}
            </STypography>
          </SFlex>
          <SInput secured name="password" />
          <SFlex mt={25} col>
            <SButton h="36px" fs={15} mb={10} variant="g2" type="submit">
              {t('devise_template.log_in')}
            </SButton>
            <SButton
              h="36px"
              onClick={() =>
                isNoStep3
                  ? navigate(paths.SIGN_UP)
                  : dispatch(accountManageActions.updateModalAccountManage(SIGN_UP_MODAL))
              }
              variant="g1"
              fs={15}
              br={`1px solid ${theme.colors.green[1]}`}
              type="button"
            >
              {t('devise_template.create_an_account')}
            </SButton>
          </SFlex>
          <SCommon.StrikeWrapper mt={15} h="40px" color={theme.colors.gray[9]} mb={15}>
            <SCommon.Strike fw={300} color={theme.colors.dark[4]} pl={15} pr={15} fs={13}>
              {t('customers.sessions.new.or_login_with')}
            </SCommon.Strike>
          </SCommon.StrikeWrapper>
          <SFlex className="social-login" icenter>
            <SocialButton
              w="100%"
              provider="facebook"
              name={t('customers.sessions.new.login_with_facebook')}
              icon={FACEBOOK_ICON}
              autoCleanUri
              appId={FACEBOOK_ID || ''}
              callback={onSocialLogin}
              className="facebook-login"
            />
            <SocialButton
              w="100%"
              provider="google"
              name={t('customers.sessions.new.login_with_google')}
              icon={GOOGLE_ICON}
              autoCleanUri
              appId={GOOGLE_KEY}
              callback={onSocialLogin}
              className="google-login"
            />
          </SFlex>
        </FormContainer>
      </SignInWrapper>
    </ModalNormal>
  )
}

export default SignIn
