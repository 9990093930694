import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash-es'
import { MAP_ICON_LOAD_PLAN } from 'constants/imageConstants'
import { ADMIN_ROLE, APPROVED_STATUS, OWNER_ROLE } from 'constants/headerConstant'
import { eventTrackingSmartloadplannerNonlogin } from 'utils/smart_planner/eventTracking'
import paths from 'routers/paths'
import CommonUtils from 'utils/common'

const trackingEvent = async (event, eventTrackingName, eventTrackingParams = {}) => {
  event.preventDefault()
  const url = event.currentTarget.href

  await CommonUtils.moengageTrackEvent(eventTrackingName, eventTrackingParams)
  window.open(url, event.metaKey || event.ctrlKey ? '_blank' : '_self')
}

const RenderWalletLink = ({ isBusinessSession, enabledEWallet, t }) => {
  const bpWallet = isBusinessSession && enabledEWallet?.includes('business')
  const nonBpWallet = !isBusinessSession && enabledEWallet?.includes('individual')
  return (
    <>
      {bpWallet && (
        <a
          className="Dropdown-Menu-Item"
          target=""
          href="/business/credit_balances"
          onClick={(e) => trackingEvent(e, 'Select Wallet')}
        >
          {t('business.credit_balances.index.bp_wallet')}
        </a>
      )}
      {nonBpWallet && (
        <a
          className="Dropdown-Menu-Item"
          target=""
          href="/credit_balances"
          onClick={(e) => trackingEvent(e, 'Select Wallet')}
        >
          {t('business.credit_balances.index.nobp_wallet')}
        </a>
      )}
    </>
  )
}

const RenderEmployeesLink = ({ isBusinessSession, currentEmploy, t }) => {
  const assignedRole = get(currentEmploy, 'assigned_role', '')
  const isShow = isBusinessSession && (assignedRole === OWNER_ROLE || assignedRole === ADMIN_ROLE)
  return (
    isShow && (
      <a
        className="Dropdown-Menu-Item"
        target=""
        href="/business/employees"
        onClick={(e) => trackingEvent(e, 'Select Employees')}
      >
        {t('webapp.label.employees')}
      </a>
    )
  )
}

const Menu = (props) => {
  const { t } = useTranslation()
  const extraInfos = useSelector((state) => state.extraInfos)
  const { customerSignedIn, isBusinessSession, currentEmploy, company } = props

  const renderDashBoardLink = () => {
    let isShow = extraInfos?.show_api_dashboard
    if (isBusinessSession && !isEmpty(company)) {
      const { status, show_api_dashboard: showApiDashboard } = company
      isShow = status === APPROVED_STATUS && showApiDashboard
    }
    return (
      isShow && (
        <a
          className="Dropdown-Menu-Item"
          target=""
          href="/business/api_dashboards"
          onClick={(e) => trackingEvent(e, 'Select API Dashboard')}
        >
          {t('webapp.api_dashboard.title')}
        </a>
      )
    )
  }

  return (
    <>
      {customerSignedIn ? (
        <div className="Menu-Nav-Item">
          <div className="dark-green Dropdown">
            <div className="Dropdown-Close"></div>
            <div className="Dropdown-Head">
              <div className="Dropdown-Head-Item">{t('webapp.booking.new_booking')}</div>
              <div className="Dropdown-Head-Caret">
                <i className="b material-icons Icon">arrow_drop_down</i>
              </div>
              <div className="top Dropdown-Head-Arrow"></div>
            </div>
            <div className="left top Dropdown-Menu">
              <a
                className="Dropdown-Menu-Item"
                href={paths.NEW_BOOKING_SINGLE}
                onClick={(e) => trackingEvent(e, 'Select Home/New Booking', { Batch: false })}
              >
                {t('batches.label.single')}
              </a>
              <a
                className="Dropdown-Menu-Item"
                href="/batches/select"
                onClick={(e) => trackingEvent(e, 'Select Home/New Booking', { Batch: true })}
              >
                {t('batches.label.batch')}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <a
          className="Menu-Nav-Item"
          href="/bookings/new"
          onClick={(e) => trackingEvent(e, 'Select Home/New Booking', { Batch: false })}
        >
          {t('webapp.booking.new_booking')}
        </a>
      )}
      {extraInfos.smart_load_planner_enable && (
        <div className="menu-item tooltip">
          {t('webapp.popup_text.load_plan')}
          <div className="tooltip-text">
            <div className="wr-box">
              <div className="row-item">
                <div className="item">
                  <img src={MAP_ICON_LOAD_PLAN} alt="" />
                </div>
              </div>
              <div className="row-item ctn-box">
                <div className="item">{t('webapp.popup_text.load_plan_text')}</div>
              </div>
              <div className="row-item">
                <div className="item">
                  <a
                    onClick={() => eventTrackingSmartloadplannerNonlogin(customerSignedIn)}
                    className="btn btn-app-exprerience"
                    href="/batches/smart_load_planner"
                  >
                    {t('webapp.intro_batchs_multiple.smart_load.title')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <span className="triangle-right">triangle</span>
        </div>
      )}
      {customerSignedIn && (
        <>
          <div className="Menu-Nav-Item Style-Hover">
            <div className="dark-green Dropdown">
              <div className="Dropdown-Close" />
              <div className="Dropdown-Head">
                <div className="Dropdown-Head-Item">{t('webapp.booking.manage_bookings')}</div>
                <div className="Dropdown-Head-Caret">
                  <i className="b material-icons Icon">arrow_drop_down</i>
                </div>
              </div>
              <div className="top Dropdown-Head-Arrow" />
              <div className="left top Dropdown-Menu">
                <a
                  className="Dropdown-Menu-Item"
                  target=""
                  href="/bookings"
                  onClick={(e) => trackingEvent(e, 'Select My Bookings', { Batch: false })}
                >
                  {t('batches.label.single')}
                </a>
                <a
                  className="Dropdown-Menu-Item"
                  target=""
                  href="/batches"
                  onClick={(e) => trackingEvent(e, 'Select My Bookings', { Batch: true })}
                >
                  {t('batches.label.batch')}
                </a>
              </div>
            </div>
          </div>
          <div className="Menu-Nav-Item Style-Hover">
            <div className="dark-green Dropdown">
              <div className="Dropdown-Close" />
              <div className="Dropdown-Head">
                <div className="Dropdown-Head-Item">{t('webapp.booking.more')}</div>
                <div className="Dropdown-Head-Caret">
                  <i className="b material-icons Icon">arrow_drop_down</i>
                </div>
              </div>
              <div className="top Dropdown-Head-Arrow" />
              <div className="left top Dropdown-Menu">
                <RenderWalletLink
                  isBusinessSession={isBusinessSession}
                  enabledEWallet={extraInfos?.enabled_ewallet}
                  t={t}
                />
                <a
                  className="Dropdown-Menu-Item"
                  target=""
                  href="/locations"
                  onClick={(e) => trackingEvent(e, 'Select My Location')}
                >
                  {t('webapp.label.contacts')}
                </a>
                <RenderEmployeesLink
                  isBusinessSession={isBusinessSession}
                  currentEmploy={currentEmploy}
                  t={t}
                />
                <a
                  className="Dropdown-Menu-Item"
                  target=""
                  href="/preference_drivers"
                  onClick={(e) => trackingEvent(e, 'Select Driver Preferences')}
                >
                  {t('webapp.label.my_driver')}
                </a>
                <a
                  className="Dropdown-Menu-Item"
                  target=""
                  href="/dashboard_analytics"
                  onClick={(e) => trackingEvent(e, 'Select Analytics')}
                >
                  {t('analytics.label.dashboard_analytics')}
                </a>
                {renderDashBoardLink()}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

Menu.propTypes = {
  customerSignedIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  isBusinessSession: PropTypes.bool.isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  currentEmploy: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
}

export default Menu
