import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
//UTILS
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
import ModalDecision from '../../driver_preference/ModalDecision'
// CONSTANTS
import {
  ICON_DRIVER_PREFERENCES_FULL,
} from 'constants/imageConstants'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { bindActionCreators } from 'redux'
// ASSETS

const mapStateToProps = state => ({
  currentPopupID: state.currentPopupID,
  infoAssignDriverPopup: state.infoAssignDriverPopup
})

function mapDispatchToProps(dispatch) {
  return {
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch)
  }
}

class AssignDriverChangeBookingDetailsPopup extends React.Component {
  closePopup(cancel) {
    const {
      infoAssignDriverPopup,
      assignDriverActions,
      currentPopupIDActions,
      bookingID,
      refNode
    } = this.props
    currentPopupIDActions.updateCurrentPopupID('')
    if (!cancel) {
      infoAssignDriverPopup.func()
      if (_.isUndefined(bookingID)) {
        assignDriverActions.changeAssignDriver(null)
        assignDriverActions.changeAssignedPickupTime('')
        assignDriverActions.changeTmpSelectedDateAssignDriver('')
        assignDriverActions.changeTmpSelectedTimeAssignDriver('')
      } else {
        assignDriverActions.updateBooking(
          bookingID,
          {
            assignedDriver: undefined,
            assignedPickupTime: '',
            tmpSelectedDateAssignDriver: '',
            tmpSelectedTimeAssignDriver: '',
            sendFirstToFavorite: undefined
          }
        )
      }
    } else if (infoAssignDriverPopup.backFunc) {
      infoAssignDriverPopup.backFunc()
    }
    $(refNode).removeClass('visible')
  }

  render() {
    return (
      <ModalDecision
        className="Modal visible change-driver-details"
        iconSrc={ICON_DRIVER_PREFERENCES_FULL}
        title={I18n.t('webapp.new_booking.change_booking_details_title')}
        content={I18n.t('webapp.new_booking.change_booking_details_content')}
        backText={I18n.t('webapp.long_haul.go_back')}
        confirmText={I18n.t('webapp.action.proceed')}
        handleConfirmAction={() => this.closePopup(false)}
        usingCustomCloseFunc
        customClosePopup={() => this.closePopup(true)}
      />
    )
  }
}

AssignDriverChangeBookingDetailsPopup.propTypes = {
  infoAssignDriverPopup: PropTypes.shape({}).isRequired,
  assignDriverActions: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.func.isRequired,
  bookingID: PropTypes.string,
  refNode: PropTypes.instanceOf(Object),
}

AssignDriverChangeBookingDetailsPopup.defaultProps = {
  bookingID: undefined,
  refNode: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignDriverChangeBookingDetailsPopup)
