import { css } from 'styled-components'
import STypography from '../STypography'
import { ISBase } from './ICommon'
import { styled, theme } from 'components/common/Styled/STheme'
const Strike = styled(STypography)<ISBase>`
  background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.colors.white[1])}!important;
  position: absolute;
  z-index: 1;
`
const StrikeWrapper = styled.p<ISBase>`
  margin-left: ${({ ml }) => (ml ? `${ml}px` : 0)};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : 0)};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
  text-align: ${({ align }) => (align ? `${align}` : `inherit`)};
  position: relative;
  display: flex;
  justify-content: center;
  height: ${({ h }) => (h ? `${h}` : '30px')};
  width: ${({ w }) => (w ? `${w}` : 'auto')};
  align-items: center;
  ::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: ${({ theme, color }) => (color ? color : theme.colors.white[1])};
    z-index: 0;
  }
`
const Hr = styled.hr<ISBase>`
  border: none;
  background-color: ${(p) => p.bgColor || theme.colors.white[1]};
  ${({ m, mt, mr, mb, ml }) =>
    css`
      margin: ${m ? `${m}px` : `${mt || 0}px ${mr || 0}px ${mb || 0}px ${ml || 0}px`};
    `}
  height: ${({ h }) => h || '1px'};
`
const SCommon = {
  Strike,
  StrikeWrapper,
  Hr,
}
export default SCommon
