import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
// UTILS
import I18n from 'i18n/i18n'

// ACTIONS
// COMPONENTS
// CONSTANTS
// API
// ASSETS

class ActionButtonsAssignDriver extends React.PureComponent {
  componentWillMount() {
    const { assignDriver } = this.props || {}
    this.root = {
      assignDriver: assignDriver || null
    }
  }

  renderAddFavoriteTooltip() {
    const { driversCount, driverFromSearch, showSearchResult } = this.props
    if (driversCount === 0 || (showSearchResult && _.isUndefined(driverFromSearch))) {
      return null
    }
    return (
      <p key={_.uniqueId()} className="center default-font default-color Popover-Item-Driver-Tooltip Bottom White-bg Radius-default pt10 pr10 pb10 pl10 relative">
        {I18n.t('webapp.new_booking.step_2.assign_driver.not_enough_driver')}
      </p>
    )
  }

  render() {
    const {
      assignDriver,
      closePopup,
      showPopupRecentDrivers,
      driversCount,
      assignedDriver
    } = this.props
    return (
      <div key="button-driver-list" className="Popover-Item-Driver-Actions flex">
        <button
          key="button-driver-1"
          type="button"
          onClick={() => closePopup()}
          className="Button Border-Default Border-Color-White White-text Black-Gray-text-hover flex-index mr5 capitalize"
        >
          {I18n.t('webapp.long_haul.go_back')}
        </button>
        {(!!assignedDriver || !!assignDriver)
          ? (
            <button
              key="button-driver-2"
              type="button"
              onClick={() => closePopup(true)}
              className="Button Border-Default Dark-Green-bg Button-Dark-Green-Hover White-text flex-index ml5 capitalize"
            >
              {I18n.t('webapp.action.confirm')}
            </button>
          ) : driversCount < 1 && (
            <div className="flex-index ml5">
              {this.renderAddFavoriteTooltip()}
              <button
                key="button-driver-3"
                type="button"
                onClick={() => showPopupRecentDrivers()}
                className="Button Border-Default Dark-Green-bg Button-Dark-Green-Hover White-text capitalize w100"
              >
                {I18n.t('webapp.action.add_favorites')}
              </button>
            </div>
          )
        }
      </div>
    )
  }
}

ActionButtonsAssignDriver.propTypes = {
  closePopup: PropTypes.func.isRequired,
  showPopupRecentDrivers: PropTypes.func.isRequired,
  bookingID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  assignDriver: PropTypes.shape({}),
  assignedDriver: PropTypes.shape({}),
  isMultiple: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  driversCount: PropTypes.number,
  driverFromSearch: PropTypes.shape({}),
  showSearchResult: PropTypes.bool
}

ActionButtonsAssignDriver.defaultProps = {
  assignDriver: undefined,
  assignedDriver: undefined,
  bookingID: undefined,
  isMultiple: false,
  showConfirmButton: true,
  driversCount: 0,
  driverFromSearch: undefined,
  showSearchResult: false
}

export default connect(null, null)(ActionButtonsAssignDriver)
