// newBookingReducer
import attachmentsSlice from 'store/toolkit/newBooking/attachments.reducer'
import beforeOptimizedLocationsSlice from 'store/toolkit/newBooking/beforeOptimizedLocations.reducer'
import bookingAgainDetailsSlice from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import stepNewLongHaulSlice from 'store/toolkit/newBooking/stepNewLongHaul.reducer'
import discountCodeSlice from 'store/toolkit/newBooking/discountCode.reducer'
import googleMarkersSlice from 'store/toolkit/newBooking/googleMarkers.reducer'
import googleMapSlice from 'store/toolkit/googleMap/googleMap.reducer'
import isLoginStep3Slice from 'store/toolkit/newBooking/isLoginStep3.reducer'
import isOptimizedSlice from 'store/toolkit/newBooking/isOptimezed.reducer'
import jobOrderNumberSlice from 'store/toolkit/newBooking/jobOrderNumber.reducer'
import autoSwitchLongHaulSlice from 'store/toolkit/newBooking/autoSwitchLongHaul.reducer'
import locationsSlice from 'store/toolkit/locations/locations.reducer'
import notesSlice from 'store/toolkit/newBooking/note.reducer'
import tmpLocationSlice from 'store/toolkit/newBooking/updateTmpLocation.reducer'
import pickupZonesSlice from 'store/toolkit/pickupZones/pickupZones.reducer'
import dropOffZonesSlice from 'store/toolkit/pickupZones/dropOffZones.reducer'
import selectedPickupZonesSlice from 'store/toolkit/pickupZones/selectedPickupZone.reducer'
import selectedDropOffZoneSlice from 'store/toolkit/pickupZones/selectedDropOffZone.reducer'
import roundTripDiscountSlice from 'store/toolkit/newBooking/roundTripDiscount.reducer'
import othersSlice from 'store/toolkit/newBooking/others.reducer'
import timeTypeSlice from 'store/toolkit/newBooking/timeType.reducer'
import timeTypeUISlice from 'store/toolkit/newBooking/timeTypeUI.reducer'
import showQuickChoiceSlice from 'store/toolkit/newBooking/quickChoice/showQuickChoice.reducer'
import documentReturnSlice from 'store/toolkit/newBooking/documentReturn.reducer'
import dataChangesSlice from 'store/toolkit/newBooking/dataChange.reducer'
import checkLocationsSlice from 'store/toolkit/newBooking/checkLocations.reducer'
import editModeTextSlice from 'store/toolkit/newBooking/editModeText.reducer'
import preTimeTypeSlice from 'store/toolkit/newBooking/preTimeType.reducer'
import subAccountTagPickedSlice from 'store/toolkit/subAccountTagPicked/subAccountTagPicked.reducer'
import showDatePickerSlice from 'store/toolkit/newBooking/showDatePicker.reducer'
import countNumberOnlineDriversSlice from 'store/toolkit/newBooking/countNumberOnlineDrivers.reducer'
import selectedServiceTypeIDSlice from 'store/toolkit/newBooking/selectedServiceTypeID.reducer'
import selectedVehicleTypeIDSlice from 'store/toolkit/newBooking/selectedVehicleTypeID.reducer'
import pickupTimeSlice from 'store/toolkit/newBooking/pickupTime.reducer'
import quickChoiceSlice from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'

 // assignDriver
import assignedDriverSlice from 'store/toolkit/newBooking/assignDriver/assignedDriver.reducer'
import assignedPickupTimeSlice from 'store/toolkit/newBooking/assignDriver/assignedPickupTime.reducer'
import estimatedWorkingTimeSlice from 'store/toolkit/newBooking/assignDriver/estimatedWorkingTime.reducer'
import isNewDriverSlice from 'store/toolkit/newBooking/assignDriver/isNewDriver.reducer'
import isSavePreviousBookingIDSlice from 'store/toolkit/newBooking/assignDriver/isSavePreviousBookingID.reducer'
import tmpSelectedDateAssignDriverSlice from 'store/toolkit/newBooking/assignDriver/tmpSelectedDateAssignDriver.reducer'
import tmpSelectedTimeAssignDriverSlice from 'store/toolkit/newBooking/assignDriver/tmpSelectedTimeAssignDriver.reducer'

// ===== TrackingBookingReducer
import cancelReasonsSlice from 'store/toolkit/cancelReasons/cancelReasons.reducer'
import trackingMarkerSlice from 'store/toolkit/trackingMarkers/trackingMarker.reducer'


const newBookingReducer = {
  // ===== NewBookingReducer
  locations: locationsSlice.reducer,

  attachments: attachmentsSlice.reducer,
  beforeOptimizedLocations: beforeOptimizedLocationsSlice.reducer,
  bookAgainDetails: bookingAgainDetailsSlice.reducer,
  stepNewLongHaul: stepNewLongHaulSlice.reducer,
  discountCode: discountCodeSlice.reducer,
  googleMarkers: googleMarkersSlice.reducer,
  googleMap: googleMapSlice.reducer,
  isLoginStep3: isLoginStep3Slice.reducer,
  isOptimized: isOptimizedSlice.reducer,
  jobOrderNumber: jobOrderNumberSlice.reducer,
  autoSwitchLongHaul: autoSwitchLongHaulSlice.reducer,
  showDatePicker: showDatePickerSlice.reducer,
  note: notesSlice.reducer,
  roundTripDiscount: roundTripDiscountSlice.reducer,
  others: othersSlice.reducer,
  pickupTime: pickupTimeSlice.reducer,
  selectedServiceTypeID: selectedServiceTypeIDSlice.reducer,
  selectedVehicleTypeID: selectedVehicleTypeIDSlice.reducer,
  countNumberOfOnlineDrivers: countNumberOnlineDriversSlice.reducer,
  tmpLocations: tmpLocationSlice.reducer,

  // double check to remove
  pickupZones: pickupZonesSlice.reducer,
  dropOffZones: dropOffZonesSlice.reducer,
  selectedPickupZone: selectedPickupZonesSlice.reducer,
  selectedDropOffZone: selectedDropOffZoneSlice.reducer,

  timeType: timeTypeSlice.reducer,
  timeTypeUI: timeTypeUISlice.reducer,
  quickChoiceID: quickChoiceSlice.reducer,
  showQuickChoices: showQuickChoiceSlice.reducer,
  documentReturn: documentReturnSlice.reducer,
  dataChange: dataChangesSlice.reducer,
  checkLocations: checkLocationsSlice.reducer,
  //assignDriver
  assignedDriver: assignedDriverSlice.reducer,
  assignedPickupTime: assignedPickupTimeSlice.reducer,
  isNewDriver: isNewDriverSlice.reducer,
  isSavePreviousBookingID: isSavePreviousBookingIDSlice.reducer,
  tmpSelectedDateAssignDriver: tmpSelectedDateAssignDriverSlice.reducer,
  tmpSelectedTimeAssignDriver: tmpSelectedTimeAssignDriverSlice.reducer,
  estimatedWorkingTime: estimatedWorkingTimeSlice.reducer,
  // shoppingList,
  editModeText: editModeTextSlice.reducer,
  // warningBanner,
  prevTimeType: preTimeTypeSlice.reducer,
  //double check for other pages
  subAccountTagPicked: subAccountTagPickedSlice.reducer,

  // ===== TrackingBookingReducer
  cancelReasons: cancelReasonsSlice.reducer,
  // locations,
  trackingMarkers: trackingMarkerSlice.reducer,
  // shoppingOrder,
}

export default newBookingReducer
