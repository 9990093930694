import apiClient from 'services/axiosApp'
import axios from 'axios'
import { getParamFromURL } from 'utils/booking/common'
const AwsImageUpload = {
  get: async (AuthenticationToken, formData = {}, file, callback) => {
    try {
      const res = await apiClient.post('/api/v3/attachments', formData, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      const { error, attachment } = res.data
      if (error) return callback(null, true)
      const { attachment_url: attachmentUrl, display_url: displayUrl, object_id: objectID } = attachment
      const result = await apiClient.put(attachmentUrl, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      if (!result) return callback(null, true)
      return callback({ photoID: objectID, photoUrl: displayUrl }, false)
    } catch (err) {
      throw new Error(err)
    }
  },
  getPutUrl: async (AuthenticationToken, formData = {}, file, callback) => {
    try {
      const res = await apiClient.post('/api/v3/attachments/booking_attachment', formData, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      const { error, attachment } = res.data
      if (error) return callback(null, true)
      const { attachment_url: url, object_id: objectID } = attachment
      const request = {
        method: 'PUT',
        url: url,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: file
      };
      const result = await axios(request);
      if (!result) return callback(null, true)
      const splitUrl = url.split('?')[0]
      return callback({ photoID: objectID, photoUrl: splitUrl }, false)
    } catch (err) {
      throw new Error(err)
    }
  },
  getPutPhotoUrl: async (formData = {}, file) => {
    const isExportAutomatic = getParamFromURL('is_export_automatic')
    try {
      const res = await apiClient.post('/api/v3/attachments/photo', formData, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      const { error, attachment } = res.data
      if (error) return null
      const { attachment_url: url, object_id: objectID } = attachment
      const result = await apiClient.put(url, file, {
          headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      if (!result) return null
      const splitUrl = url.split('?')[0]
      if(isExportAutomatic) {
        const analyticEl = document.createElement('span')
        analyticEl.id = 'analyticUploadSucceed'//triggered by BE to track the success of the upload
        document.body.appendChild(analyticEl)
      }
      return { photoID: objectID, photoUrl: splitUrl }
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default AwsImageUpload
