// COMMONS
import locationUtils from '../location'
import _ from 'lodash'
// CONSTANTS
import {
  CUSTOMER_VAR_NONBP,
  CUSTOMER_VAR_BP,
} from 'constants/common/customerConstants'
import {
  NOW,
  SCHEDULE,
  QUICK_CHOICE,
} from 'constants/bookingConstants'

const roundTripDiscountUtils = {
  shouldShow(
    locations,
    roundTripDiscount,
    timeType,
    currentCompanyID,
    enableRoundTrip,
    maxNumberOfLocation,
  ) {
    const pickup = _.first(locations)
    // Feature is disabled
    if (_.isEmpty(enableRoundTrip)) { return false }
    // Feature is not enabled for current sessionType
    const sessionType = currentCompanyID === 0 ? CUSTOMER_VAR_NONBP : CUSTOMER_VAR_BP
    if (!_.includes(enableRoundTrip, sessionType)) { return false }
    // Feature is not enabled for current timeType
    if (!_.includes([NOW, SCHEDULE, QUICK_CHOICE], timeType)) {
      return false
    }
    // Require pickup location is filled
    if (_.isUndefined(pickup.lat)) { return false }
    // Require atleast 1 filled dropoff
    const dropOffs = _.filter(locations, l => (!_.isUndefined(l.lat) && l.id !== pickup.id))
    if (_.isEmpty(dropOffs)) { return false }
    // In case pickup and destination have same address, we still show roundtrip
    if (_.size(locations) === 2 && pickup.lat === dropOffs.lat && pickup.lng === dropOffs.lng) {
      return true
    }
    // Require 1 available slot for roundTripLocation if the roundTripDiscount checkbox is not yet checked
    const pickupPosition = locationUtils.locationPosition(pickup)
    const destinationPosition = locationUtils.locationPosition(_.last(locations))
    return !(_.size(locations) >= maxNumberOfLocation
      && (pickupPosition.lat !== destinationPosition.lat && pickupPosition.lng !== destinationPosition.lng)
      && (!_.isUndefined(destinationPosition) && !_.isUndefined(destinationPosition.lng)))
  }
}

export default roundTripDiscountUtils
