import React from 'react'

import { IButton } from './IButton'
import { ButtonContainer, LoadingContainer, IconContainer, IconWrapper } from './SButton'
import SSpinner from '../SSpinner'
import STypography from '../STypography'

const SButton = React.forwardRef((props: IButton, ref): JSX.Element => {
  const {
    loading,
    icon,
    color,
    iconPosition = 'after',
    textVariant = 'body',
    fw = 400,
    disabled,
    children,
    isDownloading,
    onClick = (e) => {
      return null
    },
    ...buttonProps
  } = props
  return (
    <ButtonContainer
      ref={ref}
      disabled={disabled}
      color={color}
      iconPosition={iconPosition}
      loading={loading ? `${loading}` : ''}
      isDownloading={isDownloading}
      onClick={(e) => {
        ;(!loading || !disabled || !isDownloading) && onClick(e)
      }}
      {...buttonProps}
    >
      {iconPosition === 'before' && <IconWrapper>{icon}</IconWrapper>}
      {children &&
        (typeof children === 'string' ? (
          <STypography fs={buttonProps.fs} variant={textVariant} color={color} fw={fw}>
            {children}
          </STypography>
        ) : (
          children
        ))}
      {loading && (
        <LoadingContainer>
          <SSpinner width={24} height={24} />
        </LoadingContainer>
      )}
      <IconContainer>{iconPosition === 'after' && !loading && icon}</IconContainer>
    </ButtonContainer>
  )
})

SButton.displayName = 'Button'

export default React.memo(SButton)
