export const EZ_SHEET_UPLOAD = 'ez_sheet_upload'
export const IN_APP_EXPERIENCE = 'in_app_experience'
export const SMART_LOAD_PLANNER = 'smart_load_planner'
export const USER_TEMPLATE_TYPE = {
  NONBP: 'individual',
  BP: 'company'
}

export const BATCH_TEMPLATE_TYPE_CUSTOMER_PROP = {
  NONBP: 'individual',
  BP: 'business'
}
export const NOT_SAME_PERCENT = 'not_same_percent'
