import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch } from 'store/store'
import { ContentWrapper } from './Sindex'
import { clearVehicleTypeSettings } from 'store/actions/new_booking/extraServiceActionCreators'
import { setToFirstStep } from 'store/actions/new_booking/stepActionCreators'
import { handleSetIsLoginStep3 } from 'store/actions/common/customerActionCreators'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'

const GoToStep1 = ({ isNoStep3 }: { isNoStep3?: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    clearVehicleTypeSettings()(dispatch)
    setToFirstStep()(dispatch)
    dispatch(dataChangesActionsCreator.updateDataChange(true))
    handleSetIsLoginStep3(false)(dispatch)
    dispatch(accountManageActions.updateModalAccountManage(''))
  }
  return (
    <ModalNormal
      topLevel={!isNoStep3}
      targetParent={true}
      closeVariant="g1"
      customFixedWidth={'460px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <ContentWrapper>
        <STypography mb={20} align="center" fs={16}>
          {t('errors.customer.different_offers_and_pricing')}
        </STypography>
        <SButton
          w="100%"
          h="40px"
          fw={500}
          onClick={handleClick}
          br={`1px solid ${theme.colors.green[1]}`}
          variant="g2"
          type="button"
        >
          {t('webapp.new_booking.ok')}
        </SButton>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default GoToStep1
