import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = null
const mapServiceSlice = createSlice({
  name: 'mapService',
  initialState,
  reducers: {
    setMapService: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const mapServiceActionsCreator = mapServiceSlice.actions

export default mapServiceSlice
