import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILocation } from 'interface/IBooking'

const initialState: ILocation[] = []

const prevLocationsSlice = createSlice({
  name: 'prevLocations',
  initialState,
  reducers: {
    updatePrevLocations: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const prevLocationsActionsCreator = prevLocationsSlice.actions

export default prevLocationsSlice
