import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubAccountTag } from 'interface/IBooking'

const initialState = {} as ISubAccountTag
const subAccountTagPickedSlice = createSlice({
  name: 'subAccountTagPicked',
  initialState,
  reducers: {
    subAccountTagPicked: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const subAccountTagPickedActionsCreator = subAccountTagPickedSlice.actions

export default subAccountTagPickedSlice
