import { css } from 'styled-components'
import { Theme, styled } from '../../STheme'
import STypography from '../../STypography'
import { ISInput } from './ISInput'

export const SInputWrapper = styled.div`
  margin-bottom: 10px;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

export const Field = styled.input<Omit<ISInput, 'name'>>`
  padding: 0 10px;
  border: 0;
  height: ${({ height }) => height};
  font-size: 14px;
  box-sizing: border-box;
  flex: 1;
  :focus {
    outline: none;
  }
  :disabled {
    background-color: #efefef;
  }
`
export const WrapSecured = styled.div<{
  error: boolean
  noBorder: boolean
}>`
  display: flex;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  flex: 1;
  ${({ noBorder, error, theme }) =>
    !noBorder &&
    css`
      border: ${error ? `1px solid ${theme.colors.red[1]}` : '1px solid #cecece'};
    `}
`
export const LeftComponentContainer = styled.div<{
  theme: Theme
  clickable: boolean
  LeftComponentWidth?: string
}>`
  display: flex;
  width: ${({ LeftComponentWidth }) => {
    if (LeftComponentWidth === undefined) return `48px`
    return LeftComponentWidth
  }};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ clickable }) => clickable && 'cursor: pointer;'}
`

export const RightComponentContainer = styled.div<{ theme: Theme; clickable: boolean; RightComponentWidth?: string }>`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ RightComponentWidth }) => {
    if (RightComponentWidth === undefined) return `48px`
    return RightComponentWidth
  }};

  ${({ clickable }) => clickable && 'cursor: pointer;'}
`

export const Error = styled(STypography)`
  margin-top: 4px;
`
