// UTILS
import I18n from 'i18n/i18n'
// API
import ContactsAPI from 'api/contacts'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { BUSINESS_PROGRAM, PERSONAL } from 'constants/common/contactConstants'
import { contactsActionsCreator } from 'store/toolkit/contacts/contacts.reducer'
import { personalContactsActionsCreator } from 'store/toolkit/personalContacts/personalContacts.reducer'
// ASSETS
export const updateContacts = contacts => contactsActionsCreator.updateContacts({ contacts })
export const updatePersonalContacts = personalContacts => personalContactsActionsCreator.updatePersonalContacts(personalContacts)

export const buildPickupContacts = (showPhoneNumberToDriver, pickupContacts) => {
  if(_.isEmpty(pickupContacts)) return []
  const newPickupContacts = pickupContacts.map((pickupContact) => {
    if (!showPhoneNumberToDriver && pickupContact.is_phone_mask) {
      return { ...pickupContact, phone_mask: I18n.t('contacts.labels.not_show_phone') }
    }
    return pickupContact
  })
  return newPickupContacts
}

export const createContact = (contact, currentTab, callback) => async (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  const action =
    currentTab === BUSINESS_PROGRAM
      ? contactsActionsCreator.addNewContact
      : personalContactsActionsCreator.addNewPersonalContact
  const companyID =
    !currentCustomer.current_company_id || currentTab === PERSONAL
      ? undefined
      : getState().currentCustomer.current_company_id
  const newContact = {
    ...contact,
    customer_id: currentCustomer.id,
    company_id: companyID,
    contact_type: 'normal',
  }

  const response = await ContactsAPI.create(newContact)
  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(dispatch(action(response.data.object))).then(() => {
      callback(response)
    })
  }
}

export const updateContact = (contact, currentTab, callback) => async (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  const newContact = {
    ...contact,
    customer_id: currentCustomer.id,
  }

  const response = await ContactsAPI.update(newContact)
  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(
      dispatch(
        currentTab === BUSINESS_PROGRAM
          ? contactsActionsCreator.updateSingleContact({ id: response.data.object.id, attrs: response.data.object })
          : personalContactsActionsCreator.updateSinglePersonalContact({
              id: response.data.object.id,
              attrs: response.data.object,
            })
      )
    ).then(() => {
      callback(response)
    })
  }
}

export const loadMoreContact =
  (callback, companyID, contactType = undefined, currentPage) =>
  async (dispatch, getState) => {
    const res = await ContactsAPI.getList(companyID, contactType, currentPage)
    callback(res)
  }

export const deleteContact = (id, currentTab, callback) => async (dispatch, getState) => {
  const action =
    currentTab === BUSINESS_PROGRAM
      ? contactsActionsCreator.deleteContactLocal
      : personalContactsActionsCreator.deletePersonalContact

  const response = await ContactsAPI.delete(id)

  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(dispatch(action(id))).then(() => {
      callback(response)
    })
  }
}

export const updateContactInfo =
  (contact, callback = () => {}) =>
  async (dispatch, getState) => {
    const response = await ContactsAPI.update(contact)
    Promise.resolve(
      dispatch(contactsActionsCreator.updateSingleContact({ id: contact.id, attrs: response.object }))
    ).then(() => {
      callback()
    })
  }

export const search =
  (searchText, companyID, contactType = 'normal', only = [], callback) =>
  (dispatch, getState) => {
    const customer = getState().currentCustomer
    ContactsAPI.search({
      authenticationToken: customer.authentication_token,
      searchText,
      companyID,
      contactType,
      only,
      callback,
    })
  }

export const getDetails =
  (id, only = [], callback) =>
  async (dispatch, getState) => {
    const customer = getState().currentCustomer
    const res = await ContactsAPI.get({
      authenticationToken: customer.authentication_token,
      id,
      only,
    })
    callback(res)
  }
