import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const ContentModal = styled.div<ISBase>`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.colors.white[1])};
  color: ${({ color, theme }) => color || theme.colors.dark[1]};
`

const SignUpWrapper = styled.div<ISBase>`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .text-signin {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green[1]};
    font-size: 17px;
  }
  .term-link {
    width: 400px;
    color: #949494;
    text-align: center;
    a {
      color: ${({ theme }) => theme.colors.green[1]};
      text-decoration: underline;
    }
  }
  .facebook-login,
  .google-login {
    box-shadow: 0 0 10px #bbbbbb;
    background: #fff;
    border-radius: 4px;
    :hover {
      background-color: #efefef;
      color: #333;
    }
  }
  .facebook-login {
    img {
      margin-right: 5px;
      width: 36px;
      height: 36px;
    }
  }
  .google-login {
    img {
      margin-right: 7px;
      width: 22px;
      height: 22px;
    }
  }
`
const ErrorWrapper = styled.div<ISBase>`
  padding: 0 25px 20px 25px;
  white-space: pre-line;
`
export { ContentModal, SignUpWrapper, ErrorWrapper }
