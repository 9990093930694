import React, { FC } from 'react'

import { P } from './STypography'
import { ITypography } from './ITypography'

const STypography: FC<ITypography> = ({ tag, ...props }) => {
  const Component = tag ? P.withComponent(tag) : P

  return <Component {...props} data-tag="allowRowEvents" />
}

export default React.memo(STypography)
