import React, { FC } from 'react';


import { ISpinner } from './ISpinner';
import { SSpinWrap } from './SSpinner';
import Spin from 'components/common/SVG/Spin';

const SSpinner:FC<ISpinner> = (props) => {
  return (
    <SSpinWrap>
      <Spin {...props} />
    </SSpinWrap>
  );
};

export default React.memo(SSpinner);
