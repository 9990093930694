import $ from 'jquery'

// config basic popup
// Notes:
// Add trigger='open-modal' and modal-id='ID-of-your-modal' to the element you trigger to show the modal

export const initBasicModal = () => {
  $(document).on('click', '.close-modal-common.custom', function () {
    $(this).parent().removeClass('visible');
    $(this).parent().find('.Popup').removeAttr();
    $(this).remove();
  });

  $(document).on("click", ".Modal-Head i", function () {
    $(this).closest(".Modal").removeClass("visible");
  });

  $(document).on("click", ".close-modal", function () {
    $(this).closest(".Modal").removeClass("visible");
  });

  // close modal on click it's overlays
  $(document).on("click", ".visible.Modal.Modal-No-action", (e) => {
    if ($(e.target).hasClass('Modal')) {
      $(e.target).removeClass("visible");
    } else {
      $(e.target).parents('.Modal').removeClass("visible");
    }
  });

  $(document).keydown((e) => {
    if(e.keyCode !== 27) return
    $('.Modal.visible').each(function closeEachModal() {
      const attrId = $(this).attr('id')
      const attrClass = $(this).attr('class')
      if (attrClass.includes('escape_popup_no_action')) {
        return
      }
      if (attrClass.includes('escape_popup_remove')) {
        $(this).remove()
      }
      if (($(this).find('input').length === 0) && ($(this).find('.Normal-Booking-Actions').length === 0)
        && ($(this).find('button').length === 0) && ($(this).find('.Button').length === 0)) {
        if (attrId) {
          $(this).removeClass('visible')
        } else {
          $(this).remove()
        }
      }
    })
  })
}