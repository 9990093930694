import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import { ContentWrapper, IconModal } from './SResetPassword'
import CustomerAPI from 'api/customers'
import toastr from 'utils/toast'
import FormContainer from 'components/common/FormInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SInput from 'components/common/Styled/Form/SInput'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import CommonUtils from 'utils/common'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { useAppDispatch } from 'store/store'
import SIcon from 'components/common/Styled/SIcon'

const ResetPassword = ({ isNoStep3 }: { isNoStep3?: boolean }) => {
  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: { login: '' },
    resolver: yupResolver(
      yup.object({
        login: yup.string().trim().required(t('validations.required_email')).email(t('validations.email')),
      })
    ),
  })
  const dispatch = useAppDispatch()
  const handleResetPassword = async (e: { login: string }) => {
    await CustomerAPI.resetPassword({ login: e.login })
    dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))
    toastr.success(t('devise.passwords.send_paranoid_instructions'))
    hideLoading()
  }
  const LOGO_ICON = CommonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN

  return (
    <ModalNormal
      topLevel={!isNoStep3}
      mt={60}
      closeVariant="g1"
      customFixedWidth={'420px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
      closeIcon={
        !isNoStep3 && (
          <SButton
            br={`1px solid ${theme.colors.green[1]}`}
            h="30px"
            w="30px"
            rds="50%"
            variant="g3"
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(''))}
          >
            <SIcon color={theme.colors.green[1]} fs={16} p={4} value="close" />
          </SButton>
        )
      }
    >
      <ContentWrapper>
        <IconModal>
          <img src={LOGO_ICON} width={75} alt="LOGO_ICON" />
        </IconModal>
        <STypography align="center" fw={700} mb={20} color={theme.colors.green[1]} fs={19}>
          {t('webapp.label.reset_password')}
        </STypography>
        <FormContainer methods={methods} onSubmit={(e) => handleResetPassword(e)}>
          <SInput label={t('devise_template.email_address')} name="login" />
          <SButton h="36px" mt={20} w="100%" variant="g2" type="submit">
            {t('devise_template.get_a_link_to_reset_password')}
          </SButton>
        </FormContainer>
        <SFlex pt={20} center>
          <STypography fs={14} color={theme.colors.gray[5]}>
            {t('devise_template.already_a_member', { app_name: CommonUtils.appName() })}
          </STypography>
          &nbsp;
          <STypography
            className="cur-pointer text-signin"
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))}
          >
            {t('devise_template.log_in_here')}
          </STypography>
        </SFlex>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default ResetPassword
