import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { PICKUP_OPTIMISED_TIME } from 'constants/ez_spread_sheet/variables/stepUploadTabsDataConstants'

const initialState: any = {
  fileName: '',
  name: '',
  desc: '',
  bookings: [],
  batch_type: '',
  has_geocoder: false,
  keep_data: false,
  by_option_extra: {
    item_length: 0,
    item_names: [],
  },
  verify: {
    name: true,
    uploadedFiles: true,
    batchBookingsNumber: true,
    minRowNumber: true,
  },
  invalidRows: [],
  ignoredRows: [],
  loadingTally: true,
  pickup_time_mode: PICKUP_OPTIMISED_TIME,
  gridColumns: [],
  isSignInFinished: false,
}

const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    updateBatch: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload.attrs }),
    resetBatchBooking: (state, action: PayloadAction<any>) => {
      state.bookings = []
    },
    updateBatchBookings: (state, action: PayloadAction<any>) => {
      const bookingsSort = _.sortBy(action.payload.bookings, [(bk) => _.toInteger(bk.order)])
      const newBookings = bookingsSort.map((booking) => ({
        ...booking,
        locations: _.sortBy(booking.locations, ['order']),
      }))
      state.bookings = newBookings
    },
    addNewBookings: (state, action: PayloadAction<any>) => {
      state.bookings.push(action.payload.booking)
    },
    deleteSelectedBookings: (state) => {
      _.remove(state.bookings, { is_selected: true })
    },
    updateBooking: (state, action: PayloadAction<any>) => {
      const bookingIndex = _.findIndex(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex !== -1) {
        state.bookings[bookingIndex] = { ...state.bookings[bookingIndex], ...action.payload.booking }
      }
    },
    updateBookingLocation: (state, action: PayloadAction<any>) => {
      const bookingIndex = _.findIndex(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex !== -1) {
        const booking = state.bookings[bookingIndex] 
        const locationIndex = _.findIndex(
          booking.locations,
          (location: any) => location.temp_id === action.payload.location.temp_id
        )

        if (locationIndex !== -1) {
          booking.locations[locationIndex] = { ...action.payload.location }
        }
      }
    },
    updateBookingLocationAttrs: (state, action: PayloadAction<any>) => {
      const bookingIndex = _.findIndex(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex !== -1) {
        const newLocations = state.bookings[bookingIndex].locations
        const locationIndex = _.findIndex(
          newLocations,
          (location: any) => location.temp_id === action.payload.location.temp_id
        )

        if (locationIndex !== -1) {
          newLocations[locationIndex] = { ...newLocations[locationIndex], ...action.payload.location }
        }
      }
    },
    deleteBookingLocation: (state, action: PayloadAction<any>) => {
      const booking = _.find(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (!_.isUndefined(booking)) {
        _.remove(booking.locations, { temp_id: action.payload.location.temp_id })
        // re-index order
        _.forEach(booking.locations, (loc, locIndex) => {
          _.assign(loc, { order: locIndex })
        })
      }
    },
    receiveDraftBookingIds: (state, action: PayloadAction<any>) => {
      const responseIDs = action.payload.responseIDs
      _.map(responseIDs, (responseID) => {
        const tempBooking = _.find(state.bookings, { temp_id: responseID.temp_id })
        if (!_.isEmpty(tempBooking)) {
          _.assign(tempBooking, { draft_id: responseID.id })
        }
      })
    },
    updateTransitTime: (state, action: PayloadAction<any>) => {
      const bookingIndex = _.findIndex(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex > -1) {
        const booking = state.bookings[bookingIndex]
        // TODO: mutating directly original data source is not a good way.
        //  but atm I don't have time to refactor code for ezsheet such as BookingTimeTypePopup, VehicleTypePopup
        state.bookings[bookingIndex] = {
          ...booking,
          ...action.payload.bookingAttrs,
        }
        if (booking.worst_transit_time === undefined) {
          delete booking.worst_transit_time
        }

      }

    },
    updateAllBookingsTransitTime: (state, action: PayloadAction<any>) => {
      state.bookings = _.map(state.bookings, (booking) => {
        const tallyData = _.find(action.payload, { tmp_id: booking.temp_id })

        return {
          ...booking,
          ...tallyData,
        }
      })
      
    },
    signInProgressFinished: (state, action: PayloadAction<any>) => {
      state.isSignInFinished = action.payload
    },
  },
  extraReducers: () => {},
})

export const batchActionsCreator = batchSlice.actions

export default batchSlice
