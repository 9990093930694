import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
// UTILS
// ACTIONS
// COMPONENTS
import DynamicPopupHandler from '../../DynamicPopupHandler'
// CONSTANTS
import { POPUP_ID_ASSIGN_DRIVERS } from 'constants/common/popupConstants'
// ASSETS

// API
import PopupAPI from 'api/popups'

class DriverPreferencesPopupEye extends React.Component {
  constructor(props) {
    super(props)
    this.renderDynamicContent = this.renderDynamicContent.bind(this)
    this.getPopupApi = this.getPopupApi.bind(this)
    this.state = {
      popupData: {},
      correctHeight: true,
    }
  }

  componentWillMount() {
    this.getPopupApi()
  }

  getPopupApi() {
    const {
      currentCustomer,
      objectID,
    } = this.props
    const popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup_eye.driver_preferences
    this.setState({
      popupConst: popupConstant
    })
    const params = {
      area_id: objectID,
      popup_type: popupConstant.item_type,
    }
    return new Promise((resolve) => {
      PopupAPI.getAreaTypePopup(currentCustomer?.authentication_token, params, (result) => {
        const popupdnd = result?.data?.object
        if (result?.status === 200 && !_.isEmpty(popupdnd)) {
          this.setState({
            popupData: popupdnd
          })
        }
        resolve()
      })
    })
  }

  generateObjectFromApi() {
    const { popupData } = this.state
    const popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup_eye.driver_preferences
    let obj = {}
    const selectItemTypes = [
      popupConstant.item_title,
      popupConstant.item_content,
    ]
    const titleItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    const popupTitle = _.isEmpty(titleItems[0]) ? '' : titleItems[0].formated_content
    obj = {
      icon: decodeURIComponent(popupData.icon),
      title: popupTitle,
      subTitle: '',
    }
    return obj
  }

  static renderPopupItem(item, index) {
    const popupConst = POPUP_ID_ASSIGN_DRIVERS.popup_eye.driver_preferences
    if(item.item_type === popupConst.item_content) {
      return (
        <div key={index}>
          <p className='center fullday' dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        </div>
      )
    }
    return <span key={index} />
  }

  static renderPopupItems(items) {
    return items.map((item, index) => DriverPreferencesPopupEye.renderPopupItem(item, index))
  }

  renderDynamicContent() {
    const { popupData, popupConst } = this.state
    const selectItemTypes = [
      popupConst.item_title,
      popupConst.item_content,
    ]
    const normalItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    return (
      <div>
        {DriverPreferencesPopupEye.renderPopupItems(normalItems)}
      </div>
    )
  }

  static renderEyeIcon() {
    return (
      <i className="material-icons Icon large-icon default-color-icon cur-pointer">
        visibility
      </i>
    )
  }

  render() {
    const { correctHeight, popupData } = this.state
    const { refNode } = this.props
    if (_.isEmpty(popupData)) {
      return null
    }
    return (
      <DynamicPopupHandler
        {...this.props}
        specialClass="Driver-Preferences-Popup-Eye flex flex-center"
        renderChild={DriverPreferencesPopupEye.renderEyeIcon}
        renderInfo={this.generateObjectFromApi()}
        renderDynamicContent={this.renderDynamicContent}
        renderNode={refNode.current}
        correctHeight={correctHeight}
      />
    )
  }
}

DriverPreferencesPopupEye.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  objectID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  refNode: PropTypes.instanceOf(Object).isRequired,
}

export default connect()(DriverPreferencesPopupEye)
