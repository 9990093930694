import React from 'react'
import PropTypes from 'prop-types'

// UTILS

// COMPONENTS
// CONSTANTS
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'
import I18n from 'i18n/i18n'
import './DiscountCode.scss'

class DiscountCode extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      discountCode = {}
    } = props

    this.state = {
      visible: !!discountCode.value,
      discountCodeText: discountCode.value,
      isTouched: false,
    }

    this.handleOnChangeDiscountCheckbox = this.handleOnChangeDiscountCheckbox.bind(this)
    this.handleOnChangeDiscountCode = this.handleOnChangeDiscountCode.bind(this)
    this.handleOnClickApply = this.handleOnClickApply.bind(this)
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const {
      discountCode: newDiscountCode
    } = nextProps
    const {
      isTouched
    } = this.state

    // The idea is: visible this component whenever it is set discount code value
    //    as long as user have not touched this component
    if (newDiscountCode && newDiscountCode.value && !isTouched) {
      this.setState(() => ({ visible: true }))
    }

    const {
      discountCode = {}
    } = this.props
    if (newDiscountCode && newDiscountCode.value !== discountCode.value) {
      this.setState(() => ({ discountCodeText: newDiscountCode.value }))
    }
  }

  handleOnClickApply() {
    const { discountCodeText } = this.state
    const { onApply } = this.props

    onApply(discountCodeText)
  }

  handleOnClickRemove() {
    const { discountCodeText } = this.state
    const { onRemove } = this.props
    this.setState(() => ({
      discountCodeText: '',
    }))
    onRemove(discountCodeText)
  }

  handleOnChangeDiscountCheckbox() {
    const { onRemove } = this.props
    const { visible, discountCodeText } = this.state
    const newVisible = !visible

    if (!newVisible) {
      this.setState(() => ({
        discountCodeText: '',
      }))
      onRemove(discountCodeText)
    }

    this.setState(prevState => ({
      visible: !prevState.visible,
      isTouched: true,
    }))
  }

  handleOnChangeDiscountCode(e) {
    const target = e.target
    const {
      onChangeStatus,
      discountCode: { status }
    } = this.props

    if (status !== DISCOUNT_CODE_STATUS.pending) {
      onChangeStatus(DISCOUNT_CODE_STATUS.pending)
    }

    this.setState(() => ({
      discountCodeText: target.value
    }))
  }

  renderActionButtons() {
    const {
      discountCode: { status }
    } = this.props
    const {
      discountCodeText
    } = this.state
    const isAbleToApply = !!discountCodeText

    if (status === DISCOUNT_CODE_STATUS.checking) {
      // better if we can show spinning
      return null
    }

    if (status === DISCOUNT_CODE_STATUS.pending || status === DISCOUNT_CODE_STATUS.draft) {
      return (
        <a className={`action-button apply ${isAbleToApply ? '' : 'disabled'}`} onClick={this.handleOnClickApply}>
          {I18n.t('webapp.new_booking.discount_code.apply')}
        </a>
      )
    }

    if (status === DISCOUNT_CODE_STATUS.invalid) {
      return (
        <a className={`action-button apply ${isAbleToApply ? '' : 'disabled'}`} onClick={this.handleOnClickApply}>
          {I18n.t('webapp.new_booking.discount_code.apply')}
        </a>
      )
    }

    if (status === DISCOUNT_CODE_STATUS.valid) {
      return (
        <a className="action-button remove" onClick={this.handleOnClickRemove}>
          {I18n.t('webapp.new_booking.discount_code.remove')}
        </a>
      )
    }

    return null
  }

  renderMessageByStatus() {
    const {
      discountCode: { status, invalidMessage },
    } = this.props

    if (status === DISCOUNT_CODE_STATUS.invalid) {
      return (
        <div className="message">
          <span className="pr5 icon-wrapper">
            <i className="b material-icons Icon">
              error
            </i>
          </span>
          <span>
            {invalidMessage}
          </span>
        </div>
      )
    }

    if (status === DISCOUNT_CODE_STATUS.valid) {
      return (
        <div className="message">
          <span className="pr5 icon-wrapper">
            <i className="b material-icons Icon">
              check_circle
            </i>
          </span>
          <span>
            {I18n.t('webapp.new_booking.discount_code.discount_code_applied')}
          </span>
        </div>
      )
    }

    return null
  }

  render() {
    const {
      discountCode, isSingleView
    } = this.props
    const {
      status,
    } = discountCode

    const {
      visible,
      discountCodeText,
    } = this.state
    const lockedEditing = status === DISCOUNT_CODE_STATUS.valid || status === DISCOUNT_CODE_STATUS.checking

    return (
      <div className={`discount-code-container ${isSingleView ? 'single-discount-code' : ''}`}>
        <div className="h1px Line-bg" />
        <div className={`discount-code block ${status || ''}`}>
          <div className="discount-code-title flex flex-row pr5 pl5 pb10">
            {!isSingleView && (
              <div className="pr5 grow-2">
                {I18n.t('webapp.new_booking.discount_code.use_discount_code')}
              </div>
            )}
            {/* <span className="pr5 icon-wrapper">
              <i className="b material-icons Icon">
                help_outline
              </i>
            </span> */}
            <div className="">
              <div className="Overlay-Checkbox With-Icon Green-Checkbox">
                <input
                  type="checkbox"
                  checked={visible}
                  onChange={this.handleOnChangeDiscountCheckbox}
                />
                <label>
                  <i className="b material-icons Icon">
                    check
                  </i>
                </label>
              </div>
            </div>
            {isSingleView && (
              <div className="pr5 grow-2">
                {I18n.t('webapp.new_booking.discount_code.use_discount_code')}
              </div>
            )}
          </div>
          {
            visible && (
              <div className="input-container pb15 pr5 pl5">
                <div className="Input">
                  <input
                    placeholder={I18n.t('webapp.new_booking.step_3.enter_code')}
                    /* defaultValue={discountCodeValue} */
                    value={discountCodeText}
                    onChange={this.handleOnChangeDiscountCode}
                    disabled={lockedEditing}
                  />
                  <div className="pr10 action-buttons">
                    {
                      this.renderActionButtons()
                    }
                  </div>
                </div>
                {
                  this.renderMessageByStatus()
                }
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

DiscountCode.defaultProps = {
  discountCode: {
    value: '',
    status: DISCOUNT_CODE_STATUS.pending,
  },
  isSingleView: PropTypes.bool,
}

DiscountCode.propTypes = {
  discountCode: PropTypes.shape({
    value: PropTypes.string,
    status: PropTypes.string,
  }),
  onApply: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  isSingleView: false,
}

export default DiscountCode
