import { css } from 'styled-components'
import { styled } from '../../STheme'
export const InputPhoneWrapper = styled.div<{ error?: boolean }>`
  ${({ error, theme }) =>
    error &&
    css`
      .signup-number {
        border: ${error ? `1px solid ${theme.colors.red[1]}` : '1px solid #cecece'};
      }
    `}
`
