import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: { page: number; totalPage: number; perPage: number } = {
  page: 1,
  totalPage: 1,
  perPage: 20,
}

const paginationSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updatePage: (state: { page: number }, action: PayloadAction<any>) => {
      state.page = action.payload
    },
    updateTotalAndPerpage: (
      state: { page: number; totalPage: number; perPage: number },
      action: PayloadAction<any>
    ) => {
      state.totalPage = action.payload.totalPage
      state.perPage = action.payload.perPage
    },
  },
})
export const paginationAction = paginationSlice.actions
export default paginationSlice
