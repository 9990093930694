import React, { FC, ReactElement } from 'react'
import { SCol, SContainer } from '../Styled/SLayout/SContainer'
import SInput from '../Styled/Form/SInput'
import SInputPhone from '../Styled/Form/SInputPhone'
import SCheckBox from '../Styled/Form/SCheckBox'

export enum TYPE_INPUT {
  INPUT = 'INPUT',
  PHONE = 'PHONE',
  CHECK_BOX = 'CHECK_BOX',
  BLANK_INPUT = 'BLANK_INPUT',
}

export interface IFormItem {
  label: string
  type: TYPE_INPUT
  name: string
  required?: boolean
  disabled?: boolean
  className?: string
  handleOnChange?: (data: any) => void
  colum?: number
  placeholder?: string
  hidden?: boolean
  actionButton?: ReactElement
  classNameCol?: string
  secured?: boolean
}

const FormItem: FC<{ arrayFormItem: IFormItem[]; colGap?: number }> = ({ arrayFormItem, colGap }) => {
  return (
    <SContainer colGap={colGap} col={24}>
      {arrayFormItem.map((item, index: number) => {
        const {
          label,
          name,
          className,
          required,
          disabled,
          colum,
          placeholder,
          hidden,
          actionButton,
          classNameCol,
          type,
          secured,
        } = item
        const ARRAY_INPUT_TYPE = {
          [TYPE_INPUT.INPUT]: (
            <SInput
              label={label}
              name={name}
              className={className}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              hidden={hidden}
              secured={secured}
            />
          ),
          [TYPE_INPUT.PHONE]: (
            <SInputPhone
              label={label}
              name={name}
            />
          ),
          [TYPE_INPUT.CHECK_BOX]: (
            <SCheckBox
              label={label}
              name={name}
              required={required}
              disabled={disabled}
              inputType={'radio'}
            />
          ),

          [TYPE_INPUT.BLANK_INPUT]: <div>{actionButton}</div>,
        }
        return (
          <SCol col={colum || 8} key={index} className={classNameCol}>
            {ARRAY_INPUT_TYPE[type]}
          </SCol>
        )
      })}
    </SContainer>
  )
}

export default FormItem
