import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const isSmartLoad = window.location.pathname?.split('/').includes('smart_load_planner')
const isBatchEZ = window.location.pathname?.split('/').includes('ez_spread_sheet')
const initialState = isSmartLoad || isBatchEZ ? 0 : 1

const currentStepSlice = createSlice({
  name: 'currentStep',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<any>) => {
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentStepActionsCreator = currentStepSlice.actions

export default currentStepSlice
