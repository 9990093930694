import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EZ_SHEET_UPLOAD, SMART_LOAD_PLANNER } from 'constants/common/batchConstants'
import { IMapping } from 'interface/IBatchTemplate'
import { isSmartPlanner } from 'utils/common/batch'

interface BaseState {
  id: undefined
  user_template_type: undefined
  customer_id: undefined
  company_id: undefined
  contact_id: undefined
  pickup_time: string
  vehicle_type_id: undefined
  same_pickup: boolean
  same_pickup_datetime: boolean
  same_vehicle_type: boolean
  same_extra_requirements: boolean
  same_custom_reimbursements: boolean
  same_time_type: boolean
  extra_requirements: any[]
  consolidates: boolean
  batch_type: string
  mapping: any
  same_sub_account_tag: boolean
  customer_default_mapping: {
    consolidates: number
    job_order_number: number
    sub_account_tag: number
    vehicle_type: number
    time_type: number
    extra_requirements: number
    pickup_date_time: number
    name: number
    phone: number
    address_01: number
    address_02: number
    district: number
    city: number
    state_province: number
    postcode: number
    location_note: number
    latitude: number
    longitude: number
    express_mail_to_name: number
    express_mail_to_phone: number
    express_mail_to_address: number
    express_mail_to_city: number
    express_mail_to_state: number
    express_mail_to_postal_code: number
    other_reimbursements: number
    note_to_driver: number
  }
  company_default_mapping: {
    consolidates: number
    job_order_number: number
    sub_account_tag: number
    vehicle_type: number
    time_type: number
    extra_requirements: number
    pickup_date_time: number
    name: number
    phone: number
    address_01: number
    address_02: number
    district: number
    city: number
    state_province: number
    postcode: number
    location_note: number
    latitude: number
    longitude: number
    need_pod: number
    pod_note: number
    need_cod: number
    cod_invoice_fees: number
    cod_note: number
    other_reimbursements: number
    note_to_driver: number
  }
  position: {
    company: {
      left_column: string[]
      right_column: string[]
    }
    individual: {
      left_column: string[]
      right_column: string[]
    }
  }
}

const initialStateEzSheet = {
  id: undefined,
  user_template_type: undefined,
  customer_id: undefined,
  company_id: undefined,
  contact_id: undefined,
  pickup_time: '',
  vehicle_type_id: undefined,
  same_pickup: false,
  same_pickup_datetime: false,
  same_vehicle_type: false,
  same_sub_account_tag: false,
  same_extra_requirements: false,
  same_custom_reimbursements: false,
  same_time_type: false,
  extra_requirements: [],
  consolidates: true,
  batch_type: EZ_SHEET_UPLOAD,
  mapping: {} as IMapping,
  customer_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    sub_account_tag: 2,
    vehicle_type: 3,
    time_type: 4,
    extra_requirements: 5,
    pickup_date_time: 6,
    name: 7,
    phone: 8,
    address_01: 9,
    address_02: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
    express_mail_to_name: 18,
    express_mail_to_phone: 19,
    express_mail_to_address: 20,
    express_mail_to_city: 21,
    express_mail_to_state: 22,
    express_mail_to_postal_code: 23,
    other_reimbursements: 24,
    note_to_driver: 25,
  },
  company_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    sub_account_tag: 2,
    vehicle_type: 3,
    time_type: 4,
    extra_requirements: 5,
    pickup_date_time: 6,
    name: 7,
    phone: 8,
    address_01: 9,
    address_02: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
    express_mail_to_name: 18,
    express_mail_to_phone: 19,
    express_mail_to_address: 20,
    express_mail_to_city: 21,
    express_mail_to_state: 22,
    express_mail_to_postal_code: 23,
    need_pod: 24,
    pod_note: 25,
    need_cod: 26,
    cod_invoice_fees: 27,
    cod_note: 28,
    other_reimbursements: 29,
    note_to_driver: 30,
  },
  position: {
    company: {
      left_column: [
        'job_order_number',
        'sub_account_tag',
        'vehicle_type',
        'time_type',
        'extra_requirements',
        'pickup_date_time',
        'name',
        'phone',
        'address_01',
        'address_02',
        'district',
        'city',
      ],
      right_column: [
        'state_province',
        'postcode',
        'location_note',
        'latitude',
        'longitude',
        'need_pod',
        'pod_note',
        'need_cod',
        'cod_invoice_fees',
        'cod_note',
        'other_reimbursements',
        'note_to_driver',
        'express_mail_to_name',
        'express_mail_to_phone',
        'express_mail_to_address',
        'express_mail_to_city',
        'express_mail_to_state',
        'express_mail_to_postal_code',
      ],
    },
    individual: {
      left_column: [
        'job_order_number',
        'sub_account_tag',
        'vehicle_type',
        'time_type',
        'extra_requirements',
        'pickup_date_time',
        'name',
        'phone',
        'address_01',
        'address_02',
      ],
      right_column: [
        'district',
        'city',
        'state_province',
        'postcode',
        'location_note',
        'latitude',
        'longitude',
        'other_reimbursements',
        'note_to_driver',
        'express_mail_to_name',
        'express_mail_to_phone',
        'express_mail_to_address',
        'express_mail_to_city',
        'express_mail_to_state',
        'express_mail_to_postal_code',
      ],
    },
  },
}

const initialStateSLP = {
  id: undefined,
  user_template_type: undefined,
  customer_id: undefined,
  company_id: undefined,
  contact_id: undefined,
  pickup_time: '',
  vehicle_type_id: undefined,
  same_pickup: false,
  same_pickup_datetime: false,
  same_vehicle_type: false,
  same_extra_requirements: false,
  same_custom_reimbursements: false,
  same_time_type: false,
  extra_requirements: [],
  consolidates: true,
  batch_type: SMART_LOAD_PLANNER,
  mapping: {},
  customer_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    name: 2,
    phone: 3,
    item_length: 4,
    item_width: 5,
    item_height: 6,
    item_weight: 7,
    item_quantity: 8,
    address_1: 9,
    address_2: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
  },
  company_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    name: 2,
    phone: 3,
    item_length: 4,
    item_width: 5,
    item_height: 6,
    item_weight: 7,
    item_quantity: 8,
    address_01: 9,
    address_02: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
  },
  position: {
    company: {
      left_column: [
        'job_order_number',
        'name',
        'phone',
        'item_length',
        'item_width',
        'item_height',
        'item_weight',
        'item_quantity',
        'address_1',
        'address_2',
        'district',
        'city',
      ],
      right_column: ['state_province', 'postcode', 'location_note', 'latitude', 'longitude'],
    },
    individual: {
      left_column: [
        'job_order_number',
        'name',
        'phone',
        'item_length',
        'item_width',
        'item_height',
        'item_weight',
        'item_quantity',
        'address_1',
        'address_2',
        'district',
        'city',
      ],
      right_column: ['state_province', 'postcode', 'location_note', 'latitude', 'longitude'],
    },
  },
}

const initialState = (isSmartPlanner() ? initialStateSLP : initialStateEzSheet) as BaseState
const batchTemplateSlice = createSlice({
  name: 'batchTemplate',
  initialState,
  reducers: {
    updateBatchTemplate: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
  extraReducers: () => {},
})

export const batchTemplateActionsCreator = batchTemplateSlice.actions

export default batchTemplateSlice
