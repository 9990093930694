import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// UTILS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
import { NOUN_USER_TAG_ICON } from 'constants/imageConstants'
import { isEditBooking } from 'utils/booking/common'
import ModalSubAccount from '../../new_booking/guest_flow/ModalSubAccount'
import ToolTipsSubAccount from '../../new_booking/guest_flow/ToolTipsSubAccount'
import I18n from 'i18n/i18n'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'

const mapStateToProps = (state) => ({
  currentCustomer: state.currentCustomer,
  bookAgainDetails: state.bookAgainDetails,
  subAccountTagPicked: state.subAccountTagPicked,
})

function mapDispatchToProps(dispatch) {
  return {
    bookingsActions: bindActionCreators(bookingsActionCreators, dispatch),
    bookingActions: bookingActionCreators,
    bookingAgainDetailsActions: bindActionCreators(bookingAgainDetailsActionsCreator, dispatch),
    checkSubAccountActions: bindActionCreators(checkSubAccountActionsCreator, dispatch),
  }
}

const handleScrollToTag = (booking) => {
  if (
    booking.sub_account_tag &&
    booking.sub_account_tag.sub_account_id &&
    document.querySelector('#scroll-to-tag')
  ) {
    const element = document.querySelector('#scroll-to-tag')
    if (element) {
      element.scrollIntoView()
    }
  }
}

class SubAccountTag extends Component {
  constructor() {
    super()
    this.state = {
      showModalSubAccountTag: false,
      isChangedSubAccountTag: false,
    }
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    const { bookingActions, bookAgainDetails, isFromBookingCard } = this.props
    const hasSubAccountTagInBookAgain = !!(
      bookAgainDetails &&
      bookAgainDetails.sub_account_tag &&
      bookAgainDetails.sub_account_tag.sub_account_id
    )
    if (!hasSubAccountTagInBookAgain && !isFromBookingCard) {
      bookingActions.getSubAccountTagList()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModalSubAccountTag } = this.state
    const { booking, bookingActions, subAccountTagList, isFromBookingCard, isFromBookingDetail, subAccountTagPicked } =
      this.props

    const hasSubAccountTagInBooking = booking && booking.sub_account_tag && booking.sub_account_tag.sub_account_id
    const compareStateShowModalSubAccountTag = prevState.showModalSubAccountTag !== showModalSubAccountTag
    const compareListSubAccountTagList = prevProps.subAccountTagList !== subAccountTagList
    const compareCheckBoxSubAccountValue = prevProps.booking.isCheckSubAccount !== booking.isCheckSubAccount

    if (prevProps.subAccountTagPicked !== subAccountTagPicked) {
      this.setState({
        isChangedSubAccountTag: true,
      })
    }

    if (compareCheckBoxSubAccountValue && booking.isCheckSubAccount && !hasSubAccountTagInBooking) {
      this.openModal()
    }

    if (!isFromBookingCard && !isFromBookingDetail) {
      if (compareStateShowModalSubAccountTag && showModalSubAccountTag) {
        bookingActions.getSubAccountTagList()
      }

      if (
        (compareStateShowModalSubAccountTag || (compareListSubAccountTagList && !isEditBooking())) &&
        !showModalSubAccountTag
      ) {
        this.checkPairSubAccount()
      }

      handleScrollToTag(booking)
    }
  }

  handleCheckSubAccount(value) {
    const { onCheckSubAccountTag } = this.props
    onCheckSubAccountTag(value)
  }

  checkPairSubAccount() {
    /*
      While user selects sub account tag in web app
      and some reason admin change changes status (Unpaired or Unavailable) of this tag user chose at the same time
      we should check this tag on webapp still available or not then change UI
    */
    const {
      booking: { id, sub_account_tag: subAccountTag },
      booking,
      bookingActions,
      subAccountTagList,
      bookAgainDetails,
      checkSubAccountActions,
      bookingAgainDetailsActions,
    } = this.props
    const hasListSubAccountTag = !!(subAccountTagList && subAccountTagList.length > 0)

    // Booking
    if (booking && !id) {
      let check = false
      let subAccountID = null
      let subAccountName = null

      if (subAccountTagList?.length && subAccountTag?.sub_account_id &&
        subAccountTagList.findIndex((item) => item.id === subAccountTag.sub_account_id) !== -1
      ) {
        check = true
        subAccountID = subAccountTag.sub_account_id
        subAccountName = subAccountTag.sub_account_name
      }
      // New booking single don't have booking id
      const data = {
        sub_account_id: subAccountID,
        sub_account_name: subAccountName,
      }
      checkSubAccountActions.updateSubAccountCheckBox({ value: check })
      bookingActions.addSubAccount(data)
    }

    // Cancel to Edit
    if (bookAgainDetails && !_.isEmpty(bookAgainDetails.sub_account_tag)) {
      const newBookingAgain = {}
      if (!hasListSubAccountTag) {
        if (newBookingAgain && newBookingAgain.sub_account_tag) {
          newBookingAgain.sub_account_tag = null
        }
      } else if (
        subAccountTagList.findIndex((item) => item.id === bookAgainDetails.sub_account_tag.sub_account_id) === -1
      ) {
        newBookingAgain.sub_account_tag = null
      }
      bookingAgainDetailsActions.updateBookAgainDetails(newBookingAgain)
    }
  }

  openModal() {
    this.setState({
      showModalSubAccountTag: true,
    })
  }

  closeModal() {
    this.setState({
      showModalSubAccountTag: false,
    })
  }

  renderPickedSubAccount() {
    const {
      booking: { sub_account_tag: subAccountTag },
      isFromBookingCard,
      isFromBookingDetail,
      bookAgainDetails,
      subAccountTagPicked,
    } = this.props
    const hasSubAccountTagInBookAgain = !!(
      bookAgainDetails &&
      bookAgainDetails.sub_account_tag &&
      bookAgainDetails.sub_account_tag.sub_account_id
    )
    if (isFromBookingCard || isFromBookingDetail) {
      return <span>{subAccountTagPicked.sub_account_name || subAccountTag.sub_account_name}</span>
    }

    if (!hasSubAccountTagInBookAgain && subAccountTag) {
      if (subAccountTag.sub_account_name) {
        return <span>{subAccountTag.sub_account_name}</span>
      }
    }

    if (hasSubAccountTagInBookAgain) {
      return <span>{bookAgainDetails.sub_account_tag.sub_account_name}</span>
    }

    return null
  }

  renderHandleChangeSubAccount() {
    const {
      booking: { isCheckSubAccount, sub_account_tag: subAccountTag },
      bookAgainDetails,
      subAccountTagList,
      subAccountTagPicked,
      isFromBookingDetail
    } = this.props
    const { isChangedSubAccountTag } = this.state
    const hasListSubAccountTag = !!(subAccountTagList && subAccountTagList.length > 0)
    const hasSubAccountTagInBookAgain = !!(
      bookAgainDetails &&
      bookAgainDetails.sub_account_tag &&
      bookAgainDetails.sub_account_tag.sub_account_id
    )

    if (!subAccountTagPicked.sub_account_id && isChangedSubAccountTag && isFromBookingDetail) {
      return null
    }

    if (!hasListSubAccountTag && !hasSubAccountTagInBookAgain) {
      return null
    }

    if (
      (subAccountTagPicked.sub_account_id && isChangedSubAccountTag && isFromBookingDetail) ||
      (isCheckSubAccount && subAccountTag && subAccountTag.sub_account_id) ||
      hasSubAccountTagInBookAgain
    ) {
      return (
        <div className="sub-account-tag align-items-center">
          <label>
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title">
                <span className="b material-icons Icon mr10">
                  <img src={NOUN_USER_TAG_ICON} />
                </span>
                <span id="scroll-to-tag" style={{ margin: 'auto 0' }}>
                  {this.renderPickedSubAccount()}
                </span>
              </div>
            </div>
          </label>
          <span
            className="cur-pointer default-smaller-font default-color pt5 pr12 pb5 pl12 Radius-three Solid-Line-Default"
            style={{
              backgroundColor: '#f9f9f9',
              height: 12,
              width: 53,
              textAlign: 'center',
            }}
            onClick={() => {
              this.setState({
                showModalSubAccountTag: true,
              })
            }}
          >
            {I18n.t('webapp.long_haul.change')}
          </span>
        </div>
      )
    }
    return null
  }

  render() {
    const { showModalSubAccountTag } = this.state
    const {
      booking,
      booking: { isCheckSubAccount },
      subAccountTagList,
      isFromBookingCard,
      isFromBookingDetail,
      bookAgainDetails,
      subAccountTagPicked,
    } = this.props
    const { isChangedSubAccountTag } = this.state
    const hasSubAccountTagInBookAgain = !!(
      bookAgainDetails &&
      bookAgainDetails.sub_account_tag &&
      bookAgainDetails.sub_account_tag.sub_account_id
    )
    const hasListSubAccountTag = !!(subAccountTagList && subAccountTagList.length > 0)

    if (
      !isFromBookingDetail &&
      !isFromBookingCard &&
      !hasListSubAccountTag &&
      !hasSubAccountTagInBookAgain &&
      !showModalSubAccountTag
    ) {
      return null
    }

    return (
      <div className="Radius-Bottom-Default">
        <div>
          {!isFromBookingCard && !isFromBookingDetail && (
            <div className="TitleSubtitle-subtitle Gray-text-important">
              {I18n.t('webapp.add_a_tag_to_manage_this_booking')}
            </div>
          )}

          <div className="sub-account-tag">
            <label>
              <div className="TitleSubtitle">
                <div className="TitleSubtitle-title flex-center-algin">
                  <span className="mr5">{I18n.t('webapp.user_sub_account_tag')}</span>
                  <ToolTipsSubAccount
                    content={I18n.t('webapp.subAccountToolTip')}
                    isFromBookingCard={isFromBookingCard}
                    isFromBookingDetail={isFromBookingDetail}
                  />
                </div>
              </div>
            </label>
            <div className="block-item-action">
              <div className="Sub-Account-Tag-Checkbox With-Icon Light-Green-Checkbox">
                <input
                  type="checkbox"
                  checked={
                    isFromBookingDetail && !subAccountTagPicked.sub_account_id && isChangedSubAccountTag
                      ? false
                      : isCheckSubAccount || hasSubAccountTagInBookAgain
                  }
                  onChange={(e) => this.handleCheckSubAccount(e.target.checked)}
                />
                <label>
                  <i className="b material-icons Icon">check</i>
                </label>
              </div>
            </div>
          </div>
          {this.renderHandleChangeSubAccount()}
        </div>
        {showModalSubAccountTag && (
          <ModalSubAccount
            booking={booking}
            closeModal={this.closeModal}
            isFromBookingCard={isFromBookingCard}
            isFromBookingDetail={isFromBookingDetail}
          />
        )}
      </div>
    )
  }
}

SubAccountTag.propTypes = {
  booking: PropTypes.shape({}).isRequired,
  bookingsActions: PropTypes.shape({}).isRequired,
  bookingActions: PropTypes.func,
  onCheckSubAccountTag: PropTypes.func,
  subAccountTagList: PropTypes.arrayOf(PropTypes.object),
  bookAgainDetails: PropTypes.shape({}),
  isFromBookingCard: PropTypes.bool,
  isFromBookingDetail: PropTypes.bool,
}

SubAccountTag.defaultProps = {
  subAccountTagList: [],
  bookingActions: () => undefined,
  isFromBookingCard: false,
  onCheckSubAccountTag: () => undefined,
  bookAgainDetails: undefined,
  isFromBookingDetail: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountTag)
