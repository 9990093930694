import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { getLocationDisplayInfos } from 'utils/LocationUtil'
import { Utils } from 'utils/Utils'
import { enabledEditExtraRequirement } from 'utils/new_booking/common'
import I18n from 'i18n/i18n'

// API
// ACTIONS
import * as stepLocationActions from 'store/actions/new_booking/locationActionCreators'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
// COMPONENTS
// CONSTANTS
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  bookAgainDetails: state.bookAgainDetails,
  extraServices: state.extraServices,
  locations: state.locations,
  tmpLocations: state.tmpLocations,
  timeType: state.timeType,
  extraInfos: state.extraInfos,
})

function mapDispatchToProps(dispatch) {
  return {
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    actions: bindActionCreators(stepLocationActions, dispatch),
    editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch),
  }
}

class SelectionPerLocationPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onChangeToolBox(locationId, type, event) {
    let selectedAmount = event.target.value
    if (type === 'selectBox') {
      this.funcName = 'onChangeSelectBox'
      selectedAmount = _.toInteger(event.target.value)
    } else {
      this.funcName = 'onChangeCheckBox'
      selectedAmount = event.target.checked ? 1 : 0
    }
    const { actions } = this.props
    const data = this.dataExtraRequirementLocation(locationId, selectedAmount)
    actions.updateTmpLocation(locationId,{ extra_requirement_locations: data })
  }

  onConfirmHandler() {
    const {
      assignedDriver, locations, tmpLocations, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    const locationsWithoutMaker = _.map(locations, location => _.assign({}, location, { marker: {} }))
    if (assignedDriver && !_.isEqual(locationsWithoutMaker, tmpLocations)) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.onConfirmHandlerAfterCheckBookingDetails.bind(this),
          backFunc: this.hideSelectionPerLocationPopover.bind(this, true)
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
      this.hideSelectionPerLocationPopover(true)
    } else {
      this.onConfirmHandlerAfterCheckBookingDetails()
    }
  }

  onConfirmHandlerAfterCheckBookingDetails() {
    const { tmpLocations, actions } = this.props
    _.forEach(tmpLocations, (location) => {
      const data = _.cloneDeep(location.extra_requirement_locations)
      actions.updateLocation(location.id, { extra_requirement_locations: data })
    })
    this.hideSelectionPerLocationPopover(true)
  }

  onClickCebDisableCheckbox(fieldName) {
    const { editModeTextActions } = this.props
    editModeTextActions.updateEditModeText(fieldName)
  }

  dataExtraRequirementLocation(locationId, selectedAmount) {
    const {
      tmpLocations,
      extraServices
    } = this.props
    const tmpLocation = _.find(tmpLocations, { id: locationId })
    if (tmpLocation) {
      const location = _.cloneDeep(tmpLocation)
      const extraRequirement = extraServices.selectedExtraRequirementForPerLocation
      const index = _.findIndex(location.extra_requirement_locations,
        { extra_requirement_id: extraRequirement.id })
      if (index >= 0) {
        location.extra_requirement_locations[index].selected_amount = selectedAmount
      } else if (selectedAmount > 0) {
        const data = {
          extra_requirement_id: extraRequirement.id,
          is_flat_per_location: extraRequirement.is_flat_per_location,
          name: extraRequirement.name,
          selected_amount: selectedAmount,
          unit_price: extraRequirement.unit_price,
          position: extraRequirement.position
        }
        location.extra_requirement_locations.push(data)
      }
      return _.orderBy(location.extra_requirement_locations, ['position'])
    }
    return (null)
  }

  resetSelectionPerLocation() {
    const {
      locations,
      actions
    } = this.props
    _.forEach(locations, (location) => {
      const data = _.cloneDeep(location.extra_requirement_locations)
      actions.updateTmpLocation(location.id, { extra_requirement_locations: data })
    })
  }

  hideSelectionPerLocationPopover(isSaved) {
    if (!isSaved) {
      this.resetSelectionPerLocation()
    }
    $('.Selection-Per-Location.Popover').removeClass('visible')
  }

  renderCheckboxOrSelectBox(location) {
    const { extraServices } = this.props
    const extraRequirement = extraServices.selectedExtraRequirementForPerLocation
    const isEnabledEditExtraRequirement = enabledEditExtraRequirement(extraRequirement)
    const disableClass = isEnabledEditExtraRequirement ? '' : 'Disable'
    if (extraServices && extraServices.selectedExtraRequirementForPerLocation) {
      let selectedAmount = 0
      if (location.extra_requirement_locations
        && _.find(location.extra_requirement_locations, { extra_requirement_id: extraRequirement.id })) {
        selectedAmount = _.find(location.extra_requirement_locations,
          { extra_requirement_id: extraRequirement.id }).selected_amount
      }
      if (extraRequirement.amount > 1) {
        return (
          <div className="block-item-action">
            {
              !isEnabledEditExtraRequirement
              && (
                <div
                  className="Ceb-disable-field Disable-checkbox-overlay"
                  onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
                />
              )
            }
            <select
              value={selectedAmount}
              disabled={disableClass}
              onChange={e => this.onChangeToolBox(location.id, 'selectBox', e)}
            >
              <option>
                0
              </option>
              {_.range(extraRequirement.amount).map((value) => {
                const v = value + 1
                return (
                  <option value={v} key={v}>
                    {v}
                  </option>
                )
              })}
            </select>
          </div>
        )
      }
      return (
        <div className={`Overlay-Checkbox With-Icon White-Checkbox ${disableClass}`}>
          {
            !isEnabledEditExtraRequirement
            && (
              <div
                className="Ceb-disable-field Disable-checkbox-overlay"
                onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
              />
            )
          }
          <div className={`Overlay-Checkbox With-Icon White-Checkbox ${extraRequirement.can_toggle ? '' : 'Disable'}`}>
            <input
              type="checkbox"
              value="0"
              checked={selectedAmount > 0}
              onChange={e => this.onChangeToolBox(location.id, 'checkBox', e)}
            />
            <label>
              <i className="b material-icons Icon">
                check
              </i>
            </label>
          </div>
        </div>
      )
    }
    return (null)
  }

  renderLocation(location, index) {
    const {
      locations,
      tmpLocations
    } = this.props
    const origialLocation = _.find(locations, { id: location.id })
    if (_.isEmpty(origialLocation)) { return (null) }
    const locationDisplayInfos = getLocationDisplayInfos(tmpLocations.length, index)
    return !_.isEmpty(origialLocation.marker) && (
      <div className="Border-Bottom Border-Color-White pt10 pb10" key={index}>
        <div className="Popover-Item">
          <div className={locationDisplayInfos.className}>
            {locationDisplayInfos.label}
          </div>
          <label>
            {location.name}
          </label>
        </div>
        {this.renderCheckboxOrSelectBox(location)}
      </div>
    )
  }

  render() {
    const {
      tmpLocations,
      extraServices,
      extraInfos
    } = this.props
    const extraRequirement = extraServices.selectedExtraRequirementForPerLocation
    const disableClass = enabledEditExtraRequirement(extraRequirement) ? '' : 'Button-Disabled'
    return (
      <div className="Selection-Per-Location Popover custom step-2">
        <div className="Popover-Arrow Custom" />
        <div className="Popover-List">
          <div className="vertical-scroll Popover-List-Scroll h100">
            <div className="Popover-Item">
              <h3 className="Border-Bottom Border-Color-White pb10">
                {`${extraRequirement?.name} ${I18n.t('webapp.new_booking.step_2.extra_helper_per_location_desc')}`}
              </h3>
            </div>
            <div className="Location-Item">
              {tmpLocations.map((location, index) => this.renderLocation(location,
                index, extraInfos, extraRequirement))}
            </div>
          </div>
        </div>
        <div className="Actions">
          <div
            className="gray Button close-CodPod"
            onClick={() => this.hideSelectionPerLocationPopover(false)}
          >
            {I18n.t('webapp.action.cancel')}
          </div>
          <div
            className={`green Button ${disableClass}`}
            onClick={() => this.onConfirmHandler()}
          >
            {I18n.t('webapp.action.confirm')}
          </div>
        </div>
      </div>
    )
  }
}

SelectionPerLocationPopover.defaultProps = {
  assignedDriver: undefined,
  locations: [],
  tmpLocations: [],
  actions: () => undefined
}

SelectionPerLocationPopover.propTypes = {
  assignedDriver: PropTypes.shape({}),
  locations: PropTypes.instanceOf(Array),
  tmpLocations: PropTypes.instanceOf(Array),
  extraServices: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({}),
  editModeTextActions: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionPerLocationPopover)
