import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
// UTILS
import { currentServiceType } from 'utils/new_booking/common'
import { findPopup } from 'utils/common/popupable'
// ACTIONS
// import * as actions from 'store/actions/new_booking/serviceTypeActionCreators';
// COMPONENTS
// CONSTANTS
import {
  POPUP_ID_EXTRA_SERVICE_POPUP_EYE,
  ITEM_TYPE_EXTRA_PE_TITLE,
  ITEM_TYPE_EXTRA_PE_SUBTITLE,
  ITEM_TYPE_EXTRA_PE_CONTENT,
  ITEM_TYPE_EXTRA_PE_CONTENT_LAST,
  ITEM_TYPE_EXTRA_PE_CONTENT_FINAL,
} from 'constants/common/popupConstants'
import extraServiceUtils from 'utils/booking/extraServiceUtils'
// ASSETS

const mapStateToProps = state => ({
  currentServiceType: currentServiceType(state),
  extraRequirements: state.extraServices.extraRequirements,
  extraRequirementsNegativePosition: state.extraServices.extraRequirementsNegativePosition,
  timeType: state.timeType,
})

class ExtraRequirementPopups extends React.Component {
  static renderPopup(extraRequirement, popupType) {
    const popup = findPopup(extraRequirement, popupType)
    return !_.isEmpty(popup) && (
      <div
        className="vertical-scroll Modal Modal-No-action"
        id={`${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirement.id}`}
        key={extraRequirement.id}
      >
        <div className="Normal-Booking">
          <div className="Box">
            <div className="Box-Icon">
              <div className="Logo">
                {extraRequirement.popup_icon_url
                  && <img src={extraRequirement.popup_icon_url} alt="deliveree" />
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal Normal-Hyperlink-Custom">
                {ExtraRequirementPopups.renderPopupItems(popup.popup_items)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  static renderPopupItems(items) {
    return items.map((item, index) => ExtraRequirementPopups.renderPopupItem(item, index))
  }

  static renderPopupItem(item, index) {
    switch (item.item_type) {
      case ITEM_TYPE_EXTRA_PE_TITLE: {
        return (
          <h5 key={index} className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_EXTRA_PE_SUBTITLE: {
        return (
          <span key={index} className="Normal-Booking-Sub-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_EXTRA_PE_CONTENT: {
        return (
          <p key={index} className="center white-pre-wrap mb15" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_EXTRA_PE_CONTENT_LAST: {
        return (
          <div key={index}>
            <p className="center white-pre-wrap" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
            <div className="Normal-Booking-Devide" />
          </div>
        )
      }
      case ITEM_TYPE_EXTRA_PE_CONTENT_FINAL: {
        return (
          <p
            key={index}
            className="center custom-color mt-20-imp mb-10-imp white-pre-wrap"
            dangerouslySetInnerHTML={{ __html: item.formated_content }}
          />
        )
      }
      default:
        return <span />
    }
  }

  render() {
    const {
      timeType,
      extraRequirementsNegativePosition,
      extraRequirements,
    } = this.props
    const popupType = extraServiceUtils.getPopupType(currentServiceType.is_package, timeType)
    return (
      <div>
        {extraRequirementsNegativePosition
          .map(extraRequirement => ExtraRequirementPopups.renderPopup(extraRequirement, popupType))}
        {extraRequirements.map(extraRequirement => ExtraRequirementPopups.renderPopup(extraRequirement, popupType))}
      </div>
    )
  }
}

ExtraRequirementPopups.propTypes = {
  extraRequirementsNegativePosition: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  extraRequirements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeType: PropTypes.string,
}

ExtraRequirementPopups.defaultProps = {
  timeType: undefined,
}

export default connect(mapStateToProps, null)(ExtraRequirementPopups)
