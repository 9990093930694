import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomerAPI from 'api/customers';
import { useLocation } from 'react-router-dom';
import StorageKeys from 'constants/storage-keys';
import { getParamFromURL } from './booking/common';

function FreshChat() {
  const { customerId, companyId } = useSelector((state) => ({
    customerId: state.currentCustomer.id,
    companyId: state.currentCustomer.current_company_id,
  }));
  const location = useLocation();

  const isLiveTrackingPage = () => {
    var pathLiveTracking = /tracking/g;
    return pathLiveTracking.test(location.pathname);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, companyId]);

  const hotlineConfig = async (hotlineCustomer) => {
    let time = 0;
    if (window.fcWidget && window.fcWidget.isInitialized) {
      window.fcWidget.destroy();
      time = 500;
    }
    // init again if login
    setTimeout(() => {
      const isOpenChat = getParamFromURL('open') === 'chat'
      if (window.fcWidget) {
        window.fcWidget.init({
          ...hotlineCustomer,
          open: isOpenChat,
          config: {
            ...hotlineCustomer.config,
            ...(isLiveTrackingPage() ? { cssNames: { widget: 'fc_live_tracking' } } : {}),
          },
        });
        if (hotlineCustomer.restore_id == null) {
          window.fcWidget.on('user:created', (resp) => {
            const status = resp && resp.status;
            const data = resp && resp.data;
            if (status === 200) {
              window.fcWidget.user.setProperties({
                firstName: hotlineCustomer.firstName,
                lastName: hotlineCustomer.lastName,
                email: hotlineCustomer.email,
                phone: hotlineCustomer.phone,
                phoneCountryCode: hotlineCustomer.phoneCountryCode,
              });
              if (data && data.restoreId && hotlineCustomer.externalId) {
                CustomerAPI.updateFreshChatCustomer({
                  restore_id: data.restoreId,
                  customer_id: hotlineCustomer.externalId,
                });
              }
            }
          });
        }
        window.fcWidget.on('widget:loaded', () => {
          window.fcWidget.user.setMeta({
            BookingId: 'None',
            ...hotlineCustomer.custom_properties,
          });
        });
      }
    }, time);
  };

  const initialize = async (isLiveTracking) => {
    const token = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    try {
      const result = await CustomerAPI.getHotlineCurrentCustomer({ ...(token ? { access_token: token } : {}) });
      if (result.status === 200 && result.data) {
        hotlineConfig(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return null;
}

export default FreshChat;
