import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const Step3EnterPassword = ({ deletionAccount, onUpdateDeleteAccount }) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(true)

  return (
    <div className="step-3-enter-password">
      <h2 className="title">{t('webapp.deletion_account.enter_password')}</h2>
      <div className={`Input Custom ${!!deletionAccount.passwordError ? "password-error" : ""}`}>
        <input
          type={showPassword ? 'text' : 'password'}
          value={deletionAccount.password}
          onChange={e => onUpdateDeleteAccount('password', e.target.value)}
          autoComplete="off"
          tabIndex="1"
        />
        <label className={`Input-Icon `}>
          <input
            type="checkbox"
            className="hidden"
            onChange={() => setShowPassword(!showPassword)}
          />
          <i className={`xb material-icons Icon ${showPassword ? 'green' : ''}`}>
            visibility
          </i>
        </label>
      </div>
      {!!deletionAccount.passwordError && (
        <div className="enter-password-error mt10">
          <span className="message-error icon-error red">
            {deletionAccount.passwordError}
          </span>
        </div>
      )}
    </div>
  )
}

Step3EnterPassword.propTypes = {
  onUpdateDeleteAccount: PropTypes.func.isRequired,
  deletionAccount: PropTypes.object.isRequired
}

export default Step3EnterPassword
