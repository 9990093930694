import { ICON_FLAG_EN, ICON_FLAG_ID, ICON_FLAG_TH } from './imageConstants'

export const OWNER_ROLE = 'owner'
export const ADMIN_ROLE = 'admin'
export const APPROVED_STATUS = 'approved'
export const ALL_FLAG = {
  en: ICON_FLAG_EN,
  id: ICON_FLAG_ID,
  th: ICON_FLAG_TH,
}
