/* eslint-disable */
import $ from 'jquery';
import I18n from 'i18n/i18n'
import toastr from "utils/toast";

require('magnific-popup');

// toogle Pricing LongHaul on booking details page
export function PricingLongHaul() {
  $(document).on("click", ".Pricing-LongHaul-Button", function () {
    $(this).find('i').toggleClass('Collapse-Icon Rotate');
    $('.Pricing-LongHaul-To').toggleClass('Collapse');
    $(this).closest('.Pricing-LongHaul-Group').find('.w100').toggleClass('Pricing-LongHaul-Hide Pricing-LongHaul-Show')
    setTimeout(function () {
      if ($(".Pricing-LongHaul-Button").closest('.Modal').length != 0) {
        checkHeightPopup($(".Pricing-LongHaul-Button").closest('.Modal').find('.Popup'))
      }
    }, 0)
  });
}

$(function () {
  $(document).on("click", ".Modal-Head i", function () {
    $(this).parents(".Modal").removeClass("visible");
  });

  $(document).on('click', '.Modal.no-action', function () {
    closeModal();
  });

  if ($('.DetailBooking--Photos').length) {
    $('.DetailBooking--Photos').each(function () {
      if ($(this).find('.DetailBooking--ImageViewer').length < 2) {
        $(this).find('.DetailBooking--Photos-List').removeClass('DetailBooking--Photos-List')
      }
    });
  }

  $(document).on('click', '.driver-cancel-handler .driver-cancel-handler-icon', function click() {
    $(this).closest('.driver-cancel-handler').toggleClass('Show')
  })
})

// Show - hide loading gif photo
export function openLoading() {
  $("#loading-modal.Modal").addClass('visible');
};

export function closeLoading() {
  $("#loading-modal.Modal").removeClass('visible');
};

export function checkHeightPopup(element) {
  if (element.height() + 120 > element.parent().height()) {
    element.addClass('top-align No-Seft');
    $('.close-modal-common').css({
      'min-height': '100%',
      'height': element.height() + 120
    })
  } else {
    element.removeClass('top-align No-Seft');
  }
}

export function closeModal() {
  $('.vertical-scroll.Modal').removeClass('visible');
};
/* eslint-enable */

export function createIframeToSyncPTL(queryString, callback = () => undefined) {
  callback();
  // const ptlDomain = process.env.LTL_CUSTOMER_DOMAIN
  // if (!ptlDomain) {
  //   callback()
  //   return
  // }
  // const ptlDomainPH = process.env.REACT_APP_LTL_CUSTOMER_DOMAIN_PH
  // if (!ptlDomain) {
  //   callback()
  // } else {
  //   const fPTL = document.createElement('iframe')
  //   fPTL.sandbox = 'allow-same-origin allow-scripts allow-popups allow-forms'
  //   fPTL.src = `${ptlDomain}/?${queryString}`
  //   fPTL.onload = function onLoadedIframe() {
  //     fPTL.remove()
  //     callback()
  //   }
  //   document.body.appendChild(fPTL)
  // }

  // if (ptlDomainPH) {
  //   const fPtlPH = document.createElement('iframe')
  //   fPtlPH.sandbox = 'allow-same-origin allow-scripts allow-popups allow-forms'
  //   fPtlPH.src = `${ptlDomainPH}/?${queryString}`
  //   fPtlPH.onload = function onLoadedIframePH() {
  //     fPtlPH.remove()
  //   }
  //   document.body.appendChild(fPtlPH)
  // }
}

export const showLoading = () => {
  const loadingCom = document.getElementById('loading-modal')
  if (loadingCom && !loadingCom.className.includes('visible')) {
    loadingCom.classList.add('visible')
  }
}

export const hideLoading = () => {
  const loadingCom = document.getElementById('loading-modal')
  if (loadingCom && loadingCom.className.includes('visible')) {
    loadingCom.classList.remove('visible')
  }
}
