import $ from 'jquery'
import _, { isEmpty } from 'lodash-es'
import BatchesAPI from 'api/batches'
import ezSheetAPI from 'api/ezSheets'
import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'
import { EXTRA_REQUIREMENT_TYPE } from 'constants/ezSpreadSheetConstants'

const serviceLoaded = () => () => {
  $('#loading-modal').removeClass('visible')
}

export const getVehicleTypesAction = (areaId, companyId, isSmartPlanner = false) => async (dispatch) => {
  try {
    const vehicleTypes = await ezSheetAPI.getVehicleTypes(areaId, companyId, undefined, isSmartPlanner)
    const vehicleTypesData = vehicleTypes?.data?.vehicle_types
    if (!isEmpty(vehicleTypesData)) {
      dispatch(extraServicesActionsCreator.receiveVehicleTypes(vehicleTypesData))
      return vehicleTypesData
    }
    return []
  } catch (error) {
    throw new Error(error)
  }
}
export const getExtraRequirementVehicleTypesAction = (
  params, extraRequirementType,
) => async (dispatch) => {
  try {
    const extraRequirementsData = await ezSheetAPI.getExtraRequirementVehicleTypes(params, extraRequirementType)
    const data = extraRequirementsData?.data?.[extraRequirementType]?.data || []
    if (isEmpty(data)) return []
    switch (extraRequirementType) {
      case EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_REGULAR:
        dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypes(data))
        break
      case EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_LONG_HAUL:
        dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForLongHaul(data))
        break
      case EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_FULL_DAY:
        dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForFullday(data))
        break
      case EXTRA_REQUIREMENT_TYPE.REGULAR_BY_OPTION_EXTRAS:
        dispatch(extraServicesActionsCreator.receiveRegularByOptionExtras(data))
        break
      case EXTRA_REQUIREMENT_TYPE.FULL_DAY_BY_OPTION_EXTRAS:
        dispatch(extraServicesActionsCreator.receiveFullDayByOptionExtras(data))
        break
      case EXTRA_REQUIREMENT_TYPE.LONG_HAUL_BY_OPTION_EXTRAS:
        dispatch(extraServicesActionsCreator.receiveLongHaulByOptionExtras(data))
        break
      default:
        return []
    }
    return data
  } catch (error) {
    throw new Error(error)
  }
}
export const getCustomReimbursementsForEzSheetAction = (vehicleTypeIds, companyId) => async (dispatch) => {
  try {
    const reimbursements = await ezSheetAPI.getCustomReimbursementsForEzSheet(vehicleTypeIds, companyId)
    const reimbursementsData = reimbursements?.data?.custom_reimbursements || []
    if (!isEmpty(reimbursementsData)) dispatch(extraServicesActionsCreator.receiveBatchCustomReimbursement(reimbursementsData))
    return reimbursementsData
  } catch (error) {
    throw new Error(error)
  }
}
export const getVehicleTypeBadgesAction = (vehicleTypeIds, companyId) => async (dispatch) => {
  try {
    const vehicleTypeBadges = await ezSheetAPI.getVehicleTypeBadges(vehicleTypeIds, companyId)
    const vehicleTypeBadgesData = vehicleTypeBadges?.data?.vehicle_type_badges?.data || []
    if (!isEmpty(vehicleTypeBadgesData)) dispatch(extraServicesActionsCreator.receiveBatchVehicleTypeBadges(vehicleTypeBadgesData))
    return vehicleTypeBadgesData
  } catch (error) {
    throw new Error(error)
  }
}
export const getCompanyBadgesAction = companyId => async (dispatch) => {
  try {
    const companyBadges = await ezSheetAPI.getCompanyBadges(companyId)
    const companyBadgesData = companyBadges?.data?.company_badges?.data || []
    if (!isEmpty(companyBadgesData)) dispatch(extraServicesActionsCreator.receiveBatchCompanyBadges(companyBadgesData))
    return companyBadgesData
  } catch (error) {
    throw new Error(error)
  }
}

export const getExtraServices = (callback = () => { }, isSmartPlanner = false) => (dispatch, getState) => {
  const state = getState()
  const areaID = state.extraInfos?.id
  const timeType = 'schedule'
  const isFullDay = true
  const customer = state.currentCustomer
  const authenticationToken = customer.authentication_token
  const companyID = customer.current_company_id
  const isLH = true

  BatchesAPI.getExtraServices(
    authenticationToken, areaID, companyID, timeType, isFullDay, isLH,
    (results) => {
      if (!_.isEmpty(results)) {
        if (_.isUndefined(companyID)) {
          Promise.resolve(
            dispatch(extraServicesActionsCreator.receiveVehicleTypes(results.vehicle_types.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypes(results.extra_requirements_regular.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForFullday(results.extra_requirements_full_day.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForLongHaul(results.extra_requirements_long_haul.data)),
            dispatch(extraServicesActionsCreator.receiveRegularByOptionExtras(results.regular_by_option_extras.data)),
            dispatch(extraServicesActionsCreator.receiveFullDayByOptionExtras(results.full_day_by_option_extras.data)),
            (results.custom_reimbursements
              && dispatch(extraServicesActionsCreator.receiveBatchCustomReimbursement(results.custom_reimbursements))
          ),
            dispatch(extraServicesActionsCreator.receiveLongHaulByOptionExtras(results.long_haul_by_option_extras.data)),
            dispatch(serviceLoaded())
          ).then(() => {
            callback(getState().extraServices)
          })
        } else {
          Promise.resolve(
            dispatch(extraServicesActionsCreator.receiveVehicleTypes(results.vehicle_types.data)),
            dispatch(extraServicesActionsCreator.receiveBatchVehicleTypeBadges(results.vehicle_type_badges.data)),
            dispatch(extraServicesActionsCreator.receiveBatchCompanyBadges(results.company_badges.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypes(results.extra_requirements_regular.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForFullday(results.extra_requirements_full_day.data)),
            dispatch(extraServicesActionsCreator.receiveExtraRequirementVehicleTypesForLongHaul(results.extra_requirements_long_haul.data)),
            dispatch(extraServicesActionsCreator.receiveRegularByOptionExtras(results.regular_by_option_extras.data)),
            dispatch(extraServicesActionsCreator.receiveFullDayByOptionExtras(results.full_day_by_option_extras.data)),
            dispatch(extraServicesActionsCreator.receiveLongHaulByOptionExtras(results.long_haul_by_option_extras.data)),
            (results.custom_reimbursements
              && dispatch(extraServicesActionsCreator.receiveBatchCustomReimbursement(results.custom_reimbursements))
            ),
            dispatch(serviceLoaded())
          ).then(() => {
            callback(getState().extraServices)
          })
        }
      }
    },
    isSmartPlanner
  )
}

export const getBookingExtraRequirements = (booking, callback = () => { }) => (dispatch, getState) => {
  const state = getState()
  const customer = state.currentCustomer
  const companyId = customer.current_company_id
  const authenticationToken = customer.authentication_token
  BatchesAPI.getBookingExtraRequirements(
    authenticationToken, booking, companyId,
    (results) => {
      Promise.resolve(
        dispatch(extraServicesActionsCreator.bookingExtraRequirements(results.extra_requirement_vehicle_types.data)),
        dispatch(extraServicesActionsCreator.bookingExtraRequirementsNegativePosition(results.extra_requirements_negative_position.data))
      ).then(
        callback()
      )
    }
  )
}

export { getExtraServices as default }
