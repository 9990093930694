import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import trim from 'lodash/trim'

interface HelpTooltipProps {
  dynamicText: {
    text: string,
    hyperlink_text: string,
    hyperlink_url: string
  },
}

const HelpTooltip = ({ dynamicText } : HelpTooltipProps) => {
  const { text, hyperlink_text: hyperLinkText, hyperlink_url: hyperLink } = dynamicText
  const [showToolTip, setShowToolTip] = React.useState(false)
  const wrapperRef = useRef<any>(null)

  useEffect(() => {
    const handleClickOutside = (event: { target: any}) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setShowToolTip(false)
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if(!text) return null

  const renderToolTip =  () => {
    if (
      hyperLink?.length > 0 &&
      hyperLinkText?.length > 0 &&
      text.indexOf(hyperLinkText) !== -1 &&
      trim(text).length >= trim(hyperLinkText).length
    ) {
      const arrText = text.split(hyperLinkText)
      return (
        <div className="sub-account-tooltip-content bottom">
          {arrText[0]}
          <a href={hyperLink} target='_blank' rel="noopener noreferrer">{hyperLinkText}</a>
          {arrText[1]}
        </div>
      )
    }
    return <div className="sub-account-tooltip-content bottom">{text}</div>
    
  }

  return (
    <span
      ref={wrapperRef}
      className='sub-account-tooltip'
      onClick={() => setShowToolTip(true)}
    >
      {showToolTip && renderToolTip()}
      <i className={`material-icons Icon cur-pointer vertical-align-middle ${showToolTip ? 'green' : 'gray'} White-bg`}>
        help_outline
      </i>
    </span>
  )
}

HelpTooltip.propTypes = {
  dynamicText: PropTypes.shape({
    text: PropTypes.string,
    hyperlink_text: PropTypes.string,
    hyperlink_url: PropTypes.string,
  }).isRequired,
}

export default HelpTooltip
