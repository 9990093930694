import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import BatchesAPI from '../../api/batches'
import { SCHEDULE, FULL_DAY, LONG_HAUL, NOW } from '../../constants/bookingConstants'

export const calculateParallel = async (bookingParam, currentCustomer, callback) => {
  const res = await BatchesAPI.calculateParallelApi(bookingParam, currentCustomer)
  const response = res
  if (response?.data?.error) {
    toastr.error(response.data.error)
    hideLoading()
    return null
  }
  return callback(response?.data?.object)
}

export const getFootNoteBatch = async (listBooking, currentCustomer) => {
  const timeTypes = listBooking.map((booking) => booking.time_type)
  let timeType = FULL_DAY
  if (timeTypes.includes(SCHEDULE)) {
    timeType = SCHEDULE
  } else if (timeTypes.includes(LONG_HAUL)) {
    timeType = LONG_HAUL
  } else if (timeTypes.includes(NOW)) {
    timeType = NOW
  }
  const listBookingHasTimeType = listBooking.filter((booking) => booking.time_type === timeType)
  let bookingFootNote = listBookingHasTimeType[0]
  const index = listBookingHasTimeType.findIndex((booking) => booking.surcharges_fees > 0)
  if (index !== -1) {
    bookingFootNote = listBookingHasTimeType[index]
  }
  const params = {
    time_type: bookingFootNote.time_type,
    vehicle_type_id: bookingFootNote.vehicle_type_id,
    ...(currentCustomer.current_company_id && { company_id: currentCustomer.current_company_id }),
  }
  const { data } = await BatchesAPI.getFootNoteBatchApi(params, currentCustomer)
  return data?.footnote_for_batch
}
