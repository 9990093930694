import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  pickupTime: '',
  status: '',
  estAllBookings: '',
  backupEstAllBookings: '',
}

const pickupTimeSmartPlannerSlice = createSlice({
  name: 'pickupTimes',
  initialState,
  reducers: {
    updatePickupTime: (state, action: PayloadAction<any>) => {
      state.pickupTime = action.payload
    },
    updatePickupStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload
    },
    updateEstAllBookings: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
  },
  extraReducers: () => {},
})

export const pickupTimesActionsCreator = pickupTimeSmartPlannerSlice.actions

export default pickupTimeSmartPlannerSlice
