import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'
import { BACKGROUND_SELECT_AREA } from 'constants/imageConstants'
import { css } from 'styled-components'

const AccountManagementWrapper = styled.div<ISBase & { isNoStep3: boolean }>`
  ${({ isNoStep3 }) =>
    isNoStep3 &&
    css`
      background-image: ${`url(${BACKGROUND_SELECT_AREA})`};
      position: fixed;
      top: 60px;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
    `}
`
export { AccountManagementWrapper }
