import { styled } from '../Styled/STheme'
interface IIconModal {
  br?: number
  bg?: string
  width?: string
  height?: string
  iWidth?: number
  iHeight?: number
  idM?: string
  
}
export const ModalWrapper = styled.div<{ bgColor?: string }>`
  position: fixed;
  z-index: 100010;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor || `rgba(0, 0, 0, 0.8)`};
  overflow-y: auto;
`

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const ModalContainer = styled.div<{
  backgroundColor?: string
  width?: number
  minWidth?: number
  minHeight?: string
  padding?: string
  fullscreen?: boolean
  maxHeight?: string
  height?: number
  maxWidth?: string
  fixedWidth?: string
  fixedHeight?: string
  isShareLink?: boolean
  borderRadius?: string
  overflow?: string
}>`
  position: absolute;
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px;` : '6px')};
  box-sizing: border-box;
  max-height: ${({ maxHeight }) => maxHeight || `80vh`};
  max-width: ${({ maxWidth }) => maxWidth || '80vw'};
  padding: ${({ padding }) => padding || `0`};
  min-width: ${({ minWidth }) => minWidth || 400}px;
  min-height: ${({ minHeight }) => minHeight};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${({ theme }) => theme.zIndex[2] + 2};
  ${({ width, height, fullscreen }) => {
    if (fullscreen) {
      return `width: 100%; height: 100%; padding: 0;`
    } else if (width && height) {
      return `width: ${width}vw; height: ${height}vh;`
    }
  }}

  ${({ fixedWidth }) =>
    fixedWidth &&
    `
    width: ${fixedWidth};
  `}

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
    height: ${fixedHeight};
  `}

  @media screen and (max-width: 800px) {
    width: calc(100% - 24px);
    min-width: unset;
    margin: auto;
    padding: ${({ padding }) => padding || `12px`};
  }
`

export const IconModal = styled.div<IIconModal>`
  background: ${(props) => props.theme.colors.white[1]};
  height: ${({ iHeight }) => (iHeight ? `${iHeight}px` : '64px')};
  width: ${({ iWidth }) => (iWidth ? `${iWidth}px` : '64px')};
  border-radius: ${({ iHeight }) => (iHeight ? `${iHeight}px` : '64px')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ iHeight }) => (iHeight ? `-${iHeight / 2}px` : '-32px')};
  left: calc(50% - ${({ iWidth }) => (iWidth ? `${iWidth / 2}px` : '32px')});
  img {
    width: auto;
    height: 40px;
  }
`
export const IdModal = styled.div<IIconModal>`
  background: transparent;
  font-size: ${(p) => p.theme.fontSizes[16]};
  color: ${(p) => p.theme.colors.yellow[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: ${(p) => (p.idM ? p.idM : '')};
`
export const ContentModal = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 16px;
  padding-top: 32px;
  background: ${(props) => props.theme.colors.green[1]};
  color: ${(props) => props.theme.colors.white[1]};
`
