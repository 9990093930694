import $ from 'jquery'
import _ from 'lodash'
// UTILS
// API
// ACTIONS
// COMPONENTS
// CONSTANTS
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
// ASSETS
export const prevStep = () => (dispatch, getState) => {
  const batchStepActions = getState().extraInfos.batch_step_actions
  const stepActions = _.values(batchStepActions)
  const currentStep = getState().currentStep
  const stepIndex = _.indexOf(stepActions, currentStep)
  const step = stepActions[stepIndex - 1]
  dispatch(currentStepActionsCreator.changeStep(step))
}

export const nextStep = () => (dispatch, getState) => {
  const batchStepActions = getState().extraInfos.batch_step_actions
  const stepActions = _.values(batchStepActions)
  const currentStep = getState().currentStep
  const stepIndex = _.indexOf(stepActions, currentStep)
  const step = stepActions[stepIndex + 1]
  dispatch(currentStepActionsCreator.changeStep(step))
}

export const changeToStep = stepToChange => (dispatch) => {
  dispatch(currentStepActionsCreator.changeStep(stepToChange))
}

export const loading = () => () => {
  $('#loading #loading-modal').addClass('visible')
}

export const loaded = () => () => {
  $('#loading #loading-modal').removeClass('visible')
}
