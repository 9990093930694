import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const IconModal = styled.div<ISBase>`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`
const SignInWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .facebook-login,
  .google-login {
    box-shadow: 0 0 10px #bbbbbb;
    background: #fff;
    border-radius: 4px;
    :hover {
      background-color: #efefef;
      color: #333;
    }
  }
  .facebook-login {
    img {
      margin-right: 5px;
      width: 36px;
      height: 36px;
    }
  }
  .google-login {
    img {
      margin-right: 7px;
      width: 22px;
      height: 22px;
    }
  }
`
export { IconModal, SignInWrapper }
