import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from "constants/extraServiceConstants"
import { POPUP_TYPE_EXTRA_POPUP_EYE, POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE, POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY, POPUP_TYPE_EXTRA_POPUP_EYE_LONG_HAUL } from "constants/common/popupConstants"
import { FULL_DAY, LONG_HAUL } from 'constants/bookingConstants'

const extraServiceUtils = {
  extraRequirementsStep2: (extraRequirements = []) => extraRequirements?.filter(i => 
    !i.hide_from_services_selection || i.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
  ),
  getPopupType: (isPackageService, timeType) => {
    if (isPackageService) {
      return POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE 
    } else if (timeType === FULL_DAY) {
      return POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY
    } else if (timeType === LONG_HAUL) {
      return POPUP_TYPE_EXTRA_POPUP_EYE_LONG_HAUL
    }
    return POPUP_TYPE_EXTRA_POPUP_EYE
  }
}
export default extraServiceUtils