import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import _ from 'lodash'
// ASSETS
import {
  LOCATION_ICON,
  LOCATION_NAME_ICON,
  LOCATION_ADDRESS_ICON,
  LOCATION_NUMBER_ICON,
  LOCATION_TITLE_ICON,
  LOCATION_EMAIL_ICON,
  LOCATION_COMPANY_ICON,
  TRASH_ICON,
  ADDRESS_DETAIL_ICON,
} from 'constants/imageConstants'
// UTILS
import { LocationPopover } from 'utils/LocationUtil'
import locationUtils from 'utils/common/location'
import I18n from 'i18n/i18n'
// COMPONENTS
import InputPhoneNumber from '../InputPhoneNumber'
import AddressInput from '../AddressInput'
import * as eventTracking from 'utils/trackingMoengage'
import { SOURCE_GOOGLE } from 'constants/common/locationConstants'

class FormLocationPopup extends React.PureComponent {
  
  static handleClose(customClassAutoComplete) {
    if (customClassAutoComplete) {
      LocationPopover.addClass($('body'), customClassAutoComplete)
    }
    ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup'))
  }

  static escFunction(event) {
    if (event.keyCode === 27) {
      FormLocationPopup.handleClose()
    }
  }

  constructor(props) {
    super(props)
    const contact = props.contact
    this.state = {
      isShowResultFromAPI: false,
      showConfirmMessage: false,
      contactMessage: '',
      showAddressDetail: !!(contact && contact.address && _.size(contact.address_components)),
      showTrashIcon: contact && contact.id,
      phoneNumber: '',
      contact,
      addressError: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.showConfirmDeleteMessage = this.showConfirmDeleteMessage.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
  }

  componentDidMount() {
    if (this.refInputAddress.value) {
      this.handleSetAddressDefault()
    }
    document.addEventListener('keydown', FormLocationPopup.escFunction, false)
  }

  componentWillUnmount() {
    // this.refInputAddress.removeEventListener('keydown', this.handleAddressEnter)
    document.removeEventListener('keydown', FormLocationPopup.escFunction, false)
  }

  handleChangeLocation = () => {
    this.resetContactAddress()
  }

  handleOnKeyUp = (event) => {
    const value = event.target.value
    if (_.isEmpty(value) && (event.which === 8 || event.which === 46)) {
      this.setState({ addressError: '' })
    }
  }

  resetContactAddress() {
    const { contact } = this.state
    const contactWithNoAddress = {
      ...contact,
      address_components: undefined,
      latitude: undefined,
      longitude: undefined
    }
    this.setState({ contact: contactWithNoAddress, showAddressDetail: false })
  }

  handelSetRefInputValue(place, contact) {
    if (this.refInputAddress) {
      this.refInputAddress.blur()
      this.setState({ showAddressDetail: true, addressError: '' })
      // validate lazy address
      const { extraInfos } = this.props
      locationUtils.validateLazyAddress(place, extraInfos, null, this.errorMessage, null, (lazyAddressError) => {
        this.setState({
          addressError: lazyAddressError
        })
      })
    }
    this.setState({ contact })
  }

  handleSetPlace(place, isSearchAddress = false) {
    const { isPTL } = this.props
    if (_.size(place)) {
      if (isSearchAddress) {
        this.refInputAddress.value = place.formatted_address
      }

      const { lat, lng } = locationUtils.getLatLng(place?.location)
      _.assign(place, {
        latitude: lat, longitude: lng
      })
      const { contact } = this.state
      const contactWithUpdatedAddress = {
        ...contact,
        address_components: place.address_components,
        address: place.formatted_address,
        latitude: parseFloat(place.latitude),
        longitude: parseFloat(place.longitude)
      }
      this.handelSetRefInputValue(place, contactWithUpdatedAddress)

      const isGoogle = place?.source === SOURCE_GOOGLE
      eventTracking.eventSetLocation({
        source: `Location search - ${isGoogle ? 'Google' : 'DB'}`,
        location: 'my location',
        coordinate: place?.location,
        isPTL,
      })
    }
  }

  handleSetAddressDefault() {
    const { contact } = this.props
    const { latitude, longitude } = contact
    const place = {
      address_components: contact.address_components,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      formatted_address: contact.address,
    }
    this.refInputAddress.value = place.formatted_address
    const contactWithUpdatedAddress = {
      ...contact,
      ...place
    }
    this.handelSetRefInputValue(place, contactWithUpdatedAddress)
  }

  handleResponse(response) {
    const { currentTab, customClassAutoComplete, handleContactsChange } = this.props
    if (response.data.error) {
      this.setState({
        isShowResultFromAPI: true,
        showConfirmMessage: false,
        contactMessage: response.data.error
      })
    } else {
      handleContactsChange(currentTab)
      FormLocationPopup.handleClose(customClassAutoComplete)
    }
  }

  handleCustomerPhoneChange(phone) {
    this.setState({ phoneNumber: phone })
  }

  async handleSubmit() {
    const {
      contactActions, currentTab, extraInfos
    } = this.props
    const { contact } = this.state
    const { phoneNumber, showAddressDetail } = this.state
    const validator = $('#form-location').validate({
      rules: {
        contact_email: {
          email: true
        }
      },
      messages: {
        contact_location_name: '',
        contact_name: '',
        contact_address: '',
        contact_phone: {
          required: '',
        }
      }
    })

    _.forEach(validator.successList, (e) => {
      $(e).parent().removeClass('error')
    })

    const newContact = {
      id: contact.id,
      location_name: (this.refInputLocationName.value).trim(),
      name: this.refInputContactName.value,
      address: this.refInputAddress.value,
      address_detail: this.refInputAddressDetail ? this.refInputAddressDetail.value : '',
      address_components: contact.address_components,
      latitude: contact.latitude,
      longitude: contact.longitude,
      phone: phoneNumber,
      title: this.refInputTitle.value,
      email: this.refInputEmail.value,
      company_name: this.refInputCompany.value
    }

    // validate address not found
    if (!newContact.latitude || !newContact.longitude) {
      this.setState({ addressError: I18n.t('webapp.new_booking.step_1.location_not_found') })
      return true
    }
    // validate lazy address
    const isValidLazyAddress = await locationUtils.validateLazyAddress(newContact, extraInfos, this.refInputAddress.closest('.Input'), this.errorMessage)
    if (!isValidLazyAddress) {
      return true
    }

    if ($('#form-location').valid() && showAddressDetail) {
      const contactAction = _.isUndefined(contact.id) ? contactActions.createContact : contactActions.updateContact
      contactAction(newContact, currentTab, (response) => {
        this.handleResponse(response)
      })
    } else {
      _.forEach(validator.errorList, (e) => {
        e.element.parentNode.classList.add('error')
      })
      if (!showAddressDetail) {
        const input = this.refInputAddress

        if (input.value !== '') {
          this.setState({ addressError: I18n.t('webapp.new_booking.step_1.location_not_found') })
        }
      }
    }
    return null
  }

  handleDelete() {
    const { contactActions, contact, currentTab } = this.props

    this.setState({
      showTrashIcon: false
    })

    contactActions.deleteContact(contact.id, currentTab, (response) => {
      this.handleResponse(response)
    })
  }

  showConfirmDeleteMessage() {
    this.setState({
      showConfirmMessage: true,
      isShowResultFromAPI: true,
      contactMessage: I18n.t('contacts.confirms.delete')
    })
  }

  renderResultFromAPI() {
    const { isShowResultFromAPI, contactMessage, showConfirmMessage } = this.state

    if (!isShowResultFromAPI) {
      return null
    }
    return (
      <div className="mar20 flex flex-start w100 relative Box-Sizing Border-Box pt10 pr20 pb10 pl20 Error-Red-bg Border-Default Border-Color-Red Radius-default">
        <div className="flex-index mr10">
          <span className="default-font">
            {contactMessage}
          </span>
        </div>
        {showConfirmMessage
          && (
            <button
              type="button"
              className="red Button Button-Default larger-font default-medium-font"
              onClick={this.handleDelete}
            >
              {I18n.t('webapp.action.delete')}
            </button>
          )
        }
        <span className="BoxCustom-Close" onClick={() => this.setState({ isShowResultFromAPI: false, showConfirmMessage: false })}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
      </div>
    )
  }

  render() {
    const {
      shouldShow,
      extraInfos,
      isPTL
    } = this.props
    const {
      showTrashIcon, showAddressDetail, showConfirmMessage, contact, addressError
    } = this.state
    const formTitle = _.isUndefined(contact.id) ? I18n.t('contacts.labels.add_contact') : I18n.t('contacts.labels.edit_contact')
    const countryCode = extraInfos.country_code?.toLowerCase()

    if (!shouldShow) {
      return null
    }

    return (
      <div id="form-location-popup" className="Modal Modal-Basic fixed visible">
        <div className="Box w600">
          <div className="Modal-Head Box-Head">
            {formTitle}
          </div>
          <div className="Modal-Content Box-Content">
            <form
              id="form-location"
              className="simple_form new_contact"
              ref={(refForm) => { this.refInputForm = refForm }}
            >
              <div className="Form no-overflow-x">
                {this.renderResultFromAPI()}
                <div className="flex flex-start w100">
                  <div className="default-color larger-font default-medium-font w100 mar10 flex-index">
                    {I18n.t('contacts.labels.required_info')}
                  </div>
                  {showTrashIcon && !showConfirmMessage
                    && (
                      <span className="cur-pointer" onClick={this.showConfirmDeleteMessage}>
                        <img src={TRASH_ICON} />
                      </span>
                    )
                  }
                </div>
                <div className="flex w100 flex-wrap mar10">
                  <div className="flex-index mr10">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_ICON} alt="Location" />
                        <label className="FormGroup-label capitalize label-required label-required-no-show ml10 mar0" htmlFor="contact_location_name">
                          {I18n.t('contacts.labels.location_name')}
                        </label>
                      </div>
                      <div className="Input">
                        <input
                          ref={(refInput) => { this.refInputLocationName = refInput }}
                          type="text"
                          id="contact_location_name"
                          name="contact_location_name"
                          defaultValue={contact.location_name}
                          required
                          maxLength="255"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml10">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_NAME_ICON} alt="Name" />
                        <label className="FormGroup-label capitalize label-required label-required-no-show ml10 mar0" htmlFor="contact_name">
                          {I18n.t('contacts.labels.name')}
                        </label>
                      </div>
                      <div className="Input">
                        <input
                          ref={(refInput) => { this.refInputContactName = refInput }}
                          type="text"
                          id="contact_name"
                          name="contact_name"
                          defaultValue={contact.name}
                          required
                          maxLength="255"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w100 flex-wrap mar10">
                  <div className="flex-index mr10">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_ADDRESS_ICON} alt="Option" />
                        <label className="FormGroup-label capitalize  ml10 mar0" htmlFor="contact_address_detail">
                          {I18n.t('contacts.labels.address')}
                        </label>
                      </div>
                      <AddressInput
                        ref={(ref) => {
                          this.refInputAddress = ref?.textarea
                        }}
                        id="contact_address"
                        name="contact_address"
                        defaultValue={contact.address}
                        placeholder=""
                        extraInfos={extraInfos}
                        onClickPredictLocation={place => this.handleSetPlace(place)}
                        onChange={e => this.handleChangeLocation(e)}
                        onKeyUp={this.handleOnKeyUp}
                        onClick={() => eventTracking.selectLocationField('my location', isPTL)}
                        clickShowMore={value => eventTracking.getGoogleLocationsResults('my location', value)}
                        className={addressError ? 'error' : ''}
                      />
                      <div
                        ref={(ref) => { this.errorMessage = ref }}
                        className="locations-error red reset m pt5"
                      >
                        {addressError && (
                          <span className="message-error icon-error red">
                            {addressError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml10">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_NUMBER_ICON} alt="Phone call" />
                        <label className="FormGroup-label capitalize label-required label-required-no-show ml10 mar0" htmlFor="contact_phone">
                          {I18n.t('contacts.labels.contact_phone')}
                        </label>
                      </div>
                      <InputPhoneNumber
                        isLocationInput
                        closestElement="my-location-number"
                        phoneField="phone"
                        phoneNumber={contact.phone}
                        countryCode={countryCode}
                        telInputProps={{ tabIndex: 1 }}
                        onPhoneChange={this.handleCustomerPhoneChange}
                        onPhoneFocus={this.handleCustomerPhoneChange}
                        onPhoneBlur={this.handleCustomerPhoneChange}
                      />
                      <div>
                        <label
                          id="contact_phone"
                          htmlFor="contact_phone"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showAddressDetail && (
                  <div className="flex w100 flex-wrap mar10">
                    <div className="flex-index">
                      <div className="FormGroup">
                        <div className="flex-start mar10">
                          <img className="ui top aligned avatar image" src={ADDRESS_DETAIL_ICON} alt="Location" />
                          <label className="FormGroup-label capitalize ml10 mar0 Gray-Light-Color" htmlFor="contact_location_name">
                            {I18n.t('contacts.labels.address_detail')}
                          </label>
                        </div>
                        <div className="Input">
                          <input
                            ref={(refInput) => { this.refInputAddressDetail = refInput }}
                            type="text"
                            id="contact_address_detail_name"
                            defaultValue={contact.address_detail}
                            maxLength="255"
                            placeholder={I18n.t('contacts.labels.address_detail_place_holder')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="FormGroup-Line w100 mt10 mar20" />
                <div className="flex w100 flex-wrap mar10">
                  <div className="flex-index mr5 w180">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_TITLE_ICON} alt="Title" />
                        <label className="FormGroup-label capitalize ml10 mar0 Gray-Light-Color" htmlFor="contact_title">
                          {I18n.t('contacts.labels.pic_title')}
                        </label>
                      </div>
                      <div className="Input">
                        <input
                          ref={(refInput) => { this.refInputTitle = refInput }}
                          type="text"
                          id="contact_title"
                          className="w100"
                          defaultValue={contact.title}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index mr5 ml5 w180">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_EMAIL_ICON} alt="Email" />
                        <label className="FormGroup-label capitalize ml10 mar0 Gray-Light-Color" htmlFor="contact_email">
                          {I18n.t('contacts.labels.email')}
                        </label>
                      </div>
                      <div className="Input">
                        <input
                          ref={(refInput) => { this.refInputEmail = refInput }}
                          type="text"
                          id="contact_email"
                          name="contact_email"
                          className="w100"
                          defaultValue={contact.email}
                        />
                      </div>
                      <div>
                        <label
                          id="contact_email"
                          htmlFor="contact_email"
                          className="error Red-text pt5 block white"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml5 w180">
                    <div className="FormGroup">
                      <div className="flex-start mar10">
                        <img className="ui top aligned avatar image" src={LOCATION_COMPANY_ICON} alt="Company" />
                        <label className="FormGroup-label capitalize ml10 mar0 Gray-Light-Color" htmlFor="contact_company">
                          {I18n.t('contacts.labels.company')}
                        </label>
                      </div>
                      <div className="Input ">
                        <input
                          ref={(refInput) => { this.refInputCompany = refInput }}
                          type="text"
                          id="contact_company"
                          className="w100"
                          defaultValue={contact.company_name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w100 flex-wrap">
                  <button
                    type="submit"
                    className="gray Button flex-index mr20 h40 larger-font default-medium-font"
                    onClick={() => FormLocationPopup.handleClose()}
                  >
                    {I18n.t('contacts.labels.back')}
                  </button>
                  <button
                    type="button"
                    className="green Button flex-index h40 larger-font default-medium-font"
                    onClick={this.handleSubmit}
                  >
                    {I18n.t('contacts.labels.save_contact')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

FormLocationPopup.propTypes = {
  contact: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  contactActions: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  shouldShow: PropTypes.bool.isRequired,
  currentTab: PropTypes.string,
  customClassAutoComplete: PropTypes.string,
  handleContactsChange: PropTypes.func.isRequired,
  isPTL: PropTypes.bool
}

FormLocationPopup.defaultProps = {
  currentTab: '',
  customClassAutoComplete: '',
  isPTL: false
}

export default FormLocationPopup
