import _ from 'lodash-es'
import { LONG_HAUL, FULL_DAY } from '../constants/bookingConstants'
import { groupDataBy } from './smart_planner/locations'

const filterExtraRequirements = (extraRequirements, timeType) => extraRequirements.filter((extraRequirement) => {
  if (timeType === LONG_HAUL) {
    return extraRequirement.selected === true && extraRequirement.is_flat_per_location === false
  }
  return extraRequirement.selected === true
})
export const extraRequirementsAttributesForCalculate = (
  extraRequirements, extraRequirementsNegativePosition, timeType
) => {
  const combinedExtraRequirements = [...extraRequirements, ...extraRequirementsNegativePosition]
  const selectedExtras = filterExtraRequirements(combinedExtraRequirements, timeType)
  return selectedExtras.map((extraRequirement) => {
    let pricingId
    let unitPrice = extraRequirement.unit_price
    let levelPrice = extraRequirement.level_price
    if (!_.isUndefined(extraRequirement.selectedPricing)) {
      unitPrice = extraRequirement.selectedPricing.fees
      levelPrice = extraRequirement.selectedPricing.level_price
      pricingId = extraRequirement.selectedPricing.id
    }
    return {
      extra_requirement_id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      position: extraRequirement.position,
      unit_price: unitPrice,
      level_price: levelPrice,
      extra_requirement_pricing_id: pricingId
    }
  })
}
export const extraRequirementsAttributesForCreate = (
  extraRequirements, extraRequirementsNegativePosition, timeType
) => {
  let selectedExtras = extraRequirements
  if (timeType === FULL_DAY) {
    selectedExtras = extraRequirements.concat(extraRequirementsNegativePosition)
  }
  selectedExtras = filterExtraRequirements(selectedExtras, timeType)
  return selectedExtras.map((extraRequirement) => {
    let unitPrice
    let levelPrice
    let pricingId
    if (!_.isUndefined(extraRequirement.selectedPricing)) {
      unitPrice = extraRequirement.selectedPricing.fees
      levelPrice = extraRequirement.selectedPricing.level_price
      pricingId = extraRequirement.selectedPricing.id
    }
    return {
      id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      is_flat: extraRequirement.is_flat,
      position: extraRequirement.position,
      unit_price: unitPrice,
      level_price: levelPrice,
      extra_requirement_pricing_id: pricingId
    }
  })
}

export const mergeLocationsBookingSLP = (locations = []) => {
  const truthyLocations = locations.filter(Boolean)
  const pickup = truthyLocations[0]
  const locationsMerge = [pickup]
  const locationsGroup = groupDataBy(truthyLocations.slice(1), ['lat', 'lng', 'recipient_name', 'recipient_phone'])
  Object.values(locationsGroup).forEach((_locations, index) => {
    const cloneLocations = _.cloneDeep(_locations)
    const locationNotes = _.uniq(cloneLocations.map(loc => loc.location_note).filter(Boolean))
    const locationPhones = _.uniq(cloneLocations.map(loc => loc.recipient_phone).filter(Boolean))
    const recipientName = _.uniq(cloneLocations.map(loc => loc.recipient_name).filter(Boolean))
    const locationIsPayer = _.uniq(cloneLocations.map(loc => loc.is_payer).filter(Boolean))
    if (locationNotes.length > 0) {
      cloneLocations[0].location_note = locationNotes.reverse()[0]
    }
    if (recipientName.length > 0) {
      cloneLocations[0].recipient_name = recipientName.reverse()[0]
    }
    if (locationPhones.length > 0) {
      cloneLocations[0].recipient_phone = locationPhones.reverse()[0]
    }
    if (locationIsPayer.length) {
      cloneLocations[0].is_payer = true
    }
    locationsMerge.push({
      ...cloneLocations[0],
      order: index + 1
    })
  })
  return locationsMerge
}