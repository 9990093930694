
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'

import { Utils } from 'utils/Utils'

import {
  NOW,
  SCHEDULE,
  FULL_DAY,
  LONG_HAUL,
} from 'constants/bookingConstants'
import { FIXED_PRICE_ICON_LONG_HAUL } from 'constants/imageConstants'
import {
  POPUP_ID_TRANSIT_TIME_EST,
} from 'constants/common/popupConstants'
import { TallyUtils } from 'utils/booking/TallyUtils'
import I18n from 'i18n/i18n'

export const getPopupIdForTSEst = bookingId => (`booking-${bookingId}-${POPUP_ID_TRANSIT_TIME_EST}`)
class TallySummary extends PureComponent {
  constructor() {
    super()

    this.state = {}

    this.handleETANotesClick = this.handleETANotesClick.bind(this)
  }

  /* eslint-disable-next-line */
  handleETANotesClick() {
    const {
      bookingId
    } = this.props

    const popupId = getPopupIdForTSEst(bookingId)
    // TODO: this is not good to use jquery, but I don't have much to rework it.
    $(`#${popupId}`).addClass('visible')
    Utils.checkHeightPopup($(`#${popupId} .Normal-Booking`))
  }

  renderEstimatedTimeArrival() {
    const {
      pickupTime,
      transitTime,
      worstTransitTime,
      timeType,
      etaNotesIconVisible = false,
      countryCode,
    } = this.props

    if (!transitTime) {
      return null
    }

    const firstDateTime = moment(pickupTime).add(transitTime, 's')
    const secondDateTime = worstTransitTime && moment(pickupTime).add(worstTransitTime, 's')
    const label = timeType === FULL_DAY
      ? I18n.t('webapp.tally.completion_time')
      : I18n.t('webapp.tally.reach_final_stop')

    return (
      <div className="tally-summary__estimated-time-arrival Radius-Bottom-Default flex flex-row pr15 pl15 pt5 pb5">
        <div className={`pr5 ${!etaNotesIconVisible ? 'grow-2' : ''}`}>{label}</div>

        {etaNotesIconVisible && (
          <span className="icon-wrapper cur-pointer" onClick={this.handleETANotesClick}>
            <i className="b material-icons Icon">help_outline</i>
          </span>
        )}

        <div className="tally-summary__estimated-time-arrival__time">
          {Utils.formatTallyDatetime([firstDateTime, secondDateTime], {
            longYearFormat: false,
            countryCode,
            noYearFormat: true,
          })}
        </div>
      </div>
    )
  }

  renderTimeType() {
    const {
      timeType
    } = this.props

    switch (timeType) {
      case NOW:
        return I18n.t('webapp.pricing_display.now')
      case SCHEDULE:
        return I18n.t('webapp.pricing_display.schedule')
      case FULL_DAY:
        return I18n.t('webapp.pricing_display.full_day')
      case LONG_HAUL:
        return (
          <div>
            <img src={FIXED_PRICE_ICON_LONG_HAUL} alt="fixed-price-icon" style={{ height: 12 }} />
            <span>
              {I18n.t('webapp.pricing_display.fixed_price_long_haul_route')}
            </span>
          </div>
        )
      default:
        break
    }

    return null
  }

  renderDistanceAndTransitTime() {
    const {
      transitTime,
      distance,
    } = this.props

    if (!_.isFinite(distance) && !_.isFinite(transitTime)) {
      return null
    }

    let distanceAndTransitTimeText = ''
    // it should not in one of ['', 0, null, undefined]
    if (distance) {
      distanceAndTransitTimeText += transitTime ? `${TallyUtils.roundUp(distance)} ${I18n.t('webapp.booking.km')} (${Utils.convertSecondsToTimeString(transitTime)})` : `${(TallyUtils.roundUp(distance))} ${I18n.t('webapp.booking.km')}`
    } else {
      distanceAndTransitTimeText += transitTime ? `${Utils.convertSecondsToTimeString(transitTime)}` : ''
    }

    if (!distanceAndTransitTimeText) {
      return null
    }

    return (
      <div className="tally-summary__distance flex flex-row pb10">
        <div>
          {distance ? I18n.t('webapp.pricing_display.distance') : I18n.t('webapp.tally.transit_time')}
        </div>
        <div>
          {distanceAndTransitTimeText}
        </div>
      </div>
    )
  }

  render() {
    const {
      serviceType,
      vehicleType,
      pickupTime,
      countryCode
    } = this.props

    return (
      <div className="tally-summary mt20 mr10 ml10 Radius-default White-bg flex flex-column">
        <div className="tally-summary__service-type-time-type Radius-Top-Default flex flex-row pt10 pb10 pr15 pl15">
          {serviceType && <img src={serviceType.active_icon_url} alt="service-type-icon" className="mr10" />}
          <div className="flex flex-column">
            <div className="tally-summary__service-type pb5">
              {vehicleType && vehicleType.name}
            </div>
            <div className="tally-summary__time-type">
              {this.renderTimeType()}
            </div>
          </div>
        </div>
        <div className="tally-summary__pickup-and-distance pr15 pl15">
          <div className="tally-summary__pickup flex flex-row pb10">
            <div>
              {I18n.t('webapp.pricing_display.pickup')}
            </div>
            <div>
              {Utils.formatDateTime(pickupTime, countryCode).dayOfMonth}
            </div>
          </div>
          {
            this.renderDistanceAndTransitTime()
          }
        </div>
        {
          this.renderEstimatedTimeArrival()
        }
      </div>
    )
  }
}

TallySummary.propTypes = {
  serviceType: PropTypes.shape({}),
  timeType: PropTypes.string,
  vehicleType: PropTypes.shape({}),
  pickupTime: PropTypes.number,
  distance: PropTypes.number,
  transitTime: PropTypes.number,
  worstTransitTime: PropTypes.number,
  bookingId: PropTypes.string,
  etaNotesIconVisible: PropTypes.bool,
  countryCode: PropTypes.string,
}

TallySummary.defaultProps = {
  serviceType: null,
  vehicleType: null,
  timeType: null,
  pickupTime: null,
  distance: null,
  transitTime: null,
  worstTransitTime: null,
  bookingId: 'single-booking',
  etaNotesIconVisible: false,
}

export default TallySummary
