import React, { FC, useEffect } from 'react'

import { createPortal } from 'react-dom'

import { IModal } from '../IModal'
import { Backdrop, ContentModal, ModalCloseIcon, ModalContainer, ModalWrapper } from './SModalNormal'

const target = document.querySelector('body') as Element

const ModalNormal: FC<IModal> = (props) => {
  const { isOpen, onClickClose, children, modalAction, placeTarget, targetParent, mt, topLevel } = props

  useEffect(() => {
    const keyEscDown = (e: any) => {
      if (e.code === 'Escape') {
        onClickClose?.()
      }
    }
    document.addEventListener('keydown', keyEscDown)
    return () => {
      document.removeEventListener('keydown', keyEscDown)
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    } else {
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }
  return createPortal(
    <ModalWrapper topLevel={topLevel} targetParent={targetParent} id="modal">
      <Backdrop
        isOpen={isOpen}
        onClick={(e) => {
          e.preventDefault()
          onClickClose?.()
        }}
      />
      <ModalContainer
        backgroundColor={props.backgroundColor}
        style={props.style}
        width={props.customWidth}
        height={props.customHeight}
        minWidth={props.customMinWidth}
        minHeight={props.customMinHeight}
        padding={props.customPadding}
        fullscreen={props.fullscreen}
        maxHeight={props.customMaxHeight}
        maxWidth={props.customMaxWidth}
        fixedWidth={props.customFixedWidth}
        fixedHeight={props.customFixedHeight}
        borderRadius={props.borderRadius}
        overflow={props.overflow}
        mt={mt}
      >
        {props.closeIcon && <ModalCloseIcon>{props.closeIcon}</ModalCloseIcon>}
        <ContentModal bgColor={props.backgroundColor}>
          {children}
          {!!modalAction && modalAction}
        </ContentModal>
      </ModalContainer>
    </ModalWrapper>,
    placeTarget ? placeTarget : target
  )
}

export default ModalNormal
