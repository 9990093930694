import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS_SAVE_AS_DRAFT } from 'constants/bookingConstants'
import _ from 'lodash'

const initialState: any = {}

const selectedBatchSlice = createSlice({
  name: 'selectedBatch',
  initialState,
  reducers: {
    updateSelectedBatch: (state, action: PayloadAction<any>) => action.payload.selectedBatch,
    updateSelectedBatchAttrs: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload.attrs }),
    updateBatchBookings: (state, action: PayloadAction<any>) => {
      const currentBookings = state.bookings
      let newBookings = _.cloneDeep(state.bookings)
      action.payload.bookings.forEach((newBooking: any) => {
        const index = _.findIndex(
          currentBookings,
          (booking: any) => booking.batch_tracking_token === newBooking.batch_tracking_token
        )
        if (index !== -1) {
          newBookings[index] = newBooking
        }
      })
      newBookings = _.sortBy(newBookings, [
        function (a) {
          return a.status === STATUS_SAVE_AS_DRAFT ? Number.POSITIVE_INFINITY : a.id
        },
      ])
      state.bookings = newBookings
    },
    updateBatchBooking: (state, action: PayloadAction<any>) => {
      state.bookings.forEach((booking: any, index: number) => {
        if (action.payload.booking.id === +booking.id) {
          state.bookings[index] = action.payload.booking
        }
      })
    },
    updateBatchBookingAttrs: (state, action: PayloadAction<any>) => {
      if (state.bookings) {
        const index = state.bookings.findIndex(
          (b: any) => b.batch_tracking_token === action.payload.attrs.batch_tracking_token
        )
        if (index !== -1) {
          state.bookings[index] = {
            ...state.bookings[index],
            ...action.payload.attrs,
          }
          const booking = state.bookings[index]

          if (_.isNumber(action.payload.attrs.location_has_signature)) {
            const locations = booking.locations || booking.locations_attributes
            _.forEach(locations, (loc, index) => {
              if (index <= action.payload.attrs.location_has_signature) {
                booking.locations[index] = {
                  ...booking.locations[index],
                  has_signature: true,
                }
              }
            })
          }
        }
      }
    },
    updateBatchChatBookingAttrs: (state, action: PayloadAction<any>) => {
      const bookings = state.batch_bookings
      const index = bookings.findIndex((b: any) => b.id === action.payload.attrs.id)
      if (index !== -1) {
        bookings[index] = {
          ...bookings[index],
          ...action.payload.attrs,
        }
      } else if (state.id === action.payload.attrs.batch_id) {
        // new booking added from queue
        bookings.push(action.payload.attrs)
      }
    },
    updateCurrentBatchChatBooking: (state, action: PayloadAction<any>) => {
      state.currentChatBookingID = action.payload.bookingID
    },
    updateActiveBooking: (state, action: PayloadAction<any>) => {
      const batch = state
      const bookingIndex = batch.bookings.findIndex((booking: any) => +booking.id === +action.payload.booking.id)
      if (bookingIndex !== -1 && batch.bookings[bookingIndex].active_tracking) {
        batch.bookings[bookingIndex].active_tracking = false
      } else {
        const currentActivedBookingIndex = batch.bookings.findIndex((booking: any) => booking.active_tracking)
        if (currentActivedBookingIndex !== -1) {
          batch.bookings[currentActivedBookingIndex].active_tracking = false
        }
        batch.bookings[bookingIndex].active_tracking = true
      }
    },
    updateAttrListBooking: (state, action: PayloadAction<any>) => {
      const bookings = state.bookings
      let selectedBookings = []
      selectedBookings = bookings.map((booking: any) => {
        if (_.includes(action.payload.bookingIds, _.toInteger(booking.id))) {
          return _.assign({}, booking, action.payload.attrs)
        }
        return booking
      })
      state.bookings = selectedBookings
    },
  },
  extraReducers: () => {},
})

export const selectedBatchActionsCreators = selectedBatchSlice.actions

export default selectedBatchSlice
