import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'
export const ModalWrapper = styled.div<{ bgColor?: string; targetParent?: boolean; top?: number; topLevel?: boolean }>`
  position: ${({ targetParent }) => (targetParent ? 'absolute' : 'fixed')};
  z-index: ${({ targetParent, topLevel }) => (targetParent || topLevel ? 100010 : 2)};
  top: ${({ top }) => top || 0};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor || `rgba(0, 0, 0, 0.8)`};
  overflow-y: auto;
`

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const ModalContainer = styled.div<{
  backgroundColor?: string
  width?: number
  minWidth?: number
  minHeight?: string
  padding?: string
  fullscreen?: boolean
  maxHeight?: string
  height?: number
  maxWidth?: string
  fixedWidth?: string
  fixedHeight?: string
  isShareLink?: boolean
  borderRadius?: string
  overflow?: string
  mt?: number
}>`
  position: absolute;
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px;` : '6px')};
  box-sizing: border-box;
  max-height: ${({ maxHeight }) => maxHeight || `80vh`};
  max-width: ${({ maxWidth }) => maxWidth || '80vw'};
  padding: ${({ padding }) => padding || `0`};
  min-width: ${({ minWidth }) => minWidth || 400}px;
  min-height: ${({ minHeight }) => minHeight};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${({ theme }) => theme.zIndex[2] + 2};
  margin-top: ${({ mt }) => mt || 0}px;
  ${({ width, height, fullscreen }) => {
    if (fullscreen) {
      return `width: 100%; height: 100%; padding: 0;`
    } else if (width && height) {
      return `width: ${width}vw; height: ${height}vh;`
    }
  }}

  ${({ overflow }) =>
    overflow &&
    `
    overflow: ${overflow};
  `}

  ${({ fixedWidth }) =>
    fixedWidth &&
    `
    width: ${fixedWidth};
  `}

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
    height: ${fixedHeight};
  `}

  @media screen and (max-width: 800px) {
    width: calc(100% - 24px);
    min-width: unset;
    margin: auto;
    padding: ${({ padding }) => padding || `12px`};
  }
`
export const ContentModal = styled.div<ISBase>`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.colors.white[1])};
  color: ${({ color, theme }) => color || theme.colors.dark[1]};
`
export const ModalCloseIcon = styled.div<ISBase>`
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
`
