import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { POPUP_PICKUP_TIME } from 'constants/common/popupConstants'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import { ID_NEW_SERVICE_TYPE, ID_NEW_VEHICLE_TYPE } from 'constants/newBookingConstants'
import CommonUtils from 'utils/common'
import {
  currentVehicleType as currentVehicleTypeUtils,
} from 'utils/new_booking/common'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as vehicleTypeActionCreators from 'store/actions/new_booking/vehicleTypeActionCreators'
import * as serviceTypeActionCreators from 'store/actions/new_booking/serviceTypeActionCreators'
import useOutsideAlerter from 'hooks/commonHooks'
import I18n from 'i18n/i18n'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'

const PickupTimePopup = (props) => {
  const { setIsShowPickupTimePopup } = props
  const wrapperRef = useRef()
  const currentVehicleType = useSelector(state => currentVehicleTypeUtils(state))
  const timeType = useSelector(state => state.timeType)
  const dispatch = useDispatch()

  const closePopup = (cancel) => {
    if (!cancel) {
      const newVehicleTypeId = window.localStorage.getItem(ID_NEW_VEHICLE_TYPE)
      const newServiceTypeID = window.localStorage.getItem(ID_NEW_SERVICE_TYPE)
      if (newServiceTypeID) {
        dispatch(serviceTypeActionCreators.changeServiceType(+newServiceTypeID))
      } else if (newVehicleTypeId) {
        dispatch(vehicleTypeActionCreators.changeVehicleType(+newVehicleTypeId))
      }
      dispatch(quickChoiceActionsCreator.changeQuickChoice(0))
      dispatch(timeTypeActionCreators.actionChangeTimeType(''))
      dispatch(timeTypeUIActionsCreator.changeTimeTypeUI(''))
      dispatch(pickupTimeActionsCreator.changePickupTime(''))
    }
    window.localStorage.removeItem(ID_NEW_VEHICLE_TYPE)
    window.localStorage.removeItem(ID_NEW_SERVICE_TYPE)
    setIsShowPickupTimePopup(false)
  }

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      closePopup(true)
    }
  }
  useOutsideAlerter(wrapperRef, () => setIsShowPickupTimePopup(false), escFunction)

  const icon = CommonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  if (_.isEmpty(currentVehicleType) || _.isEmpty(timeType)) {
    return null
  }
  return (
    <div id={POPUP_PICKUP_TIME} className="vertical-scroll dlvr-modal absoute">
      <div className="Normal-Booking" ref={wrapperRef}>
        <div className="Box Modified-Popup">
          <div className="Box-Icon">
            <img src={icon} alt="icon" className="icon-modified-style" />
          </div>
          <div className="Box-Content">
            <div className="Normal">
              <h5 className="Normal-Booking-Title">
                {I18n.t('closures.label.vehicle')}
              </h5>
              <div>
                <p className="center pre-line">
                  {I18n.t('webapp.new_booking.change_vehicle_popup_content')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Normal-Booking-Actions Custom">
          <a className="Gray no-hover cur-pointer" onClick={() => closePopup(true)}>
            {I18n.t('webapp.new_booking.back')}
          </a>
          <a className="White no-hover cur-pointer" onClick={() => closePopup(false)}>
            {I18n.t('webapp.action.ok')}
          </a>
        </div>
      </div>
    </div>
  )
}
PickupTimePopup.propTypes = {
  setIsShowPickupTimePopup: PropTypes.bool.isRequired,
}
export default PickupTimePopup
