import { ISBase } from "components/common/Styled/SCommon/ICommon"
import { styled } from "components/common/Styled/STheme"

const ContentWrapper = styled.div<ISBase>`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  align-items: center;
  button:first-child {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
`
export { ContentWrapper }