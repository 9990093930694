import apiClient from 'services/axiosApp'

const EventNotificationAPI = {
  getCurrentEvent: async (areaId, currentCustomer) => {
    const queryParam = {
      params: {
        company_id: currentCustomer ? currentCustomer.current_company_id : 0
      },
      headers: {
        'Area-Id': areaId,
        'Device-Type': 'Web'
      }
    }
    const res = await apiClient.get('/api/v3/event_notifications', queryParam,)
    return res?.data
  }
}

export default EventNotificationAPI
