import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
import { currentServiceType, currentVehicleType, enabledEditExtraRequirement } from 'utils/new_booking/common'
import { havePopup } from 'utils/common/popupable'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import { UtilsHTML } from 'utils/html'
// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'
// COMPONENTS
// CONSTANTS
import {
  POPUP_ID_FULL_DAY_S2,
  POPUP_TYPE_EXTRA_POPUP_EYE,
  POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE,
  POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY,
  POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2,
} from 'constants/common/popupConstants'
import { FULL_DAY } from 'constants/bookingConstants'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import TitleSub from './TitleSub'
import extraServiceUtils from 'utils/booking/extraServiceUtils'
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  currentVehicleType: currentVehicleType(state),
  currentServiceType: currentServiceType(state),
  extraRequirements: extraServiceUtils.extraRequirementsStep2(state.extraServices.extraRequirementsNegativePosition),
  fullDayPricing: state.extraServices.fullDayPricing,
  locations: state.locations,
  timeType: state.timeType,
  pickupTime: state.pickupTime,
  extraInfos: state.extraInfos
})

function mapDispatchToProps(dispatch) {
  return {
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch),
  }
}

class ExtraRequirementForFullDay extends React.Component {
  constructor(props) {
    super(props)
  }

  onClickFullDayEyeHandler() {
    $(`#${POPUP_ID_FULL_DAY_S2}`).addClass("visible")
    this.checkHeightPopup($(`#${POPUP_ID_FULL_DAY_S2} .Normal-Booking`))
  }

  checkHeightPopup(element) {
    if(element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft');
    } else {
      element.removeClass('No-Seft');
    }
  }

  onChangeCheckBox(id, event) {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    const selected = event.target.checked
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.onChangeCheckBoxAfterCheckBookingDetails.bind(this, id, selected) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeCheckBoxAfterCheckBookingDetails(id, selected)
    }
  }

  onChangeCheckBoxAfterCheckBookingDetails(id, selected) {
    const { extraServiceActions, extraRequirements, extraInfos } = this.props
    const selectedAmount = selected ? 1 : 0
    const extraRequirement = extraRequirements.find(item => item.id === id)
    extraServiceActions.updateExtraRequirementNegativePosition(id, { selected, selected_amount: selectedAmount })
    if (extraRequirement && selectedAmount !== 0) {
      window.Moengage.track_event('Extra Services Selected', {
        'Extra Service ID': extraRequirement.id,
        'Extra Service Name': extraRequirement.name,
        Amount: extraRequirement.unit_price * selectedAmount,
        Quantity: selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  onChangeSelectBox(id, event) {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    const selectedAmount = event.target.value
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.onChangeSelectBoxAfterCheckBookingDetails.bind(this, id, selectedAmount) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeSelectBoxAfterCheckBookingDetails(id, selectedAmount)
    }
  }

  onChangeSelectBoxAfterCheckBookingDetails(id, selectedAmount) {
    const { extraServiceActions, extraRequirements, extraInfos } = this.props
    const extraRequirement = extraRequirements.find(item => item.id === id)
    extraServiceActions.updateExtraRequirementNegativePosition(
      id, { selected: (selectedAmount > 0), selected_amount: _.toInteger(selectedAmount) }
    )
    if (extraRequirement && +selectedAmount !== 0) {
      window.Moengage.track_event('Extra Services Selected', {
        'Extra Service ID': extraRequirement.id,
        'Extra Service Name': extraRequirement.name,
        Amount: extraRequirement.unit_price * selectedAmount,
        Quantity: +selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  onChangeFullDaySelectBox(event) {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    const selectedAmount = event.target.value
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.onChangeFullDaySelectBoxAfterCheckBookingDetails.bind(this, selectedAmount) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeFullDaySelectBoxAfterCheckBookingDetails(selectedAmount)
    }
  }

  onChangeFullDaySelectBoxAfterCheckBookingDetails(selectedAmount) {
    const { extraServiceActions } = this.props
    extraServiceActions.updateFullDayPricing(
      { selected: (selectedAmount > 0), selected_amount: _.toInteger(selectedAmount) }
    )
  }

  onClickCebDisableCheckbox(fieldName) {
    const { editModeTextActions } = this.props
    editModeTextActions.updateEditModeText(fieldName)
  }

  extraRequirementInfo(extraRequirement) {
    let totalPrice = 0
    const { extraInfos, fullDayPricing, timeType } = this.props
    if (extraRequirement.selected_amount === undefined || extraRequirement.selected_amount === 0) {
      return Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, '—')
    }
    if (timeType === FULL_DAY) {
      if (extraRequirement.is_flat) {
        totalPrice = extraRequirement.unit_price
      } else {
        totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount * fullDayPricing.selected_amount
      }
    } else {
      totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount
    }
    return Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, '—')
  }

  fullDayPricingInfo() {
    const { extraInfos, fullDayPricing } = this.props
    const result = fullDayPricing.unit_price * fullDayPricing.selected_amount
    return Utils.formatFee(result, extraInfos.country_language, extraInfos.currency, '—')
  }

  renderCheckbox(extraRequirement, popupType) {
    const isEnabledEditExtraRequirement = enabledEditExtraRequirement(extraRequirement)
    const disableClass = isEnabledEditExtraRequirement ? '' : 'Disable'
    const fieldName = `booking[booking_extra_requirements_with_deleted_attributes][${extraRequirement.id}]`
    return (
      <div className="block-item" key={extraRequirement.id}>
        <label>
          <div className="TitleSubtitle w-100">
            <TitleSub
              extraRequirement={extraRequirement}
              popupType={popupType}
            />
            <div className="TitleSubtitle-subtitle">{this.extraRequirementInfo(extraRequirement)}</div>
          </div>
        </label>
        <div className="block-item-action">
          <div className={`Overlay-Checkbox With-Icon Green-Checkbox ${disableClass}`}>
            {
              !isEnabledEditExtraRequirement
              && (
                <div
                  className="Ceb-disable-field Disable-checkbox-overlay"
                  onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
                />
              )
            }
            <input
              type="checkbox"
              name={`${fieldName}[selected_amount]`}
              id={`extra_requirement_${extraRequirement.id}`}
              value="1"
              checked={extraRequirement.selected}
              onChange={e => this.onChangeCheckBox(extraRequirement.id, e)}
            />
            <label>
              <i className="b material-icons Icon">check</i>
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderSelectbox(extraRequirement, popupType) {
    const isEnabledEditExtraRequirement = enabledEditExtraRequirement(extraRequirement)
    const disableClass = isEnabledEditExtraRequirement ? '' : 'Disable'
    const fieldName = `booking[booking_extra_requirements_with_deleted_attributes][${extraRequirement.id}]`
    return (
      <div className="block-item" key={extraRequirement.id}>
        <label>
          <div className="TitleSubtitle w-100">
            <TitleSub
              extraRequirement={extraRequirement}
              popupType={popupType}
            />
            <div className="TitleSubtitle-subtitle">{this.extraRequirementInfo(extraRequirement)}</div>
          </div>
        </label>
        <div className="block-item-action">
          {
            !isEnabledEditExtraRequirement
            && (
              <div
                className="Ceb-disable-field Disable-checkbox-overlay"
                onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
              />
            )
          }
          <select
            name={`${fieldName}[selected_amount]`}
            id={`extra_requirement_${extraRequirement.id}`}
            value={extraRequirement.selected_amount}
            disabled={disableClass}
            onChange={e => this.onChangeSelectBox(extraRequirement.id, e)}
          >
            <option>0</option>
            {_.range(extraRequirement.amount).map(value =>
              <option value={value + 1} key={value + 1}>{value + 1}</option>
            )}
          </select>
        </div>
      </div>
    )
  }

  renderFullDaySelectbox() {
    const { currentVehicleType: _currentVehicleType } = this.props
    return(
      <div className="block-item">
        <label htmlFor="booking_full_day_selected_amount">
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title">
              {I18n.t('webapp.new_booking.step_2.days')}
              {havePopup(_currentVehicleType, POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2) && (
                <span className="step-2-eye" onClick={() => this.onClickFullDayEyeHandler()}>
                  <i className="s material-icons Icon">
                    visibility
                  </i>
                </span>
              )}
            </div>
            <div className="TitleSubtitle-subtitle">{this.fullDayPricingInfo()}</div>
          </div>
        </label>
        <div className="block-item-action">
          <select
            name="booking[full_day_selected_amount]"
            id="booking_full_day_selected_amount"
            value={this.props.fullDayPricing.selected_amount}
            onChange={e => this.onChangeFullDaySelectBox(e)}
          >
            {_.range(this.props.fullDayPricing.amount).map(value =>
              <option value={value + 1} key={value + 1}>{value + 1}</option>
            )}
          </select>
        </div>
      </div>
    )
  }

  render() {
    const {
      timeType,
      pickupTime,
      extraInfos,
      extraRequirements
    } = this.props
    if (timeType !== FULL_DAY) {
      return null
    }
    const pickupTimeMoment = moment(pickupTime)
    const dateTimeFormated = Utils.formatDateTime(pickupTimeMoment, extraInfos.country_code)
    const itemText = `${dateTimeFormated.time}, ${dateTimeFormated.date}`
    let popupType = POPUP_TYPE_EXTRA_POPUP_EYE
    if (currentServiceType?.is_package) {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE
    } else if (timeType === FULL_DAY) {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY
    }
    return (
      <div className="ExtraServices block">
        {UtilsHTML.renderSectionTitle(I18n.t('webapp.new_booking.step_2.full_day_booking'))}
        <div className="block-item">
          <label>
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title">
                {I18n.t('webapp.new_booking.step_2.arrival')}
              </div>
            </div>
          </label>
          <span>
            {itemText}
          </span>
        </div>
        {this.renderFullDaySelectbox()}
        {extraRequirements.map(extraRequirement => (
          extraRequirement.amount === 1
            ? this.renderCheckbox(extraRequirement, popupType) : this.renderSelectbox(extraRequirement, popupType)
        ))}
      </div>
    )
  }
}

ExtraRequirementForFullDay.defaultProps = {
  assignedDriver: undefined,
  fullDayPricing: {},
}

ExtraRequirementForFullDay.propTypes = {
  assignedDriver: PropTypes.shape({}),
  fullDayPricing: PropTypes.shape({}),
  currentVehicleType: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({
    country_code: PropTypes.string
  }).isRequired,
  extraServiceActions: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  editModeTextActions: PropTypes.shape({}).isRequired,
  extraRequirements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pickupTime: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraRequirementForFullDay)
