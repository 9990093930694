import _ from 'lodash'
import $ from 'jquery'
// this file is for get common filtered data of new_booking redux state. Ex: in mapStateToProps or actonCreators
import {
  NOW,
  POLYGON_SETTINGS,
  SCHEDULE,
  FULL_DAY,
  LONG_HAUL,
  ONE_MIN_TO_MS,
  ONE_HOUR_TO_MIN,
  LTL_DISABLED_BTN_CLASS,
} from 'constants/bookingConstants'
import {
  NOW_ENABLED_SETTING,
} from 'constants/newBookingConstants'
import { EXTRA_BY_OPTION } from 'constants/extraServiceConstants'

import { Utils } from 'utils/Utils'
import {
  getParamFromURL,
  isBookAgain,
  isEditBooking,
  isMarketingPage,
  isNewBooking
} from '../booking/common'

export const currentServiceType = state => _.find(
  state.serviceTypes, { id: state.selectedServiceTypeID }
) || state.serviceTypes[0]

export const currentCurrency = state => currentServiceType(state).currency

export const vehicleTypes = (state) => {
  const serviceType = currentServiceType(state)
  if (serviceType) {
    return serviceType.vehicle_types
  }
  return []
}

export const currentVehicleType = state => _.find(
  vehicleTypes(state), { id: state.selectedVehicleTypeID }
) || vehicleTypes(state)[0]

// FOR POPUPS
export const findPopup = (popupableObject, popupType) => {
  if (_.isEmpty(popupableObject)) {
    return undefined
  }
  return _.find(popupableObject.popups, { popup_type: popupType })
}

export const havePopup = (popupableObject, popupType) => {
  if (_.isEmpty(popupableObject)) {
    return false
  }
  return !_.isEmpty(findPopup(popupableObject, popupType))
}

export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData()
  // Object.keys can NOT parse null or undefined
  // but typeof null is object => exception
  if (obj === null) { return fd }
  let formKey
  Object.keys(obj).forEach((property) => {
    const checkPrototype = Object.prototype.hasOwnProperty.call(obj, property)
    if (checkPrototype) {
      if (namespace) {
        formKey = `${namespace}[${property}]`
      } else {
        formKey = property
      }
      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  })
  return fd
}

export const drawDropOffZonePolygon = (googleMap, zoneCoords) => {
  const dropOffZonePolygon = new window.google.maps.Polygon({
    paths: zoneCoords,
    strokeColor: POLYGON_SETTINGS.strokeColor,
    strokeOpacity: POLYGON_SETTINGS.strokeOpacity,
    strokeWeight: POLYGON_SETTINGS.strokeWeight,
    fillColor: POLYGON_SETTINGS.fillColor,
    fillOpacity: POLYGON_SETTINGS.fillOpacity
  })
  dropOffZonePolygon.setMap(googleMap)

  return dropOffZonePolygon
}

export const removeDropOffZonePolygon = (googleMap) => {
  if (googleMap && googleMap.polygonZones) {
    googleMap.polygonZones.map(polygon => polygon.setMap(null))
  }
}

export const showPolygons = (googleMap, zoomLevel) => {
  const bool = zoomLevel < 15
  if (googleMap && googleMap.polygonZones) {
    googleMap.polygonZones.map((polygon) => {
      if (zoomLevel > 10 && zoomLevel < 15) {
        polygon.setOptions({ fillOpacity: POLYGON_SETTINGS.fillOpacityAdjusment })
      } else if (zoomLevel <= 10) {
        polygon.setOptions({ fillOpacity: POLYGON_SETTINGS.fillOpacity })
      }
      return polygon.setVisible(bool)
    })
  }
}

export const checkedVehicleSupportLH = (serviceTypes, vehicleTypeID) => {
  let checkedSupportLH = false
  for (let i = 0; i < _.size(serviceTypes); i += 1) {
    const currentVehicle = _.find(serviceTypes[i].vehicle_types, b => b.id === vehicleTypeID)
    if (!_.isUndefined(currentVehicle)) {
      checkedSupportLH = currentVehicle.settings.long_haul_enabled
      break
    }
  }
  return checkedSupportLH
}

export const currentZoneAreas = (zones, groupID) => {
  if (!_.isUndefined(groupID)) {
    return _.find(zones, { id: groupID })
  }
  return zones[0]
}

export const currentGroupPickup = (groupPickupZones, areaID) => {
  let currentPickupZone = {}
  for (let i = 0; i < _.size(groupPickupZones); i += 1) {
    const findPickupZone = _.find(groupPickupZones[i].zone_areas, zone => zone.id === areaID)
    if (!_.isUndefined(findPickupZone)) {
      currentPickupZone = groupPickupZones[i]
      break
    }
  }
  return currentPickupZone
}

export const getListZone = (groupZones) => {
  let zones = []
  for (let i = 0; i < _.size(groupZones); i += 1) {
    zones = zones.concat(groupZones[i].zone_areas)
  }
  return zones
}

export const validateLongHaulPickup = (latLng, pickupZones) => {
  const result = { isValid: false, zoneID: undefined }
  pickupZones.forEach((zone) => {
    if (!result.isValid) {
      const zoneCoords = window.google.maps.geometry.encoding.decodePath(zone.encoded_supported_zone)
      const polygon = new window.google.maps.Polygon({ paths: zoneCoords })
      if (window.google.maps.geometry.poly.containsLocation(latLng, polygon)) {
        result.isValid = true
        result.zoneID = zone.id
        result.zoneGroupID = zone.zone_group_id
      }
    }
  })
  return result
}

export const validateLongHaulDropOff = (latLng, dropOffZones, serviceType) => {
  const result = { isValid: false, zoneID: undefined }
  dropOffZones.forEach((zone) => {
    if (!result.isValid) {
      const zoneCoords = window.google.maps.geometry.encoding.decodePath(zone.encoded_supported_zone)
      const polygon = new window.google.maps.Polygon({ paths: zoneCoords })
      if (window.google.maps.geometry.poly.containsLocation(latLng, polygon)) {
        result.isValid = true
        result.zoneID = zone.id
        result.zoneGroupID = zone.zone_group_id
      }
    }
  })
  if (!result.isValid) {
    const zoneCoords = []
    _.forEach(serviceType.extended_zone, (coords) => {
      zoneCoords.push(new window.google.maps.LatLng(coords[0], coords[1]))
    })
    const polygon = new window.google.maps.Polygon({ paths: zoneCoords })
    if (window.google.maps.geometry.poly.containsLocation(latLng, polygon)) {
      result.isValid = true
      result.zoneID = 0
    }
  }
  return result
}

export const sortByLocaleName = (arr, sortedField) => {
  arr.sort((a, b) => {
    if (a.position === b.position) {
      return a[sortedField].localeCompare(b[sortedField])
    }
    return a.position - b.position
  })
  return arr
}

export const verifyTimeType = (vehicleType, timeType, quickChoiceID) => {
  const settings = vehicleType?.settings
  const quickChoices = vehicleType?.quick_choices
  if (_.isEmpty(timeType)) {
    return true
  }
  if (quickChoiceID > 0) {
    return quickChoices?.length > 0
  }
  let enableKey
  if (timeType === NOW) {
    enableKey = NOW_ENABLED_SETTING
  } else {
    enableKey = `${timeType}_enabled`
  }
  return settings?.[enableKey]
}

export const verifyPickupTime = (settings, pickupTime, timeType) => {
  let minutes = 0
  const minimumLongHaul = settings.minimum_pickup_time_longhaul || settings.minimum_pickup_time_fpr || 0
  switch (timeType) {
    case SCHEDULE:
      minutes = settings.minimum_pickup_time_schedule
      break
    case FULL_DAY:
      minutes = settings.minimum_pickup_time_fullday
      break
    case LONG_HAUL:
      // Minimum Pickup Time Longhaul Unit is hour
      minutes = minimumLongHaul * ONE_HOUR_TO_MIN
      break
    default:
  }

  const pickupTimeTemp = new Date(pickupTime)
  const now = new Date()
  const comparingTime = pickupTimeTemp - now
  return (comparingTime > (minutes * ONE_MIN_TO_MS) || _.isEmpty(timeType) || timeType === NOW)
}

export const getVehicleType = (vehicleTypesTemp, id) => _.find(vehicleTypesTemp, { id: _.parseInt(id) })
export const getServiceType = (serviceTypesTemp, id) => _.find(serviceTypesTemp, { id: _.parseInt(id) })
export const getQuickChoice = (quickChoicesTemp, id) => _.find(quickChoicesTemp, { id: _.parseInt(id) })
export const miniMumMinutes = (vehicleType, timeType, defaultAreaMinutes = 0) => {
  if (!_.isEmpty(vehicleType)) {
    const settings = vehicleType.settings
    const minimumLongHaul = settings.minimum_pickup_time_longhaul || settings.minimum_pickup_time_fpr || 0
    switch (timeType) {
      case SCHEDULE:
        return settings.minimum_pickup_time_schedule
      case FULL_DAY:
        return settings.minimum_pickup_time_fullday
      case LONG_HAUL:
        return minimumLongHaul * 60
      default:
        return defaultAreaMinutes
    }
  }
  return defaultAreaMinutes
}

export const minuMumBufferMinutes = (vehicleType = {}, timeType, defaultAreaMinutes = 0, forSingle = true) => {
  const settings = !_.isEmpty(vehicleType) ? vehicleType.settings : {}
  const bufferTime = forSingle ? settings.buffer_time_for_pick_time_single
    : settings.buffer_time_for_pick_time_multiple_batch
  const minMinutes = miniMumMinutes(vehicleType, timeType, defaultAreaMinutes)
  if (!_.isEmpty(vehicleType.settings) && !_.isUndefined(bufferTime)) {
    return minMinutes + _.toInteger(bufferTime)
  }
  return minMinutes
}

export const shouldShowDriverMaker = (map, extraInfos) => map.zoom > extraInfos.zoom_level_hide_far_vehicle_icons

export const isCustomerEditBooking = () => window.location.pathname?.split('/').includes('edit') && getParamFromURL('is_ceb')

export const isCustomerEditPickupTime = () => window.location.pathname?.split('/').includes('edit') && getParamFromURL('is_edit_time')

export const isCancelToEdit = () => isEditBooking() && !getParamFromURL('is_ceb') && !getParamFromURL('is_edit_time')

export const enabledEditExtraRequirement = (extraRequirement) => {
  if (!_.size(extraRequirement)) {
    return true
  }

  const isGoodsInsurance = extraRequirement.pricing_method === EXTRA_BY_OPTION && extraRequirement.name.includes('Insurance')
  const canEditExtraRequirement = isGoodsInsurance ? true : extraRequirement.can_toggle
  const extraRequirementSetting = isCustomerEditBooking()
    ? extraRequirement.allow_to_edit_when_customer_edit_booking : canEditExtraRequirement
  return extraRequirementSetting
}

export const verifyEmptyValueStep01 = (timeType, invalidIndexes) => {
  const locationElems = $('.Locations.Locations-Customs').find('.block-item .Input')
  const LocationsCustoms = document.querySelector('.BookingWizard .Content .block.Locations-Customs')
  invalidIndexes.forEach((index) => {
    $(locationElems[index]).addClass('error')
  })

  const config = {
    element: $('.BookingWizard .Content'),
    positionScroll: 0,
    animation: 400,
    elementFocus: null
  }
  if (_.isEmpty(timeType)) {
    $('.PickupTime').addClass('error')
    config.positionScroll = $('.BookingWizard .Content .PickupTime').offset().top
    Utils.scrollTop(config)
    return true
  }
  if (invalidIndexes.length > 0 && !_.isEmpty(LocationsCustoms)) {
    if (LocationsCustoms.offset) config.positionScroll = LocationsCustoms.offset().top
    Utils.scrollTop(config)
    return true
  }
  return false
}

export const convertCustomReimbursementToParam = (customReimbursements, timeType, enableParkingTollsFeature) => {
  if (timeType !== LONG_HAUL && !enableParkingTollsFeature) return null

  const newCustomReimbursements = _.filter(
    customReimbursements,
    reimbursement => reimbursement.selected_amount >= 1
  ).map((reimbursement) => {
    const userInputAmount = parseInt(reimbursement.user_input_amount, 10)
    const selectAmount = parseInt(reimbursement.selected_amount, 10)
    const result = {
      unit: selectAmount,
      vehicle_type_reimbursement_id: reimbursement.id,
      ...(reimbursement.allow_user_to_enter_amount && userInputAmount && userInputAmount > 0
        ? { amount: userInputAmount } : { amount: reimbursement.amount }
      )
    }
    return result
  })
  return newCustomReimbursements
}

export const convertBookingCustomReimbursementToParam = (customReimbursements) => {
  const newCustomReimbursements = _.filter(
    customReimbursements,
    reimbursement => reimbursement.unit >= 1
  ).map(reimbursement => ({
    unit: reimbursement.unit,
    vehicle_type_reimbursement_id: reimbursement.vehicle_type_reimbursement_id,
    amount: reimbursement.amount
  }))
  return newCustomReimbursements
}

export const getLatLongLocation = locations => locations.map(
  location => ({ lat: location.lat || location.latitude, lng: location.lng || location.longitude })
)

export const getDraftURL = id => `/bookings/multiple?draft_booking_id=${id}&book_again=true`

export const handleSetClassDisablePTL = (className, isLTL) => {
  if (isLTL) return `${className} ${LTL_DISABLED_BTN_CLASS}`
  return className
}

export const checkToShowLoadingForNewBooking= () => {
  const id = document.getElementById('progress-loading-container-id')
  const isShowLoadingBar = (isNewBooking || isCustomerEditBooking()
    || isCustomerEditPickupTime() || isMarketingPage || isBookAgain()) && id
  if (isShowLoadingBar) id.classList.add('progress-loading-container-visible')
}
