/* eslint-disable import/prefer-default-export */
import _, { isEmpty } from 'lodash'
import { changeVehicleType } from './vehicleTypeActionCreators'
import BookingAPI from 'api/bookings'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { selectedServiceTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedServiceTypeID.reducer'
import { serviceTypesCommonActionsCreator } from 'store/toolkit/serviceTypes/serviceTypesCommon.reducer'

import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import * as bookingUtils from 'utils/booking/common'

export const changeServiceType = (serviceTypeID, vehicleTypeID) => (dispatch, getState) => {
  Promise.resolve(
    dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(serviceTypeID))
  ).then(() => {
    const state = getState()
    const serviceType = _.find(state.serviceTypes, { id: state.selectedServiceTypeID })
    if (serviceType) {
      const vehicleTypes = serviceType.vehicle_types
      if (_.isEmpty(vehicleTypes)) {
        dispatch(changeVehicleType(null))
      } else if (vehicleTypeID) {
        dispatch(changeVehicleType(vehicleTypeID))
      } else {
        dispatch(changeVehicleType(vehicleTypes[0].id))
      }
    } else {
      dispatch(changeVehicleType(null))
    }
    dispatch(dataChangesActionsCreator.updateDataChange(true))
  })
}

export const updateServiceTypes = serviceTypes => (dispatch) => {
  dispatch(serviceTypesCommonActionsCreator.setServiceTypes(serviceTypes))
}

export const resetDefaultServiceType = dataServiceTypes => (dispatch) => {
  const serviceDefault = dataServiceTypes.find(service => service.is_default) || dataServiceTypes[0]
  dispatch(changeServiceType(serviceDefault.id))
}

export const resetTimeType = () => (dispatch) => {
  dispatch(quickChoiceActionsCreator.changeQuickChoice(0))
  dispatch(timeTypeActionCreators.actionChangeTimeType(''))
  dispatch(timeTypeUIActionsCreator.changeTimeTypeUI(''))
  dispatch(pickupTimeActionsCreator.changePickupTime(''))
}

const getServiceTypesInfo = (areaId, companyId) => async (dispatch, getState) => {
  const urlParams = new URLSearchParams(window.location.search)
  const isFtl = urlParams.get('ftl')
  const serviceFtlTypeIdParam = urlParams.get('st_id')
  const vehicleFtlTypeIdParam = urlParams.get('vt_id')
  const dataServiceTypes = await BookingAPI.getServiceTypes(areaId, companyId)

  const newDataServiceTypes = dataServiceTypes?.data?.filter((service) => !isEmpty(service.vehicle_types))
  if(!isEmpty(newDataServiceTypes)) {
    dispatch(updateServiceTypes(newDataServiceTypes))
    
    const { selectedServiceTypeID, selectedVehicleTypeID } = getState() 
    if (!selectedServiceTypeID && !selectedVehicleTypeID) {
      if(isFtl && bookingUtils.checkServiceAndVehicleTypesId(serviceFtlTypeIdParam, vehicleFtlTypeIdParam, newDataServiceTypes)) {
        dispatch(changeServiceType(Number(serviceFtlTypeIdParam), Number(vehicleFtlTypeIdParam)))
        bookingUtils.removeListParamOnURL(['st_id', 'vt_id']);
      } else {
        dispatch(resetDefaultServiceType(newDataServiceTypes))
      }
    } else {
      // switch account on step 3
      const isExistCurrentService = newDataServiceTypes.find(service => service.id === selectedServiceTypeID)
      if(isExistCurrentService && isExistCurrentService?.vehicle_types.length) {
        const isExistCurrentVehicle = isExistCurrentService.vehicle_types.find(vehicle => vehicle.id === selectedVehicleTypeID)
        if(!isExistCurrentVehicle) {
          const newVehicleTypeId = isExistCurrentService.vehicle_types[0].id
          dispatch(changeVehicleType(newVehicleTypeId))
          dispatch(resetTimeType())
        }
      } else {
        dispatch(resetDefaultServiceType(newDataServiceTypes))
        dispatch(resetTimeType())
      }
    }
  }
  return newDataServiceTypes
}
export { getServiceTypesInfo }
