import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CommonUtils from 'utils/common'

const POLICY_LINK = {
  ID: "https://www.deliveree.com/id/privacy-policy/",
  ID_EN: "https://www.deliveree.com/id/en/privacy-policy/",
  TH: "https://www.deliveree.com/th/privacy-policy/",
  TH_EN: "https://www.deliveree.com/th/en/privacy-policy/",
  PH: "https://www.transportify.com.ph/privacy-policy/",
  VN: "https://www.deliveree.com/id/en/privacy-policy/",
}

const Step2Policy = ({ onUpdateDeleteAccount, deletionAccount }) => {
  const { t, i18n } = useTranslation()
  const countryCode = useSelector(state => state.extraInfos?.country_code)

  const dataBlock1 = [
    t('webapp.deletion_account.you_will_not_be_able_to'),
    t('webapp.deletion_account.to_rejoin_deleveree', {
      app_name: CommonUtils.appName()
    }),
    t('webapp.deletion_account.your_personal_data'),
  ]


  const dataBlock2 = [
    t('webapp.deletion_account.deleveee_will_continue', {
      app_name: CommonUtils.appName()
    }),
    t('webapp.deletion_account.if_you_have_any_questions'),
  ]

  const getPolicyLink = () => {
    let formatCountry = countryCode?.toUpperCase()
    const language = i18n.language
    if((formatCountry === "ID" || formatCountry === "TH") && language === 'en'){
      formatCountry += "_EN"
    }
    return POLICY_LINK[formatCountry]
  }

  const getLinkWebapp = () => {
    const country = countryCode?.toUpperCase()
    if(country === "PH") {
      return "https://www.webapp.transportify.com.ph"
    }
    return "https://www.webapp.deliveree.com"
  }

  return (
    <div className="step-2-carefully">
      <h2 className="title">{t('webapp.deletion_account.please_read_carefully')}</h2>
      <div className="policy-line">
        <div>•</div>
        <div>{t('webapp.deletion_account.any_unused_credit')}</div>
      </div>
      <div className="policy-line">
        <div>•</div>
        <div>
          {t('webapp.deletion_account.before_account_deletion')}
          <div className='mt5'>
            <a className='green underline' href={getLinkWebapp()} target='_blank' rel="noreferrer">{getLinkWebapp()}</a>
          </div>
        </div>
      </div>
      {dataBlock1.map(text => (
        <div className="policy-line" key={text}>
          <div>•</div>
          <div>{text}</div>
        </div>
      ))}
      <div className="policy-line">
        <div/>
        <a className="button-policy" href={getPolicyLink()} target="_blank" rel="noreferrer">{t('devise_template.privacy_policy')}</a>
      </div>
      {dataBlock2.map((text) => (
        <div className="policy-line" key={text}>
          <div>•</div>
          <div>{text}</div>
        </div>
      ))}
      <div className="policy-line">
        <div/>
        <span className="button-policy" onClick={() => window.fcWidget.open()}>{t('webapp.action.contact_cs')}</span>
      </div>
      <div className="policy-accept">
        <div className="Overlay-Checkbox Green-Checkbox With-Icon">
          <input
            type="checkbox"
            name="settings_value"
            id="settings_value"
            value={deletionAccount.acceptDelete}
            checked={deletionAccount.acceptDelete}
            onChange={e => onUpdateDeleteAccount('acceptDelete', e.target.checked)}
          />
          <label>
            <i className="b material-icons Icon">
              check
            </i>
          </label>
        </div>
        <div>{t('webapp.deletion_account.yes_i_want_to_permanently')}</div>
      </div>
    </div>
  )
}

Step2Policy.propTypes = {
  onUpdateDeleteAccount: PropTypes.func.isRequired,
  deletionAccount: PropTypes.object.isRequired
}

export default Step2Policy
