import CustomerAPI from 'api/customers'
import AuthenticationAPI from 'api/external/authentication'
import SubAccountAPI from 'api/subAccountTag'
import StorageKeys from 'constants/storage-keys'
import { IUserInfo } from 'interface/IAccountManage'
import { isEmpty } from 'lodash-es'
import { AppDispatch } from 'store/store'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { subAccountTagListActionsCreator } from 'store/toolkit/subAccountTagList/subAccountTagList.reducer'
import { setAccessToken } from 'utils/crossStorage'
export interface IParamSignIn {
  countryCode: string
  login: string
  password: string
}

export interface IParamSignUp {
  country_code?: string
  email: string
  phone: string
  first_name: string
  last_name: string
  business_name: string
  referred_by: string
  password?: string
  access_token: string
  provider?: string
  invitation_token?: string
  identity?: string
}

const accountAction = {
  signIn:
    (params: IParamSignIn) =>
    async (dispatch: AppDispatch): Promise<IUserInfo> => {
      const areaIdLocal = localStorage.getItem(StorageKeys.AREA_ID)
      const pramsGetCustomer = { area_id: areaIdLocal, include: ['company', 'employs'] }
      const { data } = await CustomerAPI.signIn(params)
      const token = data?.access_token
      if (token) {
        setAccessToken(token)
        await CustomerAPI.updateAuthenticationToken()
        const infoUser = await CustomerAPI.getCustomer(token, pramsGetCustomer)
        if (infoUser.statusApi === 401) {
          setAccessToken('')
        } else {
          if (infoUser.last_login_employ_id) {
            return (await accountAction.switchAccount(infoUser.last_login_employ_id)(dispatch)) as any
          } else {
            dispatch(currentCustomerActionsCreator.updateCustomer(infoUser))
            return infoUser
          }
        }
      }
      return data
    },
  signUp:
    (params: IParamSignUp) =>
    async (dispatch: AppDispatch): Promise<IUserInfo> => {
      const { data: dataSignUp } = await CustomerAPI.signUp(params)
      if (dataSignUp?.access_token) {
        const { data } = await AuthenticationAPI.convertToken(
          dataSignUp?.access_token,
          dataSignUp?.object?.last_login_employ_id
        )
        setAccessToken(data.access_token)
        await CustomerAPI.updateAuthenticationToken()
        dispatch(
          currentCustomerActionsCreator.updateCustomer({
            ...dataSignUp?.object,
            authentication_token: data?.access_token,
          })
        )
        return { ...dataSignUp?.object, accessToken: data?.access_token, message: data?.message }
      }
      return dataSignUp
    },

  switchAccount:
    (currentAccountId: number) =>
    async (dispatch: AppDispatch): Promise<IUserInfo | undefined> => {
      const { data } = await CustomerAPI.switchAccount(currentAccountId)
      await CustomerAPI.switchAccountController(currentAccountId)
      if (data?.access_token) setAccessToken(data?.access_token)
      if (!isEmpty(data?.object)) {
        const newCurrentCustomer = {
          ...data?.object,
          last_login_employ_id: data?.object.last_login_employ_id ? data?.object.last_login_employ_id : null,
          ...(!data?.object.current_company_id && { current_company_id: null }),
        }
        dispatch(currentCustomerActionsCreator.updateCustomer(newCurrentCustomer))
        return { ...newCurrentCustomer, accessToken: data?.access_token }
      }
    },
  getSubAccountTags:
    (companyId?: number | null, params = {}) =>
    async (dispatch: AppDispatch) => {
      let newParams = { ...params }
      if (companyId) {
        newParams = { ...newParams, company_id: companyId }
      }
      const { data } = await SubAccountAPI.getSubAccountTags(newParams)
      if (data) {
        dispatch(subAccountTagListActionsCreator.subAccountTagList(data.data))
      }
    },
}
export default accountAction
