import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = ''

const currentPopupIDSlice = createSlice({
  name: 'currentPopupID',
  initialState,
  reducers: {
    updateCurrentPopupID: (state, action: PayloadAction<any>) => {
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentPopupIDActionsCreator = currentPopupIDSlice.actions

export default currentPopupIDSlice
