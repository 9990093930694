import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
import DynamicPopupHandler from '../DynamicPopupHandler'
// CONSTANTS
import {
  ITEM_TYPE_SERVICE_TYPE_PE_TITLE,
  ITEM_TYPE_SERVICE_TYPE_PE_CONTENT,
  ITEM_TYPE_SERVICE_TYPE_PE_SIZE_TEXT,
  ITEM_TYPE_SERVICE_TYPE_PE_WEIGHT_TEXT,
  POPUP_VEHICLE_TYPE_VEHICLE_DIMENSION_POPUP_EYE,
  ITEM_TYPE_SERVICE_TYPE_PE_VT_VD_TITLE,
  ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_TITLE,
  ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_SIZE_TEXT,
  ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_WEIGHT_TEXT,
} from 'constants/common/popupConstants'
// ASSETS
// API
import PopupAPI from 'api/popups'
//UTILS
import I18n from 'i18n/i18n'

import './DynamicPopupHandler.scss'
class DimensionPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      popupData: {},
      renderNode: {},
      correctHeight: true,
      hasPopup: false
    }

    this.getPopupApi = this.getPopupApi.bind(this)
    this.renderDynamicContent = this.renderDynamicContent.bind(this)
    this.renderEyeIcon = this.renderEyeIcon.bind(this)
  }

  componentWillMount() {
    this.getPopupApi()
  }

  componentDidMount() {
    const renderNodeElement = document.getElementById('DimensionPopupEye')
    this.setState({
      renderNode: renderNodeElement
    })
  }

  getPopupApi() {
    const { currentCustomer, objectID, objectType } = this.props
    let params
    let getPopupApiFunc
    switch (objectType) {
      case 'VehicleType': {
        params = {
          vehicle_type_id: objectID,
          popup_type: POPUP_VEHICLE_TYPE_VEHICLE_DIMENSION_POPUP_EYE
        }
        getPopupApiFunc = PopupAPI.getVehicleTypePopup
        break
      }
      case 'ServiceType':
      default: {
        params = { service_type_id: objectID }
        getPopupApiFunc = PopupAPI.getServiceTypePopup
        break
      }
    }
    return new Promise((resolve) => {
      getPopupApiFunc(currentCustomer.authentication_token, params, (result) => {
        const { data: popupdnd, status } = result || {}
        if (status === 200 && !_.isEmpty(popupdnd?.object)) {
          this.setState({
            popupData: popupdnd.object,
            hasPopup: true
          })
        }
        resolve()
      })
    })
  }

  generateObjectFromApi() {
    const { popupData } = this.state
    const { objectType, serviceType } = this.props
    let obj = {}
    if (!_.isEmpty(popupData)) {
      switch (objectType) {
        case 'VehicleType': {
          const selectItemTypes = [
            ITEM_TYPE_SERVICE_TYPE_PE_VT_VD_TITLE,
            ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_TITLE
          ]
          const titleItems = _.filter(
            popupData.popup_items,
            item => _.includes(selectItemTypes, item.item_type)
          )
          const popupTitle = _.isEmpty(titleItems[0]) ? '' : titleItems[0].formated_content
          obj = {
            icon: decodeURIComponent(popupData.icon),
            title: popupTitle,
            subTitle: '',
          }
          break
        }
        case 'ServiceType':
        default: {
          let serviceTypeIcon = serviceType.active_icon_url ? serviceType.active_icon_url : ''
          if (popupData.icon) {
            serviceTypeIcon = popupData.icon
          }
          obj = {
            icon: decodeURIComponent(serviceTypeIcon),
            title: '',
            subTitle: '',
          }
          break
        }
      }
    }
    return obj
  }

  static renderSizeWeight(items) {
    let size = _.find(items, { item_type: ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_SIZE_TEXT })
    let weight = _.find(items, { item_type: ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_WEIGHT_TEXT })
    if (!(size && weight)) {
      size = _.find(items, { item_type: ITEM_TYPE_SERVICE_TYPE_PE_SIZE_TEXT })
      weight = _.find(items, { item_type: ITEM_TYPE_SERVICE_TYPE_PE_WEIGHT_TEXT })
    }
    return (!_.isEmpty(size) || !_.isEmpty(weight)) && (
      <div className="Normal-Booking-Image">
        <div>
          {!_.isEmpty(size)
            && <p className="Dimension-Content" dangerouslySetInnerHTML={{ __html: size.formated_content }} />
          }
          {!_.isEmpty(weight)
            && <b dangerouslySetInnerHTML={{ __html: weight.formated_content }} />
          }
        </div>
      </div>
    )
  }

  static renderPopupItem(serviceType, item, index) {
    const isPackage = !_.isEmpty(serviceType) && serviceType.is_package
    switch (item.item_type) {
      case ITEM_TYPE_SERVICE_TYPE_PE_TITLE:
      case ITEM_TYPE_SERVICE_TYPE_PE_VT_VD_TITLE: {
        return (
          <div key={index}>
            {!isPackage && index > 0
              && <div className="Normal-Booking-Devide" />
            }
            <h5 className="Normal-Booking-Title mar5 fullday" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
          </div>
        )
      }
      case ITEM_TYPE_SERVICE_TYPE_PE_CONTENT: {
        return (
          <p className="center fullday" key={index} dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      default:
        return <span key={index} />
    }
  }

  static renderPopupItems(serviceType, items) {
    return items.map((item, index) => DimensionPopup.renderPopupItem(serviceType, item, index))
  }

  renderDynamicContent() {
    const { popupData } = this.state
    const { objectType, serviceType, isRenderExtraPopup } = this.props
    switch (objectType) {
      case 'VehicleType': {
        return DimensionPopup.renderSizeWeight(popupData.popup_items)
      }
      case 'ServiceType':
      default: {
        const selectItemTypes = [
          ITEM_TYPE_SERVICE_TYPE_PE_TITLE,
          ITEM_TYPE_SERVICE_TYPE_PE_CONTENT,
          ITEM_TYPE_SERVICE_TYPE_PE_VT_VD_TITLE
        ]
        const normalItems = _.filter(
          popupData.popup_items,
          item => _.includes(selectItemTypes, item.item_type)
        )
        const isPackage = !_.isEmpty(serviceType) && serviceType.is_package
        return (
          <div>
            {!isPackage && !isRenderExtraPopup
              && (
                <h5 className="Normal-Booking-Title mar5 Custom">
                  {serviceType.name}
                </h5>
              )
            }
            {DimensionPopup.renderPopupItems(serviceType, normalItems)}
            {DimensionPopup.renderSizeWeight(popupData.popup_items)}
          </div>
        )
      }
    }
  }

  renderDynamicItem() {
    const { popupData } = this.state
    const selectItemTypes = ITEM_TYPE_SERVICE_TYPE_PE_CONTENT
    const normalItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    let url
    let text
    _.forEach(normalItems, (item) => {
      const {
        item_type: itemType,
        link_url: linkUrl,
        link_text: linkText,
      } = item
      if (itemType === selectItemTypes && linkUrl && linkText) {
        url = linkUrl
        text = linkText
      }
    })
    return {
      url,
      text,
    }
  }

  renderEyeIcon() {
    const { iconEye } = this.props

    if (_.size(iconEye)) return <img src={iconEye} alt="info icon" />
    return (
      <i className="b material-icons Icon">
        visibility
      </i>
    )
  }

  render() {
    const { renderNode, correctHeight, hasPopup } = this.state
    if (!hasPopup) { return <span /> }

    const { isRenderExtraPopup } = this.props
    let renderDynamicButton
    if (isRenderExtraPopup) {
      const { url, text } = this.renderDynamicItem()
      renderDynamicButton = () => (
        <div className="flex mt10">
          <button
            type="button"
            className="Button gray Button-Default flex-index mr5 default-medium-font"
            onClick={() => ReactDOM.unmountComponentAtNode(renderNode)}
          >
            {I18n.t('webapp.booking.back')}
          </button>
          <button
            type="button"
            className="Button white Green-text Button-Default flex-index ml5 default-medium-font"
            onClick={() => { window.open(url, '_blank') }}
          >
            {text}
          </button>
        </div>
      )
    }

    return (
      <DynamicPopupHandler
        {...this.props}
        specialClass="Vehicle-Service-Type-Popup-Eye"
        renderChild={this.renderEyeIcon}
        renderInfo={this.generateObjectFromApi()}
        renderDynamicContent={this.renderDynamicContent}
        renderDynamicButton={renderDynamicButton}
        renderNode={renderNode}
        correctHeight={correctHeight}
      />
    )
  }
}

DimensionPopup.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  objectID: PropTypes.number.isRequired,
  iconEye: PropTypes.string,
  objectType: PropTypes.string,
  serviceType: PropTypes.shape({}),
  isRenderExtraPopup: PropTypes.bool,
}

DimensionPopup.defaultProps = {
  iconEye: '',
  objectType: 'ServiceType',
  serviceType: {},
  isRenderExtraPopup: false,
}

export default DimensionPopup
