import SButton from 'components/common/Styled/SButton'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import STypography from 'components/common/Styled/STypography'
import React from 'react'
import { OldSocialLogin as SocialLogin } from 'react-social-login'

const SocialButton = ({ name, icon, ...prop }) => {
  return (
    <SocialLogin {...prop}>
      <SButton h="36px" {...prop} type="button">
        <SFlex icenter>
          <img src={icon} alt="fb-icon" />
          <STypography fs={13}>{name}</STypography>
        </SFlex>
      </SButton>
    </SocialLogin>
  )
}

export default SocialButton
