import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// ACTIONS
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { bindActionCreators } from 'redux'

function mapDispatchToProps(dispatch) {
  return {
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
  }
}

class ModalDecision extends React.Component {
  closePopup() {
    const { currentPopupIDActions, customClosePopup, usingCustomCloseFunc } = this.props

    if (usingCustomCloseFunc) {
      customClosePopup()
    } else {
      currentPopupIDActions.updateCurrentPopupID('')
    }
  }

  handleConfirm() {
    const { handleConfirmAction } = this.props
    handleConfirmAction(() => this.closePopup())
  }

  render() {
    const {
      iconSrc,
      title,
      content,
      backText,
      confirmText,
      className
    } = this.props

    return (
      <div className={`popup-wrapper ${className}`}>
        <div className="overlay" onClick={() => this.closePopup()} />
        <div className="popup-inner">
          <div className="content-wrapper">
            <div className="icon">
              <img src={iconSrc} alt="icon-calendar" />
            </div>
            <p className="title">
              {title}
            </p>
            <p className="content">
              {content}
            </p>
          </div>
          <div className="btn-wrapper">
            <button type="button" className="button mr10" onClick={() => this.closePopup()}>
              {backText}
            </button>
            <button type="button" className="button confirm" onClick={() => this.handleConfirm()}>
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ModalDecision.propTypes = {
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  handleConfirmAction: PropTypes.func,
  iconSrc: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  backText: PropTypes.string,
  confirmText: PropTypes.string,
  className: PropTypes.string,
  usingCustomCloseFunc: PropTypes.bool,
  customClosePopup: PropTypes.func,
}

ModalDecision.defaultProps = {
  handleConfirmAction: () => undefined,
  iconSrc: '',
  title: '',
  content: '',
  backText: '',
  confirmText: '',
  className: '',
  customClosePopup: () => undefined,
  usingCustomCloseFunc: false
}

export default connect(null, mapDispatchToProps)(ModalDecision)
