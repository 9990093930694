import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  routes: [],
  mapLoaded: false,
  mapInstance: null
}

const mapSmartPlannerSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setMapLoaded: (state, action: PayloadAction<any>) => {
      state.mapLoaded = action.payload
    },
    setMapToStore: (state, action: PayloadAction<any>) => {
      state.mapInstance = action.payload
    },
  },
  extraReducers: () => {},
})

export const mapsActionsCreator = mapSmartPlannerSlice.actions

export default mapSmartPlannerSlice

