import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const ContentWrapper = styled.div<ISBase>`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .text-signin {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green[1]};
  }
`

const IconModal = styled.div<ISBase>`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`
export { ContentWrapper, IconModal }
