import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
// UTILS
// ACTIONS
// COMPONENTS
import DynamicPopupHandler from '../../DynamicPopupHandler'
// CONSTANTS
import { POPUP_ID_ASSIGN_DRIVERS } from 'constants/common/popupConstants'
// ASSETS
// API

class UnavailableAssignDriverPopupEye extends React.Component {
  constructor(props) {
    super(props)
    this.renderDynamicPopup = this.renderDynamicPopup.bind(this)
    this.state = {
      popupData: {},
      renderNode: {},
      correctHeight: true,
    }
    this.renderIcon = this.renderIcon.bind(this)
  }

  componentWillMount() {
    const {
      dataUnavailablePopupEye
    } = this.props
    const popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup_eye.schedule_tool
    this.setState({
      popupConst: popupConstant,
      popupData: dataUnavailablePopupEye
    })
  }

  componentDidMount() {
    const renderNodeElement = document.getElementById('CommonPopupDriver')
    this.setState({
      renderNode: renderNodeElement
    })
  }

  generateObjectFromApi() {
    const { popupData } = this.state
    const popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup_eye.schedule_tool
    const selectItemTypes = [
      popupConstant.item_title,
      popupConstant.item_content,
    ]
    const titleItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    const popupTitle = _.isEmpty(titleItems[0]) ? '' : titleItems[0].formated_content
    return {
      icon: decodeURIComponent(popupData.icon),
      title: popupTitle,
      subTitle: '',
    }
  }

  renderIcon() {
    const { icon } = this.props

    if (icon) {
      return (
        <img src={icon} alt="icon-popup" />
      )
    }

    return (
      <i className="material-icons Icon large-icon cur-pointer">
        visibility
      </i>
    )
  }

  static renderPopupItem(item, index) {
    const popupConst = POPUP_ID_ASSIGN_DRIVERS.popup_eye.schedule_tool
    if (item.item_type === popupConst.item_content) {
      return (
        <div key={index}>
          <p className="center fullday" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        </div>
      )
    }
    return <span key={index} />
  }

  static renderPopupItems(items) {
    return items.map((item, index) => UnavailableAssignDriverPopupEye.renderPopupItem(item, index))
  }

  renderDynamicPopup() {
    const { popupData, popupConst } = this.state
    const selectItemTypes = [
      popupConst.item_title,
      popupConst.item_content,
    ]
    const normalItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    return (
      <div className="mt20">
        {UnavailableAssignDriverPopupEye.renderPopupItems(normalItems)}
      </div>
    )
  }

  render() {
    const { renderNode, correctHeight, popupData } = this.state
    if (_.isEmpty(popupData)) {
      return null
    }
    return (
      <DynamicPopupHandler
        {...this.props}
        renderNode={renderNode}
        specialClass="Popover-Item-Driver-Item-Icon-Eye ml10 p-static"
        renderChild={this.renderIcon}
        renderInfo={this.generateObjectFromApi()}
        renderDynamicContent={this.renderDynamicPopup}
        options={{
          specialClass: 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
        }}
        correctHeight={correctHeight}
      />
    )
  }
}

UnavailableAssignDriverPopupEye.propTypes = {
  dataUnavailablePopupEye: PropTypes.shape({}).isRequired,
  icon: PropTypes.string,
}

UnavailableAssignDriverPopupEye.defaultProps = {
  icon: '',
}

export default connect()(UnavailableAssignDriverPopupEye)
