import React from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import FormItem, { IFormItem } from './FormItem'
export interface IFormInput {
  methods: UseFormReturn<any, object>
  onSubmit: (data: any) => void
  error?: string
  arrayFormItem?: IFormItem[]
  children?: React.ReactNode
  buttons?: React.ReactNode
  classNameButton?: string
  colGap?: number
}
const FormContainer = (props: IFormInput) => {
  const { methods, onSubmit, error = '', arrayFormItem, children, buttons, classNameButton, colGap } = props

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormItem colGap={colGap} arrayFormItem={arrayFormItem || []} />
        {children}
        <div className={`${classNameButton ? classNameButton : ''}`}>{buttons}</div>
      </form>
    </FormProvider>
  )
}

export default FormContainer
