import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  locationMarker: undefined,
  driverMarker: undefined,
  driverInfoBox: undefined,
  prevDriverLocation: null,
}
const trackingMarkerSlice = createSlice({
  name: 'trackingMarker',
  initialState,
  reducers: {
    trackingUpdateLocationMarker: (state, action: PayloadAction<any>) => {
      if (state.locationMarker) {
        if(action.payload.isGoogleMap) {
          state.locationMarker.setMap(null)
        } else {
          state.locationMarker.remove()
        }
      }
      state.locationMarker = action.payload.marker
    },
    trackingUpdateDriverMarker: (state, action: PayloadAction<any>) => {
      if (state.driverMarker) {
        if(action.payload.isGoogleMap) {
          state.driverMarker.setMap(null)
        } else {
          state.driverMarker.remove()
        }
      }
      state.driverMarker = action.payload.marker
      state.prevDriverLocation = action.payload.prevDriverLocation
    },
    trackingInitDriverInfobox: (state, action: PayloadAction<any>) => {
      state.driverInfoBox = action.payload
    },
    trackingPrevDriverLocation: (state, action: PayloadAction<any>) => {
      state.prevDriverLocation = action.payload
    },
  },
  extraReducers: () => {},
})
export const trackingMarkerActionsCreator = trackingMarkerSlice.actions

export default trackingMarkerSlice
