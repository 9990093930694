import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export type TExcelMatrix = string | null
const initialState: TExcelMatrix[][] = []

const excelMatrixSlice = createSlice({
  name: 'excelMatrix',
  initialState,
  reducers: {
    updateExcelMatrix: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const excelMatrixActionsCreator = excelMatrixSlice.actions

export default excelMatrixSlice
