import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UTILS
import { havePopup } from 'utils/common/popupable'
import I18n from 'i18n/i18n'
// COMPONENTS
import PopupIcon from '../popup/PopupIcon'
// CONSTANTS
import { POPUP_VEHICLE_TYPE_ROUND_TRIP } from 'constants/common/popupConstants'


class CheckBoxTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const {
      vehicleType,
      roundTripDiscount,
      isShowIcon,
      popupStyle,
      toggleRoundTrip,
    } = this.props
    return (
      <div className="optimize-route">
        <div className="Overlay-Checkbox Green-Checkbox">
          <input
            id="round-trip-discount"
            type="checkbox"
            checked={roundTripDiscount}
            onChange={e => toggleRoundTrip(e.target.checked)}
          />
          <label htmlFor="optimize-route">
            <i className="b material-icons Icon">
              check
            </i>
            {I18n.t('webapp.booking.return_trip_discount')}
          </label>
        </div>
        {isShowIcon && havePopup(vehicleType, POPUP_VEHICLE_TYPE_ROUND_TRIP)
          && (
            <div className="ml10 flex flex-center">
              <PopupIcon
                {...this.props}
                popupObject={vehicleType}
                popupType={POPUP_VEHICLE_TYPE_ROUND_TRIP}
                renderNode={document.getElementById('BookingPopupEye')}
                icon="visibility"
                popupStyle={popupStyle}
              />
            </div>)}
      </div>
    )
  }
}

CheckBoxTemplate.propTypes = {
  vehicleType: PropTypes.shape({}),
  isShowIcon: PropTypes.bool,
  roundTripDiscount: PropTypes.bool.isRequired,
  popupStyle: PropTypes.shape({}),
  toggleRoundTrip: PropTypes.func,
}

CheckBoxTemplate.defaultProps = {
  vehicleType: {},
  isShowIcon: false,
  popupStyle: {},
  toggleRoundTrip: () => undefined,
}

export default connect(null, null)(CheckBoxTemplate)
