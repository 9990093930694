import React, { FC } from 'react'
import { IIcon } from './IIcon'
import { Icon, IconWrap } from './SIcon'

const SIcon: FC<IIcon> = (props) => {
  return (
    <IconWrap
      onClick={(e) => {
        props.onClick && props.onClick(e)
      }}
      {...props}
    >
      <Icon className='material-icons ' {...props}>{props.value}</Icon>
    </IconWrap>
  )
}

export default React.memo(SIcon)
