import { useDispatch } from 'react-redux'
import { isEmpty, get, size } from 'lodash-es'
import PropTypes from 'prop-types'
import CommonUtils from 'utils/common'
import { useTranslation } from 'react-i18next'
import BatchMenu from '../common/BatchMenu'
import DevinaMenu from '../common/DevinaMenu'
import Menu from './Menu'
import { ICON_FLAG_EN, IMAGE_PLACE_HOLDER, V2_LOGO_WHITE, V2_LOGO_WHITE_TRANSPORTIFY } from 'constants/imageConstants'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { ALL_FLAG } from 'constants/headerConstant'
import { TRANSPORTIFY_WEBAPP_HOST, DELIVEREE_WEBAPP_HOST } from 'constants/appConstants'
import { getCompanyByEmployId, getCurrentEmploy } from 'utils/booking/common'
import { Link, matchPath, useLocation } from 'react-router-dom'
import paths from 'routers/paths'
import CustomerAPI from 'api/customers'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'

const handelChangeLanguage = async (language, customerId) => {
  if (customerId) {
    await CustomerAPI.updateCustomer({ language })
  }
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set('lang', language)
  window.location.search = urlParams
}

const onClickArea = (countryCode, areaId) => {
  const country = countryCode.toLowerCase()
  let href = `${DELIVEREE_WEBAPP_HOST}?area_id=${areaId}`
  if ((country === 'ph' || country === 'philippines') && TRANSPORTIFY_WEBAPP_HOST) {
    href = `${TRANSPORTIFY_WEBAPP_HOST}?area_id=${areaId}`
  }
  window.location.href = href
}

const handleReturnImage = (image) => {
  if (image && image !== 'missing.png') {
    return image
  }
  return IMAGE_PLACE_HOLDER
}

const setFlag = (country) => ALL_FLAG[country]

const RenderSwitchLanguages = ({ listLanguage, customerId }) => {
  const activeLanguage = CommonUtils.getLanguage() || 'en'
  return (
    <div className="Menu-Nav-Item Menu-Nav-Item-Flag">
      {!isEmpty(listLanguage) &&
        listLanguage.map(
          (item) =>
            item !== 'en' &&
            item !== 'vi' && (
              <a
                key={item}
                className={activeLanguage === item ? 'active' : ''}
                rel="nofollow"
                data-method="put"
                onClick={() => handelChangeLanguage(item, customerId)}
              >
                <img src={setFlag(item)} alt={item} />
              </a>
            )
        )}
      <a
        className={activeLanguage === 'en' ? 'active' : ''}
        rel="nofollow"
        data-method="put"
        onClick={() => handelChangeLanguage('en', customerId)}
      >
        <img src={ICON_FLAG_EN} alt="En" />
      </a>
    </div>
  )
}

const RenderAreas = ({ dropdownAreas, area }) => (
  <div className="Menu-Nav-Item">
    <div className="dark-green Dropdown">
      <div className="Dropdown-Close" />
      <div className="Dropdown-Head">
        <div className="Dropdown-Head-Item">
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title White">{area?.name}</div>
          </div>
        </div>
        <div className="Dropdown-Head-Caret">
          <i className="xb material-icons Icon">arrow_drop_down</i>
        </div>
      </div>
      <div className="top Dropdown-Head-Arrow" />
      <div className="right top Dropdown-Menu">
        {dropdownAreas.map((item) => {
          if (size(item.areas) > 1) {
            return (
              <div className="Dropdown-Menu-Item" key={item.country}>
                {item.country}
                <ul className="Dropdown-Menu-Item-Sub">
                  {item.areas.map((it) => (
                    <li key={it.id}>
                      <a
                        className={it.id === area.id ? 'selected' : ''}
                        rel="nofollow"
                        data-method="put"
                        onClick={() => onClickArea(item.country_code || item.country, it.id)}
                      >
                        {it.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
          }
          return (
            <a
              key={item.country}
              className={`Dropdown-Menu-Item Font-Size ${item.areas[0].id === area.id ? 'selected' : ''}`}
              rel="nofollow"
              onClick={() => onClickArea(item.country_code || item.country, item.areas[0].id)}
            >
              {item.country}
            </a>
          )
        })}
      </div>
    </div>
  </div>
)

const showProfilePanel = (dispatch) => {
  CommonUtils.moengageTrackEvent('Select View Profile')
  dispatch(currentCustomerActionsCreator.toggleProfilePanel(true))
}

const RenderAvatar = ({
  currentEmploy, currentCustomer, isBusinessSession, customerSignedIn, dispatch
}) => {
  if (customerSignedIn) {
    const image = currentEmploy.company?.photo_url
    if (isBusinessSession && !isEmpty(image)) {
      return (
        <div className="Menu-Nav-Item">
          <div className="rounded Avatar" onClick={() => showProfilePanel(dispatch)}>
            <img alt={currentEmploy.company?.name} src={handleReturnImage(currentEmploy.company?.photo_url)} />
          </div>
        </div>
      )
    }
    if (!isBusinessSession) {
      return (
        <div className="Menu-Nav-Item">
          <div className="rounded Avatar" onClick={() => showProfilePanel(dispatch)}>
            <img alt={currentCustomer.name} src={handleReturnImage(currentCustomer.avatar_square)} />
          </div>
        </div>
      )
    }
  }
  return null
}

const renderButton = (name, url, pathname, dispatch) => (
  <div className="Menu-Nav-Item Menu-Nav-Item-Button">
    <Link
      className="white-text white-border Button"
      onClick={() => {
        if (matchPath(pathname || '', paths.SIGN_IN)) {
          dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))
        }
      }}
      to={url}
    >
      {name}
    </Link>
  </div>
)

const RenderLogo = ({ isBusinessSession }) =>  {
  const logo = CommonUtils.isTransportify() ? V2_LOGO_WHITE_TRANSPORTIFY : V2_LOGO_WHITE
  return (
    <div className="Logo cur-pointer">
      {isBusinessSession && (
        <div className="Logo-business-badge">
          For
          <br />
          Business
        </div>
      )}
      <a
        href="/bookings/new"
        onClick={() => {
          window.location.href = paths.NEW_BOOKING_SINGLE
        }}
        className={isBusinessSession ? 'logo-link' : 'flex'}
      >
        <img src={logo} alt="Logo deliveree white" role="presentation" />
      </a>
    </div>
  )
}

const Header = (props) => {
  const dispatch = useDispatch()
  const {
    customerSignedIn,
    currentCustomer,
    isBusinessSession,
    dropdownAreas,
    listLanguage,
    area,
    extraInfos,
    linkSetting,
    employs,
  } = props
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  const lastLoginEmployId = get(currentCustomer, 'last_login_employ_id', null)
  const companies = get(currentCustomer, 'companies', [])
  let currentEmploy = {}
  let company = {}
  if (!isEmpty(employs) && isBusinessSession) {
    currentEmploy = getCurrentEmploy(employs, lastLoginEmployId)
    company = getCompanyByEmployId(companies, currentEmploy.company_id)
  } else {
    currentEmploy = { ...currentCustomer }
  }

  const renderButtonAction = () => {
    const lang = i18n.language
    return (
      <>
        {renderButton(t('devise_template.sign_in'), `/customers/sign_in?lang=${lang}`, pathname, dispatch)}
        {renderButton(t('devise_template.sign_up'), `/customers/sign_up?lang=${lang}`, pathname, dispatch)}
      </>
    )
  }

  return (
    <div className="container-top-nav header-bar">
      <div
        className={`Menu Top-Nav ${
          pathname !== paths.API_DASHBOARD && pathname !== paths.TEST_MY_BOOKING ? 'fixed fixed-width' : ''
        }`}
      >
        <RenderLogo isBusinessSession={isBusinessSession} />
        <div className="Menu-Nav left">
          <Menu
            customerSignedIn={customerSignedIn}
            isBusinessSession={isBusinessSession}
            currentCustomer={currentCustomer}
            listLanguage={listLanguage}
            currentEmploy={currentEmploy}
            company={company}
          />
          {customerSignedIn && (
            <>
              <div className="Menu-Nav-Item Devina-Screen">
                <DevinaMenu currentCustomer={currentCustomer} extraInfos={extraInfos || {}} />
              </div>
              <div className="Menu-Nav-Item Devina-Screen Devina-Screen-Batchs">
                <BatchMenu />
              </div>
            </>
          )}
        </div>
        <div className="Menu-Nav right">
          {!linkSetting?.hideAreaSelector && <RenderAreas dropdownAreas={dropdownAreas} area={area} />}
          <RenderSwitchLanguages listLanguage={listLanguage} customerId={currentCustomer?.id}/>
          <RenderAvatar
            currentCustomer={currentCustomer}
            currentEmploy={currentEmploy}
            customerSignedIn={customerSignedIn}
            isBusinessSession={isBusinessSession}
            dispatch={dispatch}
          />
          {!customerSignedIn && renderButtonAction()}
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  customerSignedIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  area: PropTypes.shape({}).isRequired,
  listLanguage: PropTypes.arrayOf(PropTypes.string),
  isBusinessSession: PropTypes.bool.isRequired,
  linkSetting: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  employs: PropTypes.array,
}

export default Header
