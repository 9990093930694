import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Object } from 'lodash'
export interface IErrorAddress {
  outsideAddress?: any[]
  errorDistanceAddress?: any[]
  listLazyAddressError?: any
}
const initialState = {
  outsideAddress: [],
  errorDistanceAddress: [],
  listLazyAddressError: {},
} as IErrorAddress

const errorAddressSlice = createSlice({
  name: 'errorAddress',
  initialState,
  reducers: {
    updateErrorAddress: (state, action: PayloadAction<IErrorAddress>) => {
      if (action.payload.outsideAddress) state.outsideAddress = action.payload.outsideAddress
      if (action.payload.errorDistanceAddress) state.errorDistanceAddress = action.payload.errorDistanceAddress
      if (action.payload.listLazyAddressError) state.listLazyAddressError = action.payload.listLazyAddressError
    },
  },
  extraReducers: () => {},
})

export const errorAddressActions = errorAddressSlice.actions

export default errorAddressSlice
