import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const ContentWrapper = styled.div<ISBase>`
  padding: 20px 20px 25px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
`

const SMSCodeWrapper = styled.div<ISBase>`
  display: flex;
  justify-content: center;
  max-width: 402px;
  position: relative;
  span {
    width: 66px;
    height: 66px;
    border-radius: 5px;
    border: solid 3px #979797;
    box-sizing: border-box;
    font-size: 40px;
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`
export { ContentWrapper, SMSCodeWrapper }
