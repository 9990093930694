import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { Utils } from 'utils/Utils'
import { selectedVehicleType as currentVehicleTypeUtils } from 'utils/multiple_bookings/common'
import { miniMumMinutes } from 'utils/new_booking/common'
import I18n from 'i18n/i18n'
// ACTIONS
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
// COMPONENTS
import BookingCalendar from '../../../common/BookingCalendar'
import UnavailableAssignDriverPopupEye from '../../../common/popup/driver_preferences/UnavailableAssignDriverPopupEye'
import AssignableDriver from '../../../common/driver_preference/AssignableDriver'
// CONSTANTS
import {
  ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER,
  QUESTION_ICON_GRAY,
} from 'constants/imageConstants'
// API
// ASSETS

const mapStateToProps = (state, ownProps) => {
  const { booking } = ownProps
  return {
    tmpSelectedDateAssignDriver: booking.tmpSelectedDateAssignDriver,
    serviceTypes: state.serviceTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    bookingsActions: bindActionCreators(bookingsActionCreators, dispatch),
  }
}

class UnavailableDriverMultiple extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availableTimeSlots: [],
    }
    this.closeCalendar = this.closeCalendar.bind(this)
    this.handleSelectTimeSlot = this.handleSelectTimeSlot.bind(this)
  }

  componentWillMount() {
    this.setTimeSlots()
  }

  componentDidMount() {
    const {
      booking,
      countryCode,
      tmpSelectedDateAssignDriver,
      bookingsActions
    } = this.props
    const { availableTimeSlots } = this.state
    const options = {
      closetElement: this.driverSlider,
      contentElement: this.driverSliderContent,
      arrowLeft: this.driverSliderArrowLeft,
      arrowRight: this.driverSliderArrowRight,
    }
    if (!_.isEmpty(tmpSelectedDateAssignDriver)) {
      const preferTimeSlot = Utils.getPreferTimeSlot(
        availableTimeSlots, tmpSelectedDateAssignDriver, countryCode
      )
      if (_.includes(availableTimeSlots, preferTimeSlot)) {
        Utils.slideToTimeSlot(options, preferTimeSlot)
      }
    } else if (!_.isEmpty(booking.assignedPickupTime) && (_.isEmpty(tmpSelectedDateAssignDriver)
      || booking.assignedPickupTime === tmpSelectedDateAssignDriver)) {
      const selectedTime = moment(booking.assignedPickupTime).format('HH:mm')
      Utils.slideToTimeSlot(options, selectedTime)
      const dataChanged = {
        tmpSelectedDateAssignDriver: booking.assignedPickupTime,
        tmpSelectedTimeAssignDriver: selectedTime
      }
      bookingsActions.updateBooking(booking.id, dataChanged)
    }
    Utils.initDriverSlider(options)
  }

  componentDidUpdate(prevProps) {
    const { tmpSelectedDateAssignDriver: prevDateAssigned } = prevProps
    const { tmpSelectedDateAssignDriver, countryCode } = this.props
    const { availableTimeSlots } = this.state

    if (prevDateAssigned !== tmpSelectedDateAssignDriver) {
      const options = {
        closetElement: this.driverSlider,
        contentElement: this.driverSliderContent,
        arrowLeft: this.driverSliderArrowLeft,
        arrowRight: this.driverSliderArrowRight,
      }
      if (!_.isEmpty(tmpSelectedDateAssignDriver)) {
        const preferTimeSlot = Utils.getPreferTimeSlot(
          availableTimeSlots, tmpSelectedDateAssignDriver, countryCode
        )
        if (_.includes(availableTimeSlots, preferTimeSlot)) {
          Utils.slideToTimeSlot(options, preferTimeSlot)
        }
      }
      Utils.initDriverSlider(options)
    }
  }


  setTimeSlots(selectedTime = null, cb = () => { }) {
    const {
      booking,
      blockedTimeInfos,
      estimatedWorkingTime,
      selectedAmountFullDay,
      tmpSelectedDateAssignDriver,
    } = this.props
    let currentDate = selectedTime
    const amountFullday = selectedAmountFullDay || 1
    if (_.isEmpty(selectedTime)) {
      currentDate = tmpSelectedDateAssignDriver
    }
    const minutePickup = miniMumMinutes(this.currentVehicleType(), booking.time_type)
    const availableTimes = Utils.getAvailableTimeSlots(blockedTimeInfos, currentDate, minutePickup,
      estimatedWorkingTime, amountFullday)
    this.setState({
      availableTimeSlots: availableTimes
    }, () => {
      cb()
    })
  }

  currentVehicleType() {
    const { booking, serviceTypes } = this.props
    return currentVehicleTypeUtils(
      serviceTypes, booking.service_type_id, booking.vehicle_type_id
    )
  }

  closeCalendar(cancel, selectedDateTime) {
    const {
      bookingsActions,
      booking
    } = this.props

    Utils.hideCloseBtn(false)
    this.setState({
      showCalendarPickup: false,
    })
    if (!cancel) {
      $(this.driverSliderContent).children('span').removeClass('Dark-Green-bg White-text cur-default').addClass('White-bg default-color cur-pointer')

      this.setTimeSlots(selectedDateTime, () => {
        const dataChanged = {
          tmpSelectedDateAssignDriver: selectedDateTime,
          tmpSelectedTimeAssignDriver: ''
        }
        bookingsActions.updateBooking(booking.id, dataChanged)
      })
    }
  }

  showCalendarDriver() {
    Utils.hideCloseBtn()
    this.setState({
      showCalendarPickup: true,
    })
  }

  handleSelectTimeSlot(event, time) {
    const { closePopup } = this.props
    $(this.driverSliderContent).children('span').removeClass('Dark-Green-bg White-text cur-default').addClass('White-bg default-color cur-pointer')
    $('.Popover-Item-Driver-Item.Modal-Driver-Change-Date-Time').removeClass('error')
    $('.Popover-Item-Driver-Item.Modal-Driver-Change-Date-Time .Item-Driver-Show').addClass('hidden')
    const { bookingsActions, booking } = this.props

    Promise.resolve(
      bookingsActions.updateBooking(booking.id, { tmpSelectedTimeAssignDriver: time })
    ).then(() => {
      closePopup(true)
    })
  }

  render() {
    const {
      booking,
      countryCode,
      driver,
      dataUnavailablePopupEye,
      tmpSelectedDateAssignDriver,
      maxPickupTimeDays,
    } = this.props
    const {
      showCalendarPickup,
      availableTimeSlots,
    } = this.state
    const driverAvatar = driver.fleet_partner_id ? ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER : driver.driver_image_url
    const driverName = driver.fleet_partner_name || Utils.driverFirstName(driver.name)
    const currentVehicle = driver.current_vehicle
    const isSameDay = moment(tmpSelectedDateAssignDriver).isSame(booking.assignedPickupTime, 'day')
    const pickupTime = booking.tmpSelectedTimeAssignDriver && isSameDay ? moment(booking.assignedPickupTime).format('H:mm') : ''
    let vehicleTypeName = ''

    if (!!currentVehicle && !!currentVehicle.vehicle_type_name) {
      vehicleTypeName = currentVehicle.vehicle_type_name
    }

    let driverPlateNumber = I18n.t('webapp.new_booking.step_2.assign_driver.fleet_plate_number')
    if (!!currentVehicle && !!currentVehicle.vehicle_attributes && _.isNull(driver.fleet_partner_id)) {
      driverPlateNumber = currentVehicle.vehicle_attributes.plate_number
    }

    return (
      <div className="Modal-Driver-Change-Date-Time pt10 pb10 unvailable-driver">
        <AssignableDriver
          noClickHandler
          driverInfos={{
            avatar: {
              url: driverAvatar,
              size: ''
            },
            name: driverName,
            specialClass: 'Black-text',
            vehicleTypeName,
            driverPlateNumber
          }}
          isFleet={driver.fleet_partner_id}
        >
          <div className="flex-center invalid-driver-text">
            {I18n.t('label.preference_driver.schedule_conflict')}
          </div>
        </AssignableDriver>
        <div className="Popover-Item-Driver-Item-Middle mar15 mt10">
          <div className="select-available-text">
            {I18n.t('label.preference_driver.select_available_pickup_slot')}
            <UnavailableAssignDriverPopupEye
              dataUnavailablePopupEye={dataUnavailablePopupEye}
              icon={QUESTION_ICON_GRAY}
            />
          </div>
          <div
            className="Radius-three White-bg h30px relative flex flex-center-algin cur-pointer pickup-datetime"
            onClick={() => this.showCalendarDriver()}
          >
            <span className="default-font default-color flex-index ml10">
              {Utils.formatAssignDriverDate(moment(tmpSelectedDateAssignDriver), true)}
            </span>
            <i className="material-icons Icon large-icon mr10 Green-text">
              keyboard_arrow_down
            </i>
          </div>
        </div>
        {showCalendarPickup && (
          <BookingCalendar
            isShowCancelBtn
            isShowOkBtn
            selectedDateTime={tmpSelectedDateAssignDriver}
            minMinutes={0}
            closePicker={this.closeCalendar}
            maximumPickupDays={maxPickupTimeDays}
            modalCssClassName="PickupTime-Modal-Driver"
            countryCode={countryCode}
            visibleTimer={false}
            configStyle={{ id: booking.id, scale: false }}
          />
        )}
        {_.isEmpty(availableTimeSlots) ? (
          <p className="center default-font unvailable-desc">
            {`${I18n.t('label.preference_driver.driver_not_available')}`}
          </p>
        )
          : (
            <div className="Popover-Item-Driver-Item-Bottom">
              <div className="Driver-Item-Sliders flex">
                <div
                  ref={(ref) => {
                    this.driverSliderArrowLeft = ref
                  }}
                  className="Driver-Item-Arrow Left flex flex-center-algin mr5"
                >
                  <i className="material-icons Icon large-icon cur-pointer">
                    keyboard_arrow_left
                  </i>
                </div>
                <div
                  ref={(ref) => {
                    this.driverSlider = ref
                  }}
                  className="Driver-Item-Slider o-hidden flex flex-index"
                >
                  <div
                    ref={(ref) => {
                      this.driverSliderContent = ref
                    }}
                    className="flex"
                  >
                    {availableTimeSlots.map(slider => (
                      <span
                        key={`slider-${slider}`}
                        onClick={e => this.handleSelectTimeSlot(e, slider)}
                        className={`slot-time Radius-default default-font mr15 pt10 pr15 pb10 pl15 Box-Sizing Border-Box ${slider === pickupTime ? 'selected White-text cur-default' : 'White-bg default-color cur-pointer'}`}
                        style={{
                          minWidth: '65px',
                          justifyContent: 'center'
                        }}
                      >
                        {slider}
                      </span>
                    ))}
                  </div>
                </div>
                <div
                  ref={(ref) => {
                    this.driverSliderArrowRight = ref
                  }}
                  className="Driver-Item-Arrow Right flex flex-center-algin ml5"
                >
                  <i className="material-icons Icon large-icon cur-pointer">
                    keyboard_arrow_right
                  </i>
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

UnavailableDriverMultiple.propTypes = {
  bookingsActions: PropTypes.shape({}).isRequired,
  booking: PropTypes.shape({}).isRequired,
  driver: PropTypes.shape({}).isRequired,
  serviceTypes: PropTypes.instanceOf(Array).isRequired,
  dataUnavailablePopupEye: PropTypes.shape({}).isRequired,
  countryCode: PropTypes.string.isRequired,
  maxPickupTimeDays: PropTypes.number.isRequired,
  blockedTimeInfos: PropTypes.shape({}),
  tmpSelectedDateAssignDriver: PropTypes.string,
  selectedAmountFullDay: PropTypes.number,
  estimatedWorkingTime: PropTypes.number,
  closePopup: PropTypes.func
}

UnavailableDriverMultiple.defaultProps = {
  tmpSelectedDateAssignDriver: undefined,
  selectedAmountFullDay: undefined,
  estimatedWorkingTime: undefined,
  blockedTimeInfos: {},
  closePopup: () => { }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnavailableDriverMultiple)
