import React, { useRef } from 'react'
import PropTypes from 'prop-types'
//UTILS
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { POPUP_ID_SERVICE_TYPE_COMING_SOON, POP_MOTOR_BIKE_TYPE, POP_MOTOR_TYPE } from 'constants/common/popupConstants'
import { COUNTRY_ID, COUNTRY_VN } from 'constants/dashBoardAnalyticsConstants'
import useOutsideAlerter from 'hooks/commonHooks'
// ASSETS

const ServiceTypeComingSoonPopup = (props) => {
  const wrapperRef = useRef(null)
  const {
    popupIdPrefix, comingSoonServiceTypeName, countryCode, iconComingSoonService, setShowComingSoonService
  } = props
  useOutsideAlerter(wrapperRef, () => setShowComingSoonService(false))
  const isMoToIndo = (countryCode === COUNTRY_ID || countryCode === COUNTRY_VN)
    && (comingSoonServiceTypeName === POP_MOTOR_TYPE || comingSoonServiceTypeName === POP_MOTOR_BIKE_TYPE)
  const titleText = isMoToIndo ? I18n.t('webapp.new_booking.step_1.service_type_coming_soon_title')
    : I18n.t('webapp.new_booking.step_1.service_type_coming_title')
  const contentText = isMoToIndo ? I18n.t('webapp.new_booking.step_1.service_type_coming_soon_content')
    : I18n.t('webapp.new_booking.step_1.service_type_coming_content')
  return (
    <div className="vertical-scroll dlvr-modal absoute" id={`${popupIdPrefix}${POPUP_ID_SERVICE_TYPE_COMING_SOON}`}>
      <div className="Normal-Booking" ref={wrapperRef}>
        <div className="Box">
          <div className="Box-Icon">
            <img src={iconComingSoonService} className="Box-Icon-Image" alt="deliveree" />
          </div>
          <div className="Box-Content">
            <div className="Normal">
              <h5 className="Normal-Booking-Title">
                {titleText}
              </h5>
              <p className="center">
                {contentText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ServiceTypeComingSoonPopup.propTypes = {
  setShowComingSoonService: PropTypes.func.isRequired,
  comingSoonServiceTypeName: PropTypes.string,
  countryCode: PropTypes.string,
  popupIdPrefix: PropTypes.string,
  iconComingSoonService: PropTypes.string,
}

ServiceTypeComingSoonPopup.defaultProps = {
  comingSoonServiceTypeName: '',
  countryCode: '',
  popupIdPrefix: '',
  iconComingSoonService: ''
}

export default ServiceTypeComingSoonPopup
