import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = 1

const validateStepSlice = createSlice({
  name: 'validateStep',
  initialState,
  reducers: {
    needToValidateStep: (state, action: PayloadAction<any>) => action.payload,
    clearValidateStep: (state) => 0,
  },
  extraReducers: () => {},
})

export const validateStepActionsCreator = validateStepSlice.actions

export default validateStepSlice
