import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash-es';
import { Utils } from 'utils/Utils'
import { getParamFromURL, isBookAgain, isEditBooking } from 'utils/booking/common'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'

const initialState: any = {
  autoExpandReimbursement: false,
  companyBadges: [],
  companySettings: {
    allow_tolls_fees: false,
    allow_parking_fees: false,
    allow_waiting_time_fees: false,
    lock_check_tolls_fees: false,
    lock_check_parking_fees: false,
    lock_check_waiting_time_fees: false,
  },
  customReimbursements: [],
  extraRequirements: [],
  extraRequirementsNegativePosition: [],
  fullDayPricing: {},
  isGetExtraService: false,
  timeTypeOfExtraServices: '',
  vehicleTypeBadges: [],
  vehicleTypeSettings: {},

  // ez-sheet and slp
  vehicleTypes: [],
  extraRequirementVehicleTypes: [],
  fulldayExtraRequirementVehicleTypes: [],
  longHaulExtraRequirementVehicleTypes: [],
  selectedExtraRequirementForPerLocation: [],
  batchVehicleTypeBadges: [],
  batchCompanyBadges: [],
  batchBookingExtraRequirements: [],
  batchBookingExtraRequirementsNegativePosition: [],
  regularByOptionExtras: [],
  fullDayByOptionExtras: [],
  longHaulByOptionExtras: [],
  batchCustomReimbursements: [],
  preVehicleTypeId: null
}
const extraServicesSlice = createSlice({
  name: 'extraServices',
  initialState,
  reducers: {
    // new booking
    receiveExtraServices: (state, action: PayloadAction<any>) => {
      // autoExpandReimbursement
      state.autoExpandReimbursement = action.payload.extraServices.auto_expand_more_options_reimbursement

      // companyBadges
      if (!_.isEmpty(action.payload.extraServices.company_badges)) {
        const badges = action.payload.extraServices.company_badges.data
        if (!_.isEmpty(badges)) {
          _.forEach(badges, (badge) => {
            _.assign(badge, {
              selected: badge.check_by_default,
              badgeable_relation_type: 'CompanyBadge',
              selected_amount: badge.check_by_default ? 1 : 0,
            })
          })
          state.companyBadges = badges
        } else {
          state.companyBadges = []
        }
      }
      
      // companySettings
      const preCompanySettings = _.get(action.payload, 'extraServices.preExtraServices.companySettings')
      const curCompanySettings = _.get(action.payload, 'extraServices.company_settings')
      const preVehicleTypeSettings = _.get(action.payload, 'extraServices.preExtraServices.vehicleTypeSettings')
      const isVehicleTypeChanged = action.payload.extraServices.isVehicleTypeChanged
      const isTimeTypeChanged = action.payload.extraServices.isTimeTypeChanged
      state.companySettings = Utils.keepDataStaticReimbursement(
        preCompanySettings, curCompanySettings, preVehicleTypeSettings, isTimeTypeChanged,
        isVehicleTypeChanged
      )
      
      // customReimbursements
      const preCustomReimbursements = _.get(action.payload, 'extraServices.preExtraServices.customReimbursements')
      const currentCustomReimbursements = _.get(action.payload, 'extraServices.custom_reimbursements')
        // keep data when step 2 => step 1 => step 2
      state.customReimbursements =Utils.handleAndKeepDataCusomReimbursement(
        preCustomReimbursements, currentCustomReimbursements, isTimeTypeChanged,
        isVehicleTypeChanged
      )
    
      // extraRequirements
      const { preExtraRequirements, bookingDetails } = action.payload.extraServices
      const newExtraRequirements = action.payload.extraServices?.extra_requirements?.data || []
      const isInEditMode = !_.isEmpty(bookingDetails) && (isBookAgain() || isEditBooking())
      const isFirstGetExtraService = !_.get(action.payload, 'extraServices.preExtraServices.isGetExtraService')
      const isBookAgainCase = isInEditMode && isFirstGetExtraService

      const paramID = getParamFromURL('upgrade_option')
      if (!_.isEmpty(newExtraRequirements)) {
        _.forEach(newExtraRequirements, (extraRequirement) => {
          const foundExtraRequirement = preExtraRequirements?.find((i: any) => i.id === extraRequirement.id)
          const allowToUseOldData = !isTimeTypeChanged && !isVehicleTypeChanged && !_.isUndefined(foundExtraRequirement)
          const isBookAgainCaseAtFirst = isBookAgainCase && !isTimeTypeChanged && !isVehicleTypeChanged
          const { selected, selectedAmount, selectedPricing } = isBookAgainCaseAtFirst
            ? Utils.handleBookAgainRequirements({ extraRequirement, booking: bookingDetails, timeType: action.payload.timeType })
            : Utils.handleExtraRequirements({
              extraRequirement,
              allowToUseOldData,
              timeType: action.payload.timeType,
              foundExtraRequirement,
              selectedPricingID: foundExtraRequirement?.selectedPricing?.id,
              isPreSelect: paramID
            })
  
          _.assign(extraRequirement, {
            selected,
            selected_amount: selectedAmount,
            selectedPricing
          })
        })
        state.extraRequirements = newExtraRequirements
      } else {
        state.extraRequirements = []
      }

      // extraRequirementsNegativePosition
      const currExtraRequirementsNegative = action.payload.extraServices.extra_requirements_negative_position.data
      const prevExtraRequirementsNegative = _.get(action.payload, 'extraServices.preExtraServices.extraRequirementsNegativePosition')
      state.extraRequirementsNegativePosition = Utils.keepDataExtraRequirementsNegative({
        currExtraRequirementsNegative, prevExtraRequirementsNegative,
        timeType: action.payload.timeType, isTimeTypeChanged, isVehicleTypeChanged
      })

      // fullDayPricing
      const { fullDayPricing: prevFullDayPricing } = action.payload.extraServices.preExtraServices

      const isNewCase = isFirstGetExtraService || isTimeTypeChanged || isVehicleTypeChanged
      const newFullDayPricing = action?.payload?.extraServices?.full_day_pricing

      if (isNewCase && newFullDayPricing) {
        state.fullDayPricing = {
          ...newFullDayPricing, selected_amount: 1 
        }
      } else {
        state.fullDayPricing = prevFullDayPricing
      }
      // isGetExtraService
      state.isGetExtraService = true

      // timeTypeOfExtraServices
      state.timeTypeOfExtraServices = action.payload.timeType

      // vehicleTypeBadges
      const badges = action.payload.extraServices.vehicle_type_badges.data
      const { vehicleTypeBadges: preBadges } = action.payload.extraServices.preExtraServices
      let newVehicleTypeBadges = []
      if(!_.isEmpty(badges)) {
        if (!isVehicleTypeChanged && !_.isEmpty(preBadges)) {
          newVehicleTypeBadges = preBadges
        } else {
          _.forEach(badges, (badge) => {
            _.assign(badge, { selected: false, badgeable_relation_type: 'VehicleTypeBadge' })
          })
          newVehicleTypeBadges = badges
        }
      }
      state.vehicleTypeBadges = newVehicleTypeBadges

      // vehicleTypeSettings
      state.vehicleTypeSettings = action.payload.extraServices.vehicle_type_settings
    },
    updateCompanyBadge: (state, action: PayloadAction<any>) => {
      const badges = [...state.companyBadges]
      const badge = _.find(badges, (b) => parseInt(b.id, 10) === parseInt(action.payload.id, 10))
      if (!_.isUndefined(badge)) {
        _.assign(badge, action.payload.attrs)
        state.companyBadges = badges
      }
    },
    updateCompanySetting: (state, action: PayloadAction<any>) => {
      state.companySettings =  {
        ...state.companySettings,
        ...action.payload
      }
    },
    clearVehicleTypeSettings: (state, action: PayloadAction<any>) => {
      state.companySettings = {}
      state.vehicleTypeSettings = {}
    },
    updateCustomReimbursement:  (state, action: PayloadAction<any>) => {
      const currentCustomReimbursement = _.cloneDeep(state.customReimbursements)
      const reimbursement = _.find(currentCustomReimbursement, item => item.id === action.payload.id)
      if (!_.isUndefined(reimbursement)) {
        _.assign(reimbursement, action.payload.attrs)
      }
      state.customReimbursements = [
        ...currentCustomReimbursement
      ]
    },
    updateExtraRequirement: (state, action: PayloadAction<any>) => {
      const index = _.findIndex(state.extraRequirements, (ex:any) => +ex.id === +action.payload.id)
      if (index >= 0) {
        state.extraRequirements[index] = { ...state.extraRequirements[index], ...action.payload.attrs }
      }
    },
    updateExtraRequirements: (state, action: PayloadAction<any>) => {
      state.extraRequirements = action.payload
    },
    updateExtraRequirementNegativePosition: (state, action: PayloadAction<any>) => {
      const index = _.findIndex(state.extraRequirementsNegativePosition, (ex: any) => +ex.id === +action.payload.id)
      if (index >= 0) {
        state.extraRequirementsNegativePosition[index] = { ...state.extraRequirementsNegativePosition[index], ...action.payload.attrs }
      }
    },
    updateFullDayPricing:  (state, action: PayloadAction<any>) => {
      state.fullDayPricing = {
        ...state.fullDayPricing,
        ...action.payload
      }
    },

    // ez-sheet and slp

    // vehicleTypes    
    receiveVehicleTypes: (state, action: PayloadAction<any>) => {
      state.vehicleTypes = action.payload
    },
    // extraRequirementVehicleTypes
    receiveExtraRequirementVehicleTypes: (state, action: PayloadAction<any>) => {
      state.extraRequirementVehicleTypes = action.payload
    },
    // fulldayExtraRequirementVehicleTypes
    receiveExtraRequirementVehicleTypesForFullday: (state, action: PayloadAction<any>) => {
      state.fulldayExtraRequirementVehicleTypes = action.payload
    },
    // longHaulExtraRequirementVehicleTypes
    receiveExtraRequirementVehicleTypesForLongHaul: (state, action: PayloadAction<any>) => {
      state.longHaulExtraRequirementVehicleTypes = action.payload
    },
    // selectedExtraRequirementForPerLocation
    updateSelectedExtraRequirementForPerLocation: (state, action: PayloadAction<any>) => {
      state.selectedExtraRequirementForPerLocation = action.payload
    },
    // batchVehicleTypeBadges
    receiveBatchVehicleTypeBadges: (state, action: PayloadAction<any>) => {
      const vehicleTypeBadges = action.payload
      _.forEach(vehicleTypeBadges, (vehicleTypeBadge) => {
        if (vehicleTypeBadge.amount == null) {
          _.assign(vehicleTypeBadge, { amount: 1 })
        }
        _.assign(vehicleTypeBadge, { badgeable_relation_type: 'VehicleTypeBadge' })
      })
      if(vehicleTypeBadges) {
        state.batchVehicleTypeBadges = vehicleTypeBadges
      }
    },

    // batchCompanyBadges
    receiveBatchCompanyBadges: (state, action: PayloadAction<any>) => {
      const companyBadges = [...action.payload]
      _.forEach(companyBadges, (companyBadge) => {
        if (companyBadge.amount == null) {
          _.assign(companyBadge, { amount: 1 })
        }
        _.assign(companyBadge, { badgeable_relation_type: 'CompanyBadge' })
      })
      if(companyBadges) {
        state.batchCompanyBadges = companyBadges
      }
    },

    //vehicleTypeBadges
    updateBadge: (state, action: PayloadAction<any>) => {
      const badge = _.find(state.vehicleTypeBadges, (b) => parseInt(b.id, 10) === parseInt(action.payload.id, 10))
      if (!_.isUndefined(badge)) {
        _.assign(badge, action.payload.attrs)
      }
    },
    // batchBookingExtraRequirements
    bookingExtraRequirements: (state, action: PayloadAction<any>) => {
      state.batchBookingExtraRequirements = action.payload
    },
    // batchBookingExtraRequirementsNegativePosition
    bookingExtraRequirementsNegativePosition: (state, action: PayloadAction<any>) => {
      state.batchBookingExtraRequirementsNegativePosition = action.payload.map((item: any) => ({
        ...item,
        negativePosition: true,
      }))
    },
    // regularByOptionExtras
    receiveRegularByOptionExtras: (state, action: PayloadAction<any>) => {
      state.regularByOptionExtras = action.payload
    },
    // fullDayByOptionExtras
    receiveFullDayByOptionExtras: (state, action: PayloadAction<any>) => {
      state.fullDayByOptionExtras = action.payload
    },
    // longHaulByOptionExtras
    receiveLongHaulByOptionExtras: (state, action: PayloadAction<any>) => {
      state.longHaulByOptionExtras = action.payload
    },
    // batchCustomReimbursements
    receiveBatchCustomReimbursement: (state, action: PayloadAction<any>) => {
      state.batchCustomReimbursements = action.payload
    },
    // preSTIdAndVTId
    setPreVehicleTypeId: (state, action: PayloadAction<any>) => {
      state.preVehicleTypeId = action.payload
    },
    updateExtraServicesTimeType: (state, action: PayloadAction<any>) => {
      state.timeTypeOfExtraServices = action.payload
    }
  },
  extraReducers: () => {},
})
export const extraServicesActionsCreator = extraServicesSlice.actions

export default extraServicesSlice
