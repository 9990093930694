import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ONE_MONTH, YESTERDAY, UPDATE_EXPORT_TEMPLATE } from 'constants/exportBookingsConstants'
import _, { isEmpty } from 'lodash'
import ExportBookingsUtils from 'utils/export_bookings/exportBookingsUtils'

const fromDate = () => (ExportBookingsUtils.calculateDay(ONE_MONTH))

const toDate = () => (ExportBookingsUtils.calculateDay(YESTERDAY))

const initState = () => {
  const initTemplate = {
    bookingInfo: {
      ids: true,
      job_numbers: true,
      customer_names: true,
      driver_names: true,
      platforms: true,
      vehicle_type_names: true,
      statuses: true,
      time_types: true,
      created_at: true,
      pickup_times: true,
      completed_at: true,
      pickup_names: true,
      customer_location_name: true,
      destination_names: true,
      driver_notes: true,
      destinations_counts: true,
      total_distances: true,
      break_down_of_distance: true,
      payment_types: true,
      waiting_times: true,
      driver_arrived_time: true,
      driver_finished_time: true,
      customer_note: true,
      driver_license_plate: true,
      driver_reimburse_request: true
    },
    fees: {
      total_prices: true,
      standard_fares: true,
      extra_services: true,
      goods_insurances: true,
      out_of_service_areas: true,
      surcharges: true,
      discounts: true,
      tolls: true,
      parkings: true,
      extend_wait_times: true,
      overtimes: true,
      other_reimbursements: true,
      free_reimbursements: true,
      cancelation_fees: true,
    },
    codPod: {
      cod_pod_fees: true,
      total_cod_amounts: true,
      notes: true,
    },
  }
  return initTemplate
}

const initialState = initState()

const exportTemplateSlice = createSlice({
  name: 'driverMarkers',
  initialState,
  reducers: {
    syncExportTemplate: (state: any, action: PayloadAction<any>) => {
      const { isBp } = action.payload
      if (!_.isNil(action.payload.exportTemplate.object)) {
        const template = action.payload.exportTemplate.object
        _.keys(template).forEach(templateKey => (
          _.keys(template[templateKey]).forEach((key) => {
            template[templateKey][key] = JSON.parse(template[templateKey][key])
          })
        ))
        state.bookingInfo = {
          ...state.bookingInfo,
          ...template.booking_info
        }
        state.fees = {
          ...state.fees,
          ...template.fee,
          ...(isBp ? null : { 'vendor_tipping_amount': template.fee.vendor_tipping_amount })
        }
        state.codPod = {
          ...state.codPod,
          ...template.cod_pod
        }
      } else {
        const { prevExportTemplate = {} } = action.payload
        let newExportTemplate = { ...initialState }
        if (!isEmpty(prevExportTemplate)) {
          newExportTemplate = {
            ...prevExportTemplate,
            fees: {
              ...prevExportTemplate.fees,
              ...(isBp ? null : { 'vendor_tipping_amount': true })
            }
          }
        }
        return newExportTemplate
      }
    },
    updateExportTemplate:  (state: any, action: PayloadAction<any>) => action.payload
  },
  extraReducers: () => {},
})

export const exportTemplateActions = exportTemplateSlice.actions

export default exportTemplateSlice
