import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState = ''

const accountManageSlice = createSlice({
  name: 'currentCustomer',
  initialState,
  reducers: {
    updateModalAccountManage: (state, action: PayloadAction<string>) => action.payload,
  },
  extraReducers: () => {},
})

export const accountManageActions = accountManageSlice.actions

export default accountManageSlice
