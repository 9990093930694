import { useEffect, useState } from 'react'

const SMALL_SCREEN = 1366

// for small laptop (1024 <= width <= 1366), we need to calculate ratio due to every things is zoom in 0.8
const generateSize = () => ({
  width: 1024 <= window.innerWidth && window.innerWidth <= SMALL_SCREEN ? window.innerWidth / 0.8 : window.innerWidth,
  height: 1024 <= window.innerWidth && window.innerWidth <= SMALL_SCREEN ? window.innerHeight / 0.8 : window.innerHeight,
})

const useViewportResize = () => {
  const [size, setViewportSize] = useState(generateSize())

  useEffect(() => {
    const handler = () => {
      setViewportSize(generateSize())
    }

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  return size
}

export default useViewportResize
