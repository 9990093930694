import { SCHEDULE } from '../constants/bookingConstants'
import I18n from 'i18n/i18n'
import apiClient from 'services/axiosApp'

const ezSheetAPI = {
  getVehicleTypes: (areaId, companyId = 0, dropOffCount = 2, isSmartPlanner = false) => {
    const params = {
      area_id: areaId,
      company_id: companyId
    }
    if (!isSmartPlanner) params.dropoff_count = dropOffCount

    return apiClient.get('/api/v3/batches/vehicle_types', {
      params
    })
  },

  getExtraRequirementVehicleTypes: (params, extraRequirementType) => {
    const {
      areaId, vehicleTypeIds,
      companyId, isFullDay, isLongHaul, timeType,
    } = params
    return apiClient.get('/api/v3/batches/extra_requirement_vehicle_types',{
      params: {
        area_id: areaId,
        'vehicle_type_ids': vehicleTypeIds,
        extra_requirement_type: extraRequirementType,
        company_id: companyId || 0,
        is_full_day: isFullDay || false,
        is_long_haul: isLongHaul || false,
        time_type: timeType || SCHEDULE,
      }
    })
  },

  getCustomReimbursementsForEzSheet: (vehicleTypeIds, companyId = 0) => apiClient.get('/api/v3/batches/custom_reimbursements_for_ezsheet', {
    params: {
      'vehicle_type_ids': vehicleTypeIds,
      company_id: companyId,
    }
  }),

  getVehicleTypeBadges: (vehicleTypeIds, companyId = 0) => apiClient.get('/api/v3/batches/vehicle_type_badges', {
    params: {
      'vehicle_type_ids': vehicleTypeIds,
      company_id: companyId,
    }
  }),

  getCompanyBadges: (companyId = 0) => apiClient.get('/api/v3/batches/company_badges', {
    params: {
      company_id: companyId,
    }
  }),
}

export default ezSheetAPI
