/* eslint-disable import/prefer-default-export */
export const UPDATE_OPTIMIZE_LOCATIONS = 'SMART_PLANNER/UPDATE_OPTIMIZE_LOCATIONS'

export const STATUS_PICKUP = {
  EDIT: 'EDIT'
}

export const RECOMMENDED_COLUMN = [
  'phone',
  'item_length',
  'item_width',
  'item_height',
  'item_weight',
  'item_quantity',
  'address_01',
  'address_02',
  'district',
  'city',
  'state_province',
  'latitude',
  'longitude',
]

export const POPUP_SMART_PLANNER_LOADING = 'POPUP_SMART_PLANNER_LOADING'

export const LIMIT_PERCENT = 100

export const TOTAL_TIME_LOADING = 60000 * 10 // 10min

export const TIME_FINISH_AFTER_SUCCESS = 5000

export const LIMIT_CONVERT_TO_M3 = 999999

export const LIMIT_CONVERT_TO_TON = 999

export const ONE_M3_CM3 = 1000000

export const ONE_TON_KG = 1000

export const LIMIT_CONVERT_TO_M = 999

export const ONE_M_CM = 1000

export const UPLOAD_WRONG_TEMPLATE = 'UPLOAD_WRONG_TEMPLATE'

export const UPLOAD_EMPTY_FILE = 'UPLOAD_EMPTY_FILE'

export const UPLOAD_INVALID_DATA = 'UPLOAD_INVALID_DATA'

export const BUNDLE_STATUS = {
  pending: 'pending',
  success: 'success',
  failed: 'failed'
}

export const INVALID_ROW_POPUP = 'INVALID_ROW_POPUP'


export const BOOKING_COLORS = [
  // 0
  {
    main: '#1296fe',
    sub: '#0d70bf'
  },
  // 1
  {
    main: '#fe7c9c',
    sub: '#bf5d75'
  },
  // 2
  {
    main: '#e1b12c',
    sub: '#a98521'
  },
  // 3
  {
    main: '#d5382d',
    sub: '#a02a21'
  },
  // 4
  {
    main: '#42ba71',
    sub: '#318c55'
  },
  // 5
  {
    main: '#dc71ef',
    sub: '#a555b3'
  },
  // 6
  {
    main: '#7582bf',
    sub: '#58618f'
  },
  // 7
  {
    main: '#d3603d',
    sub: '#9e482d'
  },
  // 8
  {
    main: '#273c75',
    sub: '#1d2d58'
  },
  // 9
  {
    main: '#7f8c8d',
    sub: '#5f696a'
  },
  // 10
  {
    main: '#3dcecc',
    sub: '#2d9b99'
  },
  // 11
  {
    main: '#b333c8',
    sub: '#862696'
  },
  // 12
  {
    main: '#6ab04c',
    sub: '#4f8439'
  },
  // 13
  {
    main: '#ff9800',
    sub: '#c07200'
  },
  // 14
  {
    main: '#079992',
    sub: '#05736d'
  },
  // 15
  {
    main: '#4b4b4b',
    sub: '#383838'
  },
  // 16
  {
    main: '#78e08f',
    sub: '#5aa86b'
  },
  // 17
  {
    main: '#775fff',
    sub: '#5947c0'
  },
  // 18
  {
    main: '#a278d8',
    sub: '#795aa2'
  },
  // 19
  {
    main: '#d8c266',
    sub: '#a2924c'
  },
]

export const OUT_SERVICE_AREA = 'OUT_SERVICE_AREA'
export const SAMPLE_ROW = 'SLP123456'

export const ERROR_TYPES = {
  distance_maxtrix_failed: 2,
  localtion_cannot_carrier: 3
}

export const CONSIDER_ALL_VEHICLES = 'consider_all_vehicles'
export const CONSIDER_SPECIFIC_VEHICLES = 'consider_specific_vehicles'

export const LIST_SERVICE_TYPES_FOR_BUNDLE = [
  'more_than_ten_tons',
  'one_to_ten_tons',
  'lest_than_one_ton'
]

export const GOOGLE_REQUEST_PER_CALL = 40
export const DELAY_GEOCODING = 1000
