import { styled } from "../../STheme";

export const CheckBoxWrapper = styled.div`
  padding: 0 10px;
  border: 0;
  font-size: 14px;
  box-sizing: border-box;
  flex: 1;
  :focus {
    outline: none;
  }
  :disabled {
    background-color: #efefef;
  }
`