import { useEffect } from 'react'

const useEscapeEvent = (handler) => {
  useEffect(() => {
    const eventHandler = (e) => {
      if(e.key === 'Escape') {
        handler(e)
      }
    }
    document.addEventListener('keydown', eventHandler)

    /* eslint-disable consistent-return */
    return () => {
      document.removeEventListener('keydown', eventHandler)
    }
  }, [])
}

export default useEscapeEvent
