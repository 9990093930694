import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
// UTILS
import { UtilsHTML } from 'utils/html'
import I18n from 'i18n/i18n'
// ACTIONS
import { jobOrderNumberActionsCreator } from 'store/toolkit/newBooking/jobOrderNumber.reducer';
import { bindActionCreators } from 'redux';
// COMPONENTS
// CONSTANTS
// ASSETS

const mapStateToProps = state => ({
  jobOrderNumber: state.jobOrderNumber,
  bookAgainDetails: state.bookAgainDetails
});

function mapDispatchToProps(dispatch) {
  return {
    jobOrderNumberActions: bindActionCreators(jobOrderNumberActionsCreator, dispatch)
  }
}

class JobOrderNumber extends React.Component {
  constructor(props) {
    super(props)
    this.defaultJobOrderNumber = ''
  }

  componentDidMount() {
    const { jobOrderNumber } = this.props
    this.defaultJobOrderNumber = jobOrderNumber
  }

  handleBlur = (e) => {
    const value = e.target.value
    if (value !== this.defaultJobOrderNumber && value !== '') {
      window.Moengage.track_event('Job Number Used')
      this.defaultJobOrderNumber = value
    }
  }

  handleChangeJobOrderNumber = (e) => {
    const value = e.target.value
    const { jobOrderNumberActions } = this.props
    jobOrderNumberActions.updateJobOrderNumber(value)
  }

  render() {
    const { jobOrderNumber } = this.props
    return (
      <div>
        {UtilsHTML.renderOptionTitle(I18n.t('webapp.new_booking.step_2.job_order_number'), false)}
        <div className="JobOrder block">
          <div className="ml10 mr10 mar20">
            <div className="Input">
              <input
                name="booking[job_order_number]"
                placeholder=" "
                value={jobOrderNumber}
                onChange={this.handleChangeJobOrderNumber}
                onBlur={this.handleBlur}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

JobOrderNumber.defaultProps = {
  jobOrderNumber: undefined
}

JobOrderNumber.propTypes = {
  jobOrderNumberActions: PropTypes.shape({}).isRequired,
  jobOrderNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  bookAgainDetails: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOrderNumber)
