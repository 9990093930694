import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const switchAccountSlice = createSlice({
  name: 'switchAccount',
  initialState,
  reducers: {
    updateSwitchAccount: (state, action: PayloadAction<any>) => {
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const switchAccountActionsCreator = switchAccountSlice.actions

export default switchAccountSlice
