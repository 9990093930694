import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { currentVehicleType } from 'utils/new_booking/common'
import { findPopup } from 'utils/common/popupable'
import I18n from 'i18n/i18n'
// ACTIONS
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
// import * as actions from 'store/actions/new_booking/serviceTypeActionCreators';
// COMPONENTS
// CONSTANTS
import {
  POPUP_ID_FULL_DAY_S1,
  POPUP_ID_FULL_DAY_S2,
  POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S1,
  POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2,
  ITEM_TYPE_VEHICLE_TYPE_FD_TITLE,
  ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE,
  ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT,
  ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG,
  ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL,
  POPUP_VEHICLE_TYPE_LONG_HAUL_DROP_OFF,
  POPUP_ID_LONG_HAUL_DROP_OFF,
} from 'constants/common/popupConstants'
import { FULL_DAY, LONG_HAUL } from 'constants/bookingConstants'
import { getCurentCompanyTypeId } from 'utils/booking/common'
import { withNavigate } from 'hocs/withNavigate'
// ASSETS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  currentVehicleType: currentVehicleType(state),
  timeType: state.timeType,
  currentStep: state.currentStep,
})

function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
  }
}

class VehicleTypePopups extends React.Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  onS1CancelHandler() {
    $(`#${POPUP_ID_FULL_DAY_S1}`).removeClass("visible")
    this.props.stepActions.toggleDraggableMarkers(true)
  }

  onS1ContinueHandler() {
    const { currentCustomer, locationActions, stepActions, navigate } = this.props
    $(`#${POPUP_ID_FULL_DAY_S1}`).removeClass('visible')
    const companyTypeId = getCurentCompanyTypeId(currentCustomer)
    if (companyTypeId) {
      locationActions.updateCODPODFees()
    }
    stepActions.nextStep(navigate)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.onS1CancelHandler()
    }
  }

  render() {
    return (
      <div>
        {this.props.timeType == FULL_DAY && this.props.currentStep == 1 &&
          this.renderPopup(POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S1, POPUP_ID_FULL_DAY_S1)
        }
        {this.props.timeType == FULL_DAY && this.props.currentStep == 2 &&
          this.renderPopup(POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2, POPUP_ID_FULL_DAY_S2)
        }
        {this.props.timeType == LONG_HAUL &&
          this.props.currentStep == 1 &&
          this.renderPopup(POPUP_VEHICLE_TYPE_LONG_HAUL_DROP_OFF, POPUP_ID_LONG_HAUL_DROP_OFF)
        }
      </div>
    )
  }

  renderPopup(popupType, popupID) {
    if (_.isEmpty(this.props.currentVehicleType)) {
      return <span></span>
    }
    let popup = findPopup(this.props.currentVehicleType, popupType)
    let modalClassName = "vertical-scroll Modal"
    let iconURL = this.props.currentVehicleType.popup_icon_s1_url
    if (popupType == POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2) {
      modalClassName = "vertical-scroll Modal Modal-No-action"
      iconURL = this.props.currentVehicleType.popup_icon_url
    } else if (popupType == POPUP_VEHICLE_TYPE_LONG_HAUL_DROP_OFF) {
      modalClassName = "vertical-scroll Modal Modal-No-action"
    }

    return !_.isEmpty(popup) && (
      <div className={modalClassName} id={popupID}>
        <div className="Normal-Booking">
          <div className="Box">
            <div className="Box-Icon">
              <div className="Logo">
                {iconURL &&
                  <img src={iconURL} alt="deliveree" />
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal">
                {this.renderPopupItems(popup.popup_items)}
              </div>
            </div>
          </div>
          {popupType == POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S1 &&
            <div className="Normal-Booking-Actions Custom">
              <a href="javascript:void(0)" className="Gray no-hover" onClick={this.onS1CancelHandler.bind(this)}>{I18n.t("webapp.new_booking.back")}</a>
              <a href="javascript:void(0)" className="White no-hover" onClick={this.onS1ContinueHandler.bind(this)}>{I18n.t("webapp.new_booking.continue")}</a>
            </div>
          }
        </div>
      </div>
    )
  }

  renderPopupItems(items) {
    return items.map((item, index) =>
      this.renderPopupItem(item, index)
    )
  }

  renderPopupItem(item, index) {
    switch (item.item_type) {
      case ITEM_TYPE_VEHICLE_TYPE_FD_TITLE: {
        return (
          <div key={index}>
            {index > 0 && (
              <div className="Normal-Booking-Devide"></div>
            )
            }
            <h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.content }}></h5>
          </div>
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE: {
        return (
          <p key={index} className="center italic" dangerouslySetInnerHTML={{ __html: item.content }}></p>
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT: {
        return (
          <p key={index} className="center" dangerouslySetInnerHTML={{ __html: item.content }}></p>
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG: {
        return (
          <p key={index} className="center fullday" dangerouslySetInnerHTML={{ __html: item.formated_content }}></p>
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL: {
        return (
          <p key={index} className="center yellow fullday-custom" dangerouslySetInnerHTML={{ __html: item.formated_content }}></p>
        )
      }
      default:
        break
    }
  }
}

VehicleTypePopups.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(VehicleTypePopups))
