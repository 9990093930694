import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import _, { findIndex } from 'lodash'

const initialState: any = [
  {
    bookings: [],
    batch_bookings: [],
  },
]

const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    updateBatches: (state, action: PayloadAction<any>) => action.payload.batches,
    updateBatchAttrs: (state, action: PayloadAction<any>) => {
      const badge = _.find(state, (b) => b.id === action.payload.batch_id)
      if (!_.isUndefined(badge)) {
        _.assign(badge, action.payload.attrs)
      }
    },
    updateBatchBookingAttrsForBatches: (state, action: PayloadAction<any>) => {
      const currentBatchIdx = findIndex(state, { id: action.payload.batch_id })
      if (currentBatchIdx !== -1) {
        const bookings: any[] = state[currentBatchIdx].batch_bookings || state[currentBatchIdx].bookings
        const currentBookingIdx = findIndex(
          bookings,
          (b) => b.batch_tracking_token === action.payload.attrs.batch_tracking_token
        )
        if (currentBookingIdx !== -1) {
          bookings[currentBookingIdx] = {
            ...bookings[currentBookingIdx],
            ...action.payload.attrs,
          }
        }
      }
    },
  },
  extraReducers: () => {},
})

export const batchesActionsCreators = batchesSlice.actions

export default batchesSlice
