import { get, useFormContext } from 'react-hook-form'
import STypography from '../../STypography'
import { CheckBoxWrapper } from './Sindex'

interface IInputPhone {
  name: string
  label: any
  required?: boolean
  disabled?: boolean
  inputType: string
}

const SCheckBox = ({ name, label, disabled = false, inputType }: IInputPhone) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const error = get(errors, `${name}.message`)
  return (
    <CheckBoxWrapper>
      {label && (
        <STypography fs={15} mb={5}>
          {label}
        </STypography>
      )}
      <div>
        <input type={inputType} disabled={disabled} {...register(name)} />
        <label>
          <i className="b.material-icons.Icon">fiber_manual_record</i>
        </label>
      </div>
      {error?.length > 0 && (
        <STypography mt={5} variant="e4">
          {error}
        </STypography>
      )}
    </CheckBoxWrapper>
  )
}

export default SCheckBox
