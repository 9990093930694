import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash-es'
// UTILS
import { CPODUtils } from 'utils/booking/CPODUtils'
import { Utils } from 'utils/Utils'
import { LocationPopover } from 'utils/LocationUtil'
import I18n from 'i18n/i18n'
// API
// ACTIONS
import * as stepLocationActions from 'store/actions/new_booking/locationActionCreators'
// COMPONENTS
// CONSTANTS
import { FULL_DAY, LONG_HAUL, NEW_GEN_POD } from 'constants/bookingConstants'
import {
  POPUP_VEHICLE_TYPE_LONG_HAUL_DOCUMENT_RETURN, POPUP_VEHICLE_TYPE_SFPOD
} from 'constants/common/popupConstants'
// ASSETS
// CURRENT VEHICLE types
import { currentVehicleType } from 'utils/new_booking/common'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'

const mapStateToProps = state => ({
  bookAgainDetails: state.bookAgainDetails,
  currentCustomer: state.currentCustomer,
  extraInfos: state.extraInfos,
  locations: state.locations,
  timeType: state.timeType,
  popupCOD: state.popupCOD,
  selectedVehicleType: currentVehicleType(state),
  documentReturn: state.documentReturn,
  booking: state.booking,
  checkLocations: state.checkLocations,
  assignedDriver: state.assignedDriver,
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(stepLocationActions, dispatch),
    documentActions: bindActionCreators(documentReturnActionsCreator, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
  }
}

class CODAndPOD extends React.Component {
  checkAssignDriverAndShowPopover() {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props

    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.showPopoverHandler.bind(this) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.showPopoverHandler()
    }
  }

  showPopoverHandler() {
    const {
      locations, timeType, actions, documentActions, documentReturn,
    } = this.props
    const validLocations = _.filter(locations, l => (!_.isUndefined(l.lat) && !_.isUndefined(l.lng)))

    if ((validLocations.length === 1 && timeType !== FULL_DAY) || validLocations.length === 0) {
      const renderNode = document.getElementById('DefaultPopup')
      CPODUtils.showEnoughDestination(renderNode)
    } else {
      Promise.resolve(
        actions.initTmpLocations()
      ).then(() => {
        documentActions.updateDocumentReturn({
          ...documentReturn,
          is_show: true
        })
        LocationPopover.closeClass($('body'), 'Step-Route')
      })
    }
  }

  popupEyeHandler(isNewGenCODPOD) {
    const { selectedVehicleType } = this.props
    const renderNode = document.getElementById('DefaultPopup')
    const popupType = isNewGenCODPOD ? POPUP_VEHICLE_TYPE_LONG_HAUL_DOCUMENT_RETURN : POPUP_VEHICLE_TYPE_SFPOD
    const popup = _.filter(selectedVehicleType.popups, {
      popup_type: popupType
    })

    if (isNewGenCODPOD) {
      CPODUtils.showDocumentReturnPopup(popup[0], renderNode)
    } else {
      CPODUtils.showSFPODPopup(popup[0], renderNode)
    }
  }

  showCodPodPopup() {
    const { popupCOD } = this.props
    const renderNode = document.getElementById('DefaultPopup')

    CPODUtils.showPersonalCOD({
      icon: popupCOD[0] !== undefined ? popupCOD[0].icon_url : '',
      renderContent: CODAndPOD.renderContent(popupCOD),
      renderButtons: undefined,
    }, renderNode)
  }

  static renderContent(popupCOD) {
    if (popupCOD[1] !== undefined) {
      const url = popupCOD[1].link_url
      let content = popupCOD[1].content

      if (!_.isUndefined(url) && url.length > 0) {
        const linkText = popupCOD[1].link_text
        const linkClass = 'no-hover hover-underline hyperlink-default-color'

        if (!_.isUndefined(linkText) && linkText.length > 0
          && content.indexOf(linkText) !== -1
          && _.trim(content).length >= _.trim(linkText).length
        ) {
          const subContent = `<a class='${linkClass}' href='${url}'>${linkText}</a>`
          content = content.split(linkText).join(subContent)
        }
      }

      return (
        <p className="center pre-line Custom-Mb-15" dangerouslySetInnerHTML={{ __html: content }} />
      )
    }

    return null
  }

  renderLearnMore(isRequired) {
    return (
      <div className={`CodPod block CodPod-JS CodPod-Custom ${isRequired ? 'select-error' : ''}`}>
        <div className="block-item cur-default-important">
          <div className="TitleSubtitle">

            <div className="TitleSubtitle-title flex-start">
              <span className="default-font flex-index">
                {I18n.t('webapp.new_booking.step_2.cod_pod_block_desc')}
              </span>
              <span className="right default-font cur-pointer" onClick={() => this.showCodPodPopup()}>
                {I18n.t('webapp.new_booking.step_2.learn_more')}
              </span>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderCOD() {
    const {
      selectedVehicleType,
      currentCustomer,
      extraInfos,
      locations,
      documentReturn,
      bookAgainDetails,
      checkLocations,
      booking = {},
      timeType,
    } = this.props
    const { cod_pod_fees: codPodFees = 0 } = booking

    const documentReturnPopup = selectedVehicleType ? selectedVehicleType.popups.map(x => x.popup_type) : []
    const isNotUsingCODPOD = _.every(locations, {
      need_pod: false,
      need_cod: false
    })
    const isRequired = documentReturn ? documentReturn.is_required : false
    const isBusiness = currentCustomer.current_company_id
    const validLocationsPOD = !!_.find(locations, ['need_pod', true])
    const isNewGenCODPOD = CPODUtils.verifyNewGenCPOD({
      bookAgainDetails,
      checkLocations,
    })
    const countryLanguage = extraInfos.country_language

    const displayCodPodFeeLH = I18n.t('webapp.pricing_display.free')
    const displayCodPodFee = validLocationsPOD && codPodFees
      ? Utils.formatFee(+codPodFees, countryLanguage, extraInfos.currency, undefined, 8)
      : '—'
    const finalText = timeType === LONG_HAUL ? displayCodPodFeeLH : displayCodPodFee

    return (
      <div className={`CodPod block CodPod-JS ${isRequired ? 'select-error' : ''} ${isBusiness ? 'block' : 'CodPod-Custom'}`}>
        <div className="block-item">

          <div className="TitleSubtitle">
            <div className={`TitleSubtitle-title ${isBusiness ? '' : 'flex-start'}`}>
              <span onClick={() => this.checkAssignDriverAndShowPopover()}>
                {isNewGenCODPOD
                  ? I18n.t('webapp.new_booking.step_2.document_return')
                  : I18n.t('webapp.pricing_display.cod_flash_pod')
                }
              </span>

              {(documentReturnPopup.includes(POPUP_VEHICLE_TYPE_LONG_HAUL_DOCUMENT_RETURN)
                || documentReturnPopup.includes(POPUP_VEHICLE_TYPE_SFPOD)) && (
                <span
                  className={`step-2-eye ${isBusiness ? '' : 'flex-center ml5'}`}
                  onClick={() => this.popupEyeHandler(isNewGenCODPOD)}
                >
                  <i className="s material-icons Icon">
                    visibility
                  </i>
                </span>
              )}
            </div>

            <div className={`TitleSubtitle-subtitle ${isNotUsingCODPOD ? 'default-color' : ''}`} id="cod-pod-fees">
              {finalText}
            </div>
          </div>

          <i
            className={`xb material-icons Icon ${isNotUsingCODPOD ? 'default-color-icon' : 'default-color-active-icon'}`}
            onClick={() => this.checkAssignDriverAndShowPopover()}
          >
            keyboard_arrow_right
          </i>
        </div>
      </div>
    )
  }

  renderCodForLogged = (isRequired) => {
    const {
      bookAgainDetails,
      currentCustomer,
      checkLocations,
    } = this.props
    if (bookAgainDetails && bookAgainDetails.new_gen_pod) {
      if (checkLocations && !checkLocations.cod_pod) {
        return null
      }

      return this.renderCOD()
    }

    if (checkLocations && !checkLocations.cod_pod) {
      if (!currentCustomer.current_company_id) {
        return this.renderLearnMore(isRequired)
      }

      return null
    }

    return this.renderCOD()
  }

  render() {
    const {
      currentCustomer,
      documentReturn,
      checkLocations,
    } = this.props
    const isRequired = documentReturn ? documentReturn.is_required : false
    
    // IF the setting is ticked, it will hide SFPOD completely from the apps
    if (checkLocations && checkLocations?.hide_sfpod_nonbp && !currentCustomer.current_company_id) {
       return null
    }

    if (currentCustomer.id !== 0) {
      return this.renderCodForLogged(isRequired)
    }

    if (checkLocations && checkLocations.cod_pod === NEW_GEN_POD) {
      return this.renderCOD()
    }
    return this.renderLearnMore(isRequired)
  }
}

CODAndPOD.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  popupCOD: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.string,
  ]),
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  booking: PropTypes.shape({}),
  bookAgainDetails: PropTypes.shape({}),
  selectedVehicleType: PropTypes.shape({
    popups: PropTypes.instanceOf(Array)
  }),
  timeType: PropTypes.string,
  documentActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]),
  checkLocations: PropTypes.shape({}),
}

CODAndPOD.defaultProps = {
  selectedVehicleType: undefined,
  booking: {},
  bookAgainDetails: {},
  timeType: undefined,
  documentActions: null,
  checkLocations: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(CODAndPOD)
