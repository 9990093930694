import { ISvg } from 'interface/ISvg'
import React from 'react'

const Spin = (props: ISvg): JSX.Element => {
  const { width, height, fill } = props
  return (
    <svg width={width || 52} height={height || 52} viewBox="0 0 52 52" fill="none">
      <circle cx={26} cy={26} r={24} stroke="#d3d3d3" strokeWidth={3} />
      <path
        d="M26 2c13.255 0 24 10.745 24 24"
        stroke={fill || '#070550'}
        strokeWidth={3}
        strokeMiterlimit={1.414}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Spin
