// UTILS
// CONSTANTS
import _ from 'lodash'
import { FULL_DAY, MAX_LENGTH_NOTE } from 'constants/bookingConstants'

export const isFullDayBooking = (timeTypeOption) => {
  const timeType = timeTypeOption
  if (!_.isUndefined(timeType)) {
    if (timeType.type_key === FULL_DAY) {
      return true
    }
  }
  return false
}

export const updateVerifyLocations = (locations, timeType) => {
  const newLocations = _.map(locations, (location, index) => {
    const tempLocation = { ...location, verify: { ...location.verify } }
    if (timeType === FULL_DAY && index !== 0 && _.isEmpty(tempLocation.name)) {
      tempLocation.verify = {
        name: true,
        recipient_name: true,
        recipient_phone: true,
      }
    } else {
      if (_.isEmpty(tempLocation.name)) {
        tempLocation.verify.name = false
      }
      if (_.isEmpty(tempLocation.recipient_name)) {
        tempLocation.verify.recipient_name = false
      }
      if (_.isEmpty(tempLocation.recipient_phone)) {
        tempLocation.verify.recipient_phone = false
      }
      if (!_.isEmpty(tempLocation?.location_note) && tempLocation?.location_note.length > MAX_LENGTH_NOTE) {
        tempLocation.verify.location_note = false
      }
    }
    return tempLocation
  })
  return newLocations
}
