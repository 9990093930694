import React from 'react'
import PropTypes from 'prop-types'
import {
  DISTANCE_CALCULATOR,
} from 'constants/imageConstants'
import I18n from 'i18n/i18n'

class ChangePickupLocation extends React.PureComponent {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { handleClosePopup, handleYesConfirm } = this.props
    return (
      <div className="Change-pickup-location">
        <div className="content">
          <div className="Distance-calculator-img flex-center">
            <img src={DISTANCE_CALCULATOR} alt="distance calculator icon" className="m-h36px" />
          </div>
          <div className="Text-content">
            <div className="Title-content">
              {I18n.t('webapp.label.change_pickup_location')}
            </div>
            <div className="Description">
              {I18n.t('webapp.label.the_pickup_address')}
            </div>
          </div>
        </div>
        <div className="Change-pickup-location-actions">
          <button
            className="Button gray"
            type="button"
            onClick={() => handleClosePopup()}
          >
            {I18n.t('webapp.action.back')}
          </button>
          <button
            className="Button white green-text"
            type="button"
            onClick={() => handleYesConfirm()}
          >
            {I18n.t('webapp.action.yes_confirm')}
          </button>
        </div>
      </div>
    )
  }
}

ChangePickupLocation.defaultProps = {
  handleClosePopup: () => undefined,
  handleYesConfirm: () => undefined,
}

ChangePickupLocation.propTypes = {
  handleClosePopup: PropTypes.func,
  handleYesConfirm: PropTypes.func,
}

export default ChangePickupLocation
