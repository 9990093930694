import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''
const focusInputSlice = createSlice({
  name: 'focusInput',
  initialState,
  reducers: {
    updateFocusInput: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const focusInputActions = focusInputSlice.actions

export default focusInputSlice
