import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  STEP_UPLOAD_TAB_ADVANCED,
  STEP_UPLOAD_TAB_OUR_TEMPLATE,
  STEP_UPLOAD_TAB_USER_TEMPLATE,
} from 'constants/ezSpreadSheetConstants'
import {
  TAB_DATA_MAPPING_VAR_NEW,
  TAB_DATA_PICKUP_LOCATION_VAR_LATER,
} from 'constants/ez_spread_sheet/variables/stepUploadTabsDataConstants'
import _ from 'lodash'

const initialState: any = [
  {
    tabName: STEP_UPLOAD_TAB_OUR_TEMPLATE,
    uploadedFiles: [],
    name: '',
    desc: '',
    // use for step select Pickup Contact/Location
    pickupLocationSelectedFlow: TAB_DATA_PICKUP_LOCATION_VAR_LATER,
    pickupLocationSelectedObject: undefined,
    // use for step select Mapping
    // for the toggle create new / use previous
    // available values: TAB_DATA_MAPPING_VAR_NEW, TAB_DATA_PICKUP_LOCATION_VAR_LATER
    mappingSelectedFlow: TAB_DATA_MAPPING_VAR_NEW,
    // mappingSelectedObject will be set a value if user use "Add from saved"
    mappingSelectedObject: undefined,
    // for the check box show step mapping
    mappingShowStepMapping: true,
    // systemFileDetected will be updated when recheck the uploaded file:
    // - Uploaded new files
    // - Switch between tabs
    mappingSystemFileDetected: false,
  },
  {
    tabName: STEP_UPLOAD_TAB_USER_TEMPLATE,
    uploadedFiles: [],
    name: '',
    desc: '',
    // use for step select Pickup Contact/Location
    pickupLocationSelectedFlow: TAB_DATA_PICKUP_LOCATION_VAR_LATER,
    pickupLocationSelectedObject: undefined,
    // use for step select Mapping
    mappingSelectedFlow: TAB_DATA_MAPPING_VAR_NEW,
    mappingSelectedObject: undefined,
    mappingShowStepMapping: true,
    mappingSystemFileDetected: false,
  },
  {
    tabName: STEP_UPLOAD_TAB_ADVANCED,
    uploadedFiles: [],
    name: '',
    desc: '',
    // use for step select Pickup Contact/Location
    pickupLocationSelectedFlow: TAB_DATA_PICKUP_LOCATION_VAR_LATER,
    pickupLocationSelectedObject: undefined,
    // use for step select Mapping
    mappingSelectedFlow: TAB_DATA_MAPPING_VAR_NEW,
    mappingSelectedObject: undefined,
    mappingShowStepMapping: true,
    mappingSystemFileDetected: false,
    // changed is use for checking/keep user consolidates selectons on step assign(mapping)
    // true after change file
    // false after next to step 2
    changed: false,
  },
]

const stepUploadTabsDataSlice = createSlice({
  name: 'stepUploadTabsData',
  initialState,
  reducers: {
    updateTab: (state, action: PayloadAction<any>) => {
      const targetIndex =state.findIndex((item: any) => item.tabName === action.payload.tabName)
      state[targetIndex] = { ...state[targetIndex], ...action.payload.attrs }
    },
    removeFile: (state, action: PayloadAction<any>) => {
      const tab = _.find(state, { tabName: action.payload.tabName })
      _.remove(tab.uploadedFiles, { id: action.payload.id })
      _.assign(tab, { mappingSystemFileDetected: false })
   
    },
    removeSheet: (state, action: PayloadAction<any>) => {
      const tab = _.find(state, { tabName: action.payload.tabName })
      const file = _.find(tab.uploadedFiles, { id: action.payload.fileID })
      _.remove(file.sheets, { id: action.payload.id })
      if (_.isEmpty(file.sheets)) {
        _.remove(tab.uploadedFiles, { id: action.payload.fileID })
      }
    
    },
  },
  extraReducers: () => {},
})

export const stepUploadTabsDataActionsCreator = stepUploadTabsDataSlice.actions

export default stepUploadTabsDataSlice
