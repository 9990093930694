// PREFERENCE DRIVERS TAB CONST
export const UPDATE_PREFERENCE_DRIVERS = 'UPDATE_PREFERENCE_DRIVERS'
export const UPDATE_AVAILABLE_DRIVERS = 'UPDATE_AVAILABLE_DRIVERS'
export const FAVORITE_DRIVER = 'FAVORITE_DRIVER'
export const UNFAVORITE_DRIVER = 'UNFAVORITE_DRIVER'
export const UPDATE_BANNED_DRIVERS = 'UPDATE_BANNED_DRIVERS'
export const UPDATE_SELECTED_DRIVER_IDS = 'UPDATE_SELECTED_DRIVER_IDS'
export const DRIVERS_PER_PAGE = 10
export const DRIVER_STATUS = {
  auto_deactive: 'auto_deactive',
  cs_suspends_driver: 'cs_suspends_driver',
  inactive: 'inactive',
  red_card: 'red_card',
  suspend: 'suspend',
  suspend_competitor_app: 'suspend_competitor_app',
  suspend_receive_booking: 'suspend_receive_booking',
  suspend_security_bond: 'suspend_security_bond',
  suspend_police_clearance: 'suspend_police_clearance',
  suspend_sticker: 'suspend_sticker',
}
export const DRIVER_STATUS_KEY = [
  DRIVER_STATUS.inactive,
  DRIVER_STATUS.suspend,
  DRIVER_STATUS.suspend_receive_booking,
  DRIVER_STATUS.cs_suspends_driver,
  DRIVER_STATUS.auto_deactive,
  DRIVER_STATUS.suspend_security_bond,
  DRIVER_STATUS.suspend_competitor_app,
  DRIVER_STATUS.red_card,
  DRIVER_STATUS.suspend_police_clearance,
  DRIVER_STATUS.suspend_sticker,
]
