import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch, useAppSelector } from 'store/store'
import { EDIT_PHONE_NUMBER_MODAL, VERIFICATION_CODE_MODAL } from 'components/new_booking/guest_flow/constants'
import { ContentWrapper } from './SWelcomeRevolution'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'

const WelcomeRevolution = ({ isNoStep3 }: { isNoStep3?: boolean }) => {
  const { t } = useTranslation()
  const currentCustomer = useAppSelector((state) => state.currentCustomer)
  const dispatch = useAppDispatch()

  return (
    <ModalNormal
      topLevel={!isNoStep3}
      closeVariant="g1"
      customFixedWidth={'632px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <ContentWrapper>
        <STypography fw={500} mb={20} color={theme.colors.green[1]} fs={17}>
          {t('webapp.label.welcome_to_revolution')}
        </STypography>
        <STypography
          align="center"
          lh={19.5}
          dangerouslySetInnerHTML={{
            __html: t('webapp.label.send_sms_verification_code', { phone: currentCustomer.phone }),
          }}
          fs={15}
        />

        <SFlex full mt={20}>
          <SButton
            w="50%"
            h="40px"
            mr={10}
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(EDIT_PHONE_NUMBER_MODAL))}
            variant="w2"
            fw={500}
            type="button"
          >
            {t('webapp.label.edit_number')}
          </SButton>
          <SButton
            w="50%"
            h="40px"
            fw={500}
            ml={10}
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(VERIFICATION_CODE_MODAL))}
            variant="g2"
            br={`1px solid ${theme.colors.green[1]}`}
            type="button"
          >
            {t('webapp.action.ok')}
          </SButton>
        </SFlex>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default WelcomeRevolution
