import styled, { css } from 'styled-components'
interface Props {
  flexB?: number
  center?: boolean
  full?: boolean
  col?: boolean
  between?: boolean
  jusend?: boolean
  fEnd?: boolean
  iend?: boolean
  fStart?: boolean
  icenter?: boolean
  algin?: boolean
  around?: boolean
  wraper?: boolean
  position?: string
  mt?: number
  mr?: number
  mb?: number
  p?: number
  pl?: number
  pt?: number
  pr?: number
  pb?: number
  ml?: number
  m?: number
  h100?: boolean
  bgColor?: string
}

const SFlex = styled.div<Props>`
  display: flex;
  position: ${({ position }) => position};
  ${({ m, mt, mr, mb, ml }) =>
    css`
      margin: ${m ? `${m}px` : `${mt || 0}px ${mr || 0}px ${mb || 0}px ${ml || 0}px`};
    `}
  ${({ p, pt, pr, pb, pl }) =>
    (p || pt || pr || pb || pl) &&
    css`
      padding: ${p ? `${p}px` : `${pt || 0}px ${pr || 0}px ${pb || 0}px ${pl || 0}px`};
    `}
  background-color:${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
  ${({ h100 }) =>
    h100 &&
    css`
      height: 100%;
    `};
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      align-items: center;
    `};
  ${({ jusend }) =>
    jusend &&
    css`
      justify-content: flex-end;
      align-items: center;
    `};
  ${({ iend }) =>
    iend &&
    css`
      align-items: flex-end;
    `};
  ${({ icenter }) =>
    icenter &&
    css`
      align-items: center;
    `};
  ${({ fEnd }) =>
    fEnd &&
    css`
      justify-content: flex-end;
      align-items: flex-end;
    `};
  ${({ algin }) =>
    algin &&
    css`
      align-items: center;
    `};
  ${({ full }) =>
    full &&
    css`
      width: 100%;
      height: 100%;
    `};
  ${({ fStart }) =>
    fStart &&
    css`
      justify-content: flex-start;
    `};
  ${({ col }) =>
    col &&
    css`
      flex-direction: column;
    `};
  ${({ between }) =>
    between &&
    css`
      justify-content: space-between;
    `};
  ${({ around }) =>
    around &&
    css`
      justify-content: space-around;
    `};
  ${({ wraper }) =>
    wraper &&
    css`
      flex-wrap: wrap;
    `};
`
export default SFlex
