export const AF_TYPE_FULL_LOAD = 'Full Load';
export const BOOKING1_NEXT_PRESS = 'Booking1_NextPress';
export const BOOKING2_SERVICE_PRESS = 'Booking2_ServicePress';
export const SIGN_OUT = 'SignOut';
export const SIGN_IN = 'SignIn';
export const CANCEL_BOOKING_PRESS_BEFORE_DRIVER_ACCEPT = 'CancelBookingPressBeforeDriverAccept';
export const DROP_OFF_PRESS = 'DropoffPress';
export const PICKUP_PRESS = 'PickupPress';
export const RATING_DRIVER_SUBMIT = 'RatingDriverSubmit';
export const SEARCH_DRIVER_BY_REFERRAL_ID_CLICK = 'SearchDriverByReferralID_Click';
export const BOOKING3_CONFIRM_PRESS = 'Booking3_ConfirmPress';
export const CANCEL_BOOKING_PRESS_AFTER_DRIVER_ACCEPT = 'CancelBookingPressAfterDriverAccept';
export const ADD_DROP_OFF_PRESS = 'AddDropoffPress';
export const SIGN_IN_AFTER_BOOKING = 'SignInAfterBooking';
export const BOOKING3_CONFIRM_PRESS_BEFORE_SIGNED_IN = 'Booking3_ConfirmPressBeforeSignedIn';
export const BOOKING3_CONFIRM_PRESS_FAILED = 'Booking3_ConfirmPressFailed';
export const ARR_ID_NONE_LOGIN = ['000000', 'NONE_LOGIN'];
