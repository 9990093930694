import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const Step4AccountDeleted = () => {
  const { t } = useTranslation()

  return (
    <div className="step-4-account-deleted">
      <div>
        {t('webapp.deletion_account.account_deleted')}
      </div>
    </div>
  )
}

Step4AccountDeleted.propTypes = {
  onUpdateDeleteAccount: PropTypes.func.isRequired,
  deletionAccount: PropTypes.object.isRequired
}

export default Step4AccountDeleted
