import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STEP_UPLOAD_TAB_OUR_TEMPLATE } from 'constants/ezSpreadSheetConstants'
const initialState = STEP_UPLOAD_TAB_OUR_TEMPLATE

const currentTabEzsheetSlice = createSlice({
  name: 'currentTab',
  initialState,
  reducers: {
    currentTabUpdate: (state: any, action: PayloadAction<any>) => {
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentTabEzsheetActionsCreator = currentTabEzsheetSlice.actions

export default currentTabEzsheetSlice
