import React from 'react'
import PropTypes from 'prop-types'
import { WARNING_YELLOW } from 'constants/imageConstants'
import { useTranslation } from 'react-i18next'

const PopupWarning = ({ popup, onClosePopup }) => {
  const { t } = useTranslation()
  const { title, content } = popup

  return (
    <div className="vertical-scroll Modal visible popup-warning">
      <div className="Normal-Booking">
        <div className="Box">
          <div className="Box-Icon">
            <div className="Logo"><img src={WARNING_YELLOW} alt="warning yellow" /></div>
          </div>
          <div className="Box-Content">
            <div className="Normal Normal-Hyperlink-Custom">
              <h5 className="Normal-Booking-Title mb10">{title}</h5>
            </div>
            <div>
              <p className="center mt10">{content}</p>
            </div>
          </div>
        </div>
        <div className="Normal-Booking-Actions Custom">
          <a className="White no-hover cur-pointer" onClick={() => onClosePopup()}>
            {t('webapp.new_booking.back')}
          </a>
        </div>
      </div>
    </div>
  )
}

PopupWarning.propTypes = {
  onUpdateDeleteAccount: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired
}

export default PopupWarning
