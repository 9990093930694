/* eslint-disable import/prefer-default-export */

import _ from 'lodash'
import { addLocation, setUpForNewWhenChangeTimeType } from './locationActionCreators'
import { update } from './roundTripDiscountActionCreators'
import {
  NOW,
  SCHEDULE,
  FULL_DAY,
  QUICK_CHOICE,
} from 'constants/bookingConstants'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { autoSwitchLongHaulActionsCreator } from 'store/toolkit/newBooking/autoSwitchLongHaul.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'

export const changeTimeType = timeType => timeTypeActionsCreator.changeTimeType(timeType)

export const autoSwitchLongHaul = switchLongHaul => autoSwitchLongHaulActionsCreator.autoSwitchLongHaul(switchLongHaul)

export const showDatePicker = value => showDatePickerActionsCreator.showDatePicker(value)

export const changePickupTime = pickupTime => pickupTimeActionsCreator.changePickupTime(pickupTime)

export const changeTimeTypeUI = timeType => timeTypeUIActionsCreator.changeTimeTypeUI(timeType)

const actionBeforeChangeTimeType = timeType => (dispatch, getState) => {
  switch (timeType) {
    case FULL_DAY: {
      dispatch(setUpForNewWhenChangeTimeType(timeType))
      break
    }
    case SCHEDULE: {
      dispatch(setUpForNewWhenChangeTimeType(timeType))
      break
    }
    case NOW: {
      dispatch(setUpForNewWhenChangeTimeType(timeType))
      break
    }
    default: {
      if (getState().locations.length < 2) {
        dispatch(addLocation())
      }
      break
    }
  }
}

export const actionChangeTimeType = timeType => (dispatch, getState) => {
  Promise.resolve(
    dispatch(actionBeforeChangeTimeType(timeType))
  ).then(() => {
    dispatch(timeTypeActionsCreator.changeTimeType(timeType))
    if (!_.includes([NOW, SCHEDULE, QUICK_CHOICE], timeType) && getState().roundTripDiscount) {
      dispatch(update(false, true))
    }
    dispatch(dataChangesActionsCreator.updateDataChange(true))
  })
}
