import { Controller, get, useFormContext } from 'react-hook-form'

import StorageKeys from 'constants/storage-keys'
import InputPhoneNumber from 'components/common/InputPhoneNumber'
import STypography from '../../STypography'
import { InputPhoneWrapper } from './SInputPhone'
import { theme } from '../../STheme'

interface IInputPhone {
  name: string
  label: any
}

const SInputPhone = ({
  name,
  label,
}: IInputPhone) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const countryCode = localStorage.getItem(StorageKeys.COUNTRY_CODE) || 'id'
  const error = get(errors, `${name}.message`)
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <InputPhoneWrapper error={!!error?.length}>
          {label && (
            <STypography fs={15} mb={5}>
              {label}
            </STypography>
          )}
          <InputPhoneNumber
            isWebApp
            closestElement="signup-number"
            telInputProps={{ tabIndex: 1 }}
            phoneNumber={value || ''}
            countryCode={countryCode}
            phoneField="customer[phone]"
            countryCodeField="customer[country_code]"
            onPhoneChange={onChange}
            onPhoneBlur={onChange}
            onPhoneFocus={onChange}
          />
          {error?.length > 0 && (
            <STypography mt={5} variant="e4" color={theme.colors.red[1]}>
              {error}
            </STypography>
          )}
        </InputPhoneWrapper>
      )}
    />
  )
}

export default SInputPhone
