import React from 'react'
import Modal from 'components/common/Modal'
import { BP_ACCOUNT } from 'constants/imageConstants'
import { useTranslation } from 'react-i18next'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import STypography from 'components/common/Styled/STypography'
import SButton from 'components/common/Styled/SButton'
import { ContentWrapper } from './Sindex'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { useAppDispatch } from 'store/store'

const CompanyApproved: React.FC<{
  onCheckBackToStep1: () => void
}> = ({ onCheckBackToStep1 }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Modal
      isOpen
      title={t('popup.company_approved.title')}
      customFixedWidth={'440px'}
      imageTitle={BP_ACCOUNT}
      onClickClose={() => {
        dispatch(accountManageActions.updateModalAccountManage(''))
        onCheckBackToStep1()
      }}
      modalAction={
        <>
          <SFlex mt={10} between>
            <SButton
              h="36px"
              fw={500}
              w="100%"
              variant="g3"
              onClick={() => {
                dispatch(accountManageActions.updateModalAccountManage(''))
                onCheckBackToStep1()
              }}
              type="button"
            >
              {t('webapp.booking.ok')}
            </SButton>
          </SFlex>
        </>
      }
    >
      <ContentWrapper className="s-scroll">
        <STypography align={'center'} fs={15} mb={5} cVar="w1">
          {t('popup.company_approved.content')}
        </STypography>
      </ContentWrapper>
    </Modal>
  )
}

export default CompanyApproved
