import { useEffect } from 'react'

const useOutsideHandler = (els, handler) => {
  useEffect(() => {
    const eventHandler = (e) => {
      const insideEls = els.filter(el => el.current && el.current?.contains(e.target))
      if (insideEls.length) return

      handler(e)
    }
    document.addEventListener('mousedown', eventHandler)

    /* eslint-disable consistent-return */
    return () => {
      document.removeEventListener('mousedown', eventHandler)
    }
  }, [])
}

export default useOutsideHandler
