import { ISBase } from 'components/common/Styled/SCommon/ICommon'
import { styled } from 'components/common/Styled/STheme'

const ContentWrapper = styled.div<ISBase>`
  background-color: ${({ theme }) => theme.colors.white[1]};
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  img {
    border-radius: 6px;
  }
  .info {
    flex: 1;
    padding-left: 10px;
    padding-right: 15px;
  }
  .account-container:hover {
    background: #d0d0d0;
    cursor: pointer;
  }
`

const BlockCompanyWrapper = styled.div<ISBase>`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`
const ModalWrapper = styled.div<ISBase>`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`
export { ContentWrapper, ModalWrapper, BlockCompanyWrapper }
