import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//UTILS
import { Utils } from 'utils/Utils'
import _ from 'lodash'
import I18n from 'i18n/i18n'

class CustomReimbursementItem extends React.PureComponent {
  static checkAndCreatePopupkey(reimbursementCustom) {
    if (reimbursementCustom.popups && reimbursementCustom.popups.popup_items
      && reimbursementCustom.popups.popup_items.length && reimbursementCustom.popups.popup_type
    ) {
      return reimbursementCustom.popups.popup_type
    }
    return null
  }

  checkRenderBox() {
    const { reimbursementCustom } = this.props
    if (reimbursementCustom.allow_user_to_enter_amount) {
      return this.renderInputBox()
    }

    if (reimbursementCustom.unit > 1) {
      return this.renderSelectbox()
    }

    return this.renderCheckbox()
  }

  handleInputAmout(e) {
    const { handleOnChangeCustom, reimbursementCustom } = this.props
    let value = e.target.value
    if (!_.isUndefined(value)) {
      const specialCharacters = /\D/gi
      value = value.replace(specialCharacters, '')
      const updateValue = {}
      if (!value || value === '0') {
        updateValue.user_input_amount = ''
        updateValue.selected_amount = 0
      } else {
        updateValue.user_input_amount = value
        updateValue.selected_amount = 1
        const maxCap = Number(_.get(reimbursementCustom, 'customer_maximum_cap_per_location', 0))
        if (maxCap > 0 && Number(value) > maxCap) {
          updateValue.invalid = true
        } else {
          updateValue.invalid = false
        }
      }
      handleOnChangeCustom(reimbursementCustom.id, { ...updateValue })
    }
  }

  renderCheckbox() {
    const {
      handleOnChangeCustom, reimbursementCustom
    } = this.props

    return (
      <div className="block-item-action">
        <div
          className={`Overlay-Checkbox With-Icon Green-Checkbox ${!reimbursementCustom.can_toggle ? 'Disable' : ''}`}
        >
          <input
            type="checkbox"
            name={`booking[${_.snakeCase(reimbursementCustom.name)}]`}
            id={`booking_${_.snakeCase(reimbursementCustom.name)}`}
            checked={reimbursementCustom.selected_amount === 1}
            onChange={event => (
              handleOnChangeCustom(reimbursementCustom.id, { selected_amount: event.target.checked ? 1 : 0 })
            )}
          />
          <label>
            <i className="b material-icons Icon">
              check
            </i>
          </label>
        </div>
      </div>
    )
  }

  renderInputBox() {
    const { reimbursementCustom, extraInfos, handleOnBlurCustom  } = this.props
    const maxCap = Number(_.get(reimbursementCustom, 'customer_maximum_cap_per_location', 0))
    const userInputAmount = Number(_.get(reimbursementCustom, 'user_input_amount', 0))
    const isLargerMaxCap = maxCap > 0 && userInputAmount > maxCap
    return (
      <div className="w100">
        <div className="mt5">
          <div className={`Input ${isLargerMaxCap && 'error mar2'}`}>
            <input
              className={reimbursementCustom.can_toggle ? 'enter-amount-reimbursement' : ''}
              name={`booking[${_.snakeCase(reimbursementCustom.name)}]`}
              placeholder={I18n.t('webapp.booking.enter_amount')}
              value={reimbursementCustom.user_input_amount
                ? Utils.formatNumberByCountry(reimbursementCustom.user_input_amount, extraInfos.country_language) : ''
              }
              onChange={e => this.handleInputAmout(e)}
              onBlur={handleOnBlurCustom}
              maxLength={9}
              disabled={!reimbursementCustom.can_toggle}
            />
          </div>
          {isLargerMaxCap && (
            <span className="message-error red">
              {I18n.t('webapp.adding_fund.error.maximum_is', { value: Utils.formatFee(maxCap, extraInfos.country_language, extraInfos.currency, '') })}
            </span>
          )}
        </div>
      </div>
    )
  }

  renderSelectbox() {
    const { handleOnChangeCustom, reimbursementCustom } = this.props
    return (
      <div className="block-item-action">
        <select
          name={`booking[${_.snakeCase(reimbursementCustom.name)}]`}
          value={reimbursementCustom.selected_amount}
          disabled={!reimbursementCustom.can_toggle}
          onChange={e => handleOnChangeCustom(reimbursementCustom.id, { selected_amount: e.target.value })}
        >
          <option>
            0
          </option>
          {_.range(reimbursementCustom.unit).map((value) => {
            const v = value + 1
            return (
              <option value={v} key={v}>
                {v}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderFee() {
    const { extraInfos, reimbursementCustom } = this.props
    const totalPrice = reimbursementCustom.amount * reimbursementCustom.selected_amount

    return Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, '')
  }

  render() {
    const {
      handlePopupEye, reimbursementCustom
    } = this.props

    if (reimbursementCustom.unit === 0 || reimbursementCustom.hide_from_more_options) {
      return null
    }
    const popupKey = CustomReimbursementItem.checkAndCreatePopupkey(reimbursementCustom)
    return (
      <div
        className={`
          block-item No-Border-Bottom
          ${reimbursementCustom.allow_user_to_enter_amount ? ' block-sub-item-flex-start flex-column' : ''}
        `}
        key={`block-reimbursement-${reimbursementCustom.id}`}
      >
        <label className="default-color default-font pr10">
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title mb3">
              {reimbursementCustom.name}
              {popupKey && (
                <span className="step-2-eye" onClick={() => handlePopupEye(popupKey, reimbursementCustom.id)}>
                  <i className="s material-icons Icon">
                    visibility
                  </i>
                </span>
              )}
            </div>

            <div className={`TitleSubtitle-subtitle Gray-text-important ${reimbursementCustom.sub_text ? 'mb5' : ''}`}>
              {reimbursementCustom.sub_text}
            </div>

            {!reimbursementCustom.allow_user_to_enter_amount && (
              <div className="TitleSubtitle-subtitle">
                {this.renderFee()}
              </div>
            )}
          </div>
        </label>
        {this.checkRenderBox()}
      </div>
    )
  }
}

CustomReimbursementItem.propTypes = {
  reimbursementCustom: PropTypes.shape({}).isRequired,
  handleOnChangeCustom: PropTypes.func.isRequired,
  handleOnBlurCustom: PropTypes.func.isRequired,
  handlePopupEye: PropTypes.func.isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => ({
  extraInfos: state.extraInfos,
})

export default connect(mapStateToProps, null)(CustomReimbursementItem)
