import { createGlobalStyle } from 'styled-components'

export const SGlobal = createGlobalStyle<{}>`
  .l-box {
    cursor: pointer;
  }
  .s-scroll {
  overflow:auto;
  &::-webkit-scrollbar-track {
    background-color: #d7edd7;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #0e730f;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0e730f;
    border-radius: 0;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0e730f;
  }
  }
  .social-login {
    > div {
      :first-child {
        margin-right: 10px;
      }
      :last-child {
        margin-left: 10px;
      }
      width: 50%
    }
  }
`
