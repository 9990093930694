import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import FormContainer from 'components/common/FormInput'
import { useForm } from 'react-hook-form'
import SInput from 'components/common/Styled/Form/SInput'
import CommonUtils from 'utils/common'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import { ContentWrapper, IconModal } from './Sindex'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import { useAppDispatch, useAppSelector } from 'store/store'
import { MergeInfo } from '..'
import accountAction from 'store/actions/common/accountAction'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
interface IDataForm extends Omit<MergeInfo, 'countryCode' | 'socialToken' | 'provider'> {}
const MergeAccount: React.FC<{
  dataMergeSocial: MergeInfo
  onCheckRedirectPage: () => void
  isNoStep3?: boolean
}> = ({ dataMergeSocial, onCheckRedirectPage, isNoStep3 }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const methods = useForm({
    defaultValues: {
      email: dataMergeSocial.email,
      phone: dataMergeSocial.phone,
      firstName: dataMergeSocial.firstName,
      lastName: dataMergeSocial.lastName,
      businessName: dataMergeSocial.businessName,
      referredBy: dataMergeSocial.referredBy,
    },
  })
  const extraInfos = useAppSelector((state) => state.extraInfos)
  const handleSubmit = async (e: IDataForm) => {
    const params = {
      country_code: dataMergeSocial.countryCode,
      email: e.email,
      phone: e.phone,
      first_name: e.firstName,
      last_name: e.lastName,
      business_name: e.businessName || '',
      referred_by: e.referredBy || '',
      access_token: dataMergeSocial.socialToken,
      provider: dataMergeSocial.provider,
    }
    accountAction.signUp(params)(dispatch)
    onCheckRedirectPage()
    dispatch(accountManageActions.updateModalAccountManage(''))
  }
  const LOGO_ICON = CommonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  
  return (
    <ModalNormal topLevel={!isNoStep3} closeVariant="g1" customFixedWidth={'420px'} backgroundColor={theme.colors.white[1]} isOpen>
      <ContentWrapper>
        <IconModal>
          <img src={LOGO_ICON} width={75} alt='LOGO_ICON' />
        </IconModal>
        <STypography align="center" fw={700} mb={20} color={theme.colors.green[1]} fs={19}>
          {t('devise_template.already_a_member', { app_name: CommonUtils.appName() })}
        </STypography>
        <FormContainer methods={methods} onSubmit={(e) => handleSubmit(e)}>
          <SInput label={t('devise_template.email_address')} disabled name="email" />
          <SInput label={t('devise_template.phone_number')} disabled name="phone" />
          <SInput label={t('devise_template.first_name')} disabled name="firstName" />
          <SInput label={t('devise_template.last_name')} disabled name="lastName" />
          <SInput label={t('devise_template.business_name')} name="businessName" />
          <SInput label={t('devise_template.referred_by')} name="referredBy" />

          <SButton
            mt={20}
            w="100%"
            h="36px"
            variant='g2'
            type="submit"
          >
            {t('devise_template.create_my_account')}
          </SButton>
        </FormContainer>
        <SFlex mt={20} center>
          <STypography
            fs={13}
            mt={5}
            className="term-link"
            dangerouslySetInnerHTML={{
              __html: t('devise_template.by_clicking', {
                term_link: extraInfos?.tc_link?.non_bp || '',
                privacy_link: extraInfos.privacy_policy_link || '',
                app_name: CommonUtils.appName(),
              }),
            }}
          />
        </SFlex>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default MergeAccount
