import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'
import _, { assign, filter, find, findIndex, forEach, get, isEmpty, isUndefined, remove, size } from 'lodash'
import Utils from 'utils/Utils'

const initialState: any = []

const resetLocationBeforeOptimize = (booking:any, locations:any) => {
  // eslint-disable-next-line no-param-reassign
  booking.locationBeforeOptimize = locations
}

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    addBookingMultiple: (state, action: PayloadAction<any>) => {
      state.push(action.payload)
    },
    deleteBooking: (state, action: PayloadAction<any>) => {
      remove(state, { id: action.payload.id })
    },
    updateBooking: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(state, (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        state[index] = {
          ...state[index],
          ...action.payload.attrs,
        }
      }
    },
    updateBookings: (state, action: PayloadAction<any>) => action.payload,
    addLocation: (state, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        booking.locations_attributes.push(action.payload.location)
        booking.dataChange = action.payload.dataChange
        if (booking.isOptimized) {
          resetLocationBeforeOptimize(booking, booking.locations_attributes)
        }
      }
    },
    insertLocation: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const newLocations = booking.locations_attributes.slice(0)
        const position = isUndefined(action.payload.location.lat) ? size(newLocations) - 1 : size(newLocations)
        newLocations.splice(position, 0, action.payload.location)

        booking.locations_attributes = newLocations
        booking.dataChange = action.payload.dataChange
        if (booking.isOptimized) {
          resetLocationBeforeOptimize(booking, booking.locations_attributes)
        }

      }
    },
    updateLocations: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking:any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        booking.locations_attributes = action.payload.locations
        if (booking.isOptimized) {
          resetLocationBeforeOptimize(booking, booking.locations_attributes)
        }
      }
    },
    updateLocation: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const locations = _.cloneDeep(booking.locations_attributes)
        const locationIndex = findIndex(locations, { id: action.payload.id })
        if (locationIndex > -1) {
          const location = assign({}, locations[locationIndex])
          // if change payer
          if (action.payload.attrs.is_payer) {
            forEach(locations, (l) => {
              assign(l, { is_payer: false })
            })
          }
          locations[locationIndex] = { ...location, ...action.payload.attrs }
          booking.locations_attributes = locations

          if (booking.isOptimized) {
            resetLocationBeforeOptimize(booking, booking.locations_attributes)
          }
        }
      }
    },
    deleteLocation: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        remove(booking.locations_attributes, { id: action.payload.id })

        if (booking.locationBeforeOptimize) {
          remove(booking.locationBeforeOptimize, { id: action.payload.id })
        }
      }
    },
    receiveExtraServices: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const preTimeType = _.get(action.payload, 'extraServices.preExtraServices.timeTypeOfExtraServices')
        const isChangedTimeType = !_.isUndefined(preTimeType) && preTimeType !== action.payload.timeType
        const isChangeTimeTypeBookAgain = booking.time_type !== booking.booking_time_type && booking.booking_time_type
        const isFirstGetExtraService = !get(action.payload, 'extraServices.preExtraServices.isGetExtraService')
        const isInEditMode = action.payload.bookAgain || action.payload.useDraftData
        const isBookAgainCase = isInEditMode && isFirstGetExtraService
        const originalBookings = _.get(action.payload, 'extraServices.originalBookings', [])
        const originalBooking = _.find(originalBookings,
          bookingItem => +bookingItem.id === +action.payload.bookingID)
        const isVehicleTypeChanged = action.payload.extraServices.isVehicleTypeChanged

        // companySettings
        const preCompanySettings = !isBookAgainCase ? get(action.payload, 'extraServices.preExtraServices.companySettings') : {}
        const curCompanySettings = get(action.payload, 'extraServices.company_settings')
        const preVehicleTypeSettings = get(action.payload, 'extraServices.preExtraServices.vehicleTypeSettings')
        const companySettings = Utils.keepDataStaticReimbursement(
          preCompanySettings,
          curCompanySettings,
          preVehicleTypeSettings,
          isChangedTimeType,
          isVehicleTypeChanged
        )

        // flow book again and load draft
        if (
          isBookAgainCase &&
          isEmpty(preCompanySettings) && // only run once (init)
          !isEmpty(curCompanySettings) &&
          !isChangeTimeTypeBookAgain &&
          !isVehicleTypeChanged
        ) {
          assign(companySettings, {
            allow_parking_fees: booking.allow_parking_fees,
            allow_tolls_fees: booking.allow_tolls_fees,
            allow_waiting_time_fees: booking.allow_waiting_time_fees,
          })
        }

        // customReimbursements
        const preCustomReimbursements = get(action.payload, 'extraServices.preExtraServices.customReimbursements')
        const getValidIsChangeTimeType = () => {
          let result = false
          if (isBookAgainCase && !_.isEmpty(originalBooking)) result = action.payload.timeType !== originalBooking.time_type
          else result = preTimeType && preTimeType !== action.payload.timeType
          return result
        }
        const isChangeTimeType = getValidIsChangeTimeType()
        const currentCustomReimbursements = get(action.payload, 'extraServices.custom_reimbursements')

        // keep data when step 2 => step 1 => step 2
        let customReimbursements = Utils.handleAndKeepDataCusomReimbursement(
          preCustomReimbursements,
          currentCustomReimbursements,
          isChangeTimeType,
          isVehicleTypeChanged
        )

        // flow book again and load draft
        const customReimbursementsOfBookAgain = get(booking, 'custom_reimbursements', []) || []
        if (
          isInEditMode &&
          isFirstGetExtraService && // only run once (init)
          !isEmpty(currentCustomReimbursements) // have the custom reimbursement
        ) {
          customReimbursements = customReimbursements.map((reimbursement) => {
            const reimbursementOfBookAgain = customReimbursementsOfBookAgain.find(
              (item: any) => item.vehicle_type_reimbursement_id === reimbursement.id || item.id === reimbursement.id
            )

            const isOldBooking = !isChangeTimeTypeBookAgain && !isVehicleTypeChanged
            if (
              !isOldBooking
              && reimbursement.check_by_default
              && !reimbursement.allow_user_to_enter_amount
            ) {
              return reimbursement
            }

            // check case, the Customer select unit > new setting unit

            const validReimbursement = !isEmpty(reimbursementOfBookAgain)
            const { oldSelectedAmount, oldUserInputAmount } = Utils.buildCustomReimbursementsOfBookAgain(
              customReimbursementsOfBookAgain,
              reimbursementOfBookAgain,
              reimbursement,
              validReimbursement
            )
            return {
              ...reimbursement,
              ...(reimbursement.allow_user_to_enter_amount ? { user_input_amount: oldUserInputAmount } : {}),
              selected_amount: oldSelectedAmount,
            }
          })
        }

        // fullDayPricing
        let fullDayPricing = assign(action.payload.extraServices.full_day_pricing, { selected_amount: 1 })
        const prevFullDayPricing = action.payload.extraServices?.preExtraServices?.fullDayPricing
        if (action.payload.bookAgain) {
          fullDayPricing = assign(action.payload.extraServices.full_day_pricing, {
            selected_amount: booking.full_day_selected_amount || 1
          })
        } else if (action.payload.useDraftData) {
          fullDayPricing = assign(action.payload.extraServices.full_day_pricing, {
            selected_amount: booking.fullday_selected_amount || 1 
          })
        } else if (!isFirstGetExtraService && !isChangeTimeType && !_.isEmpty(prevFullDayPricing)) {
          fullDayPricing = prevFullDayPricing
        }
        // extraRequirements
        const extraRequirements = Utils.handleAndKeepDataExtraRequirements(
          booking, action.payload, isChangeTimeType, isBookAgainCase, isVehicleTypeChanged
        )
        // extraRequirementsNegativePosition
        const extraRequirementsNegativePosition = action.payload.extraServices.extra_requirements_negative_position.data
        if (!isEmpty(extraRequirementsNegativePosition)) {
          forEach(extraRequirementsNegativePosition, (extraRequirement) => {
            if (!isChangeTimeType && !isFirstGetExtraService) {
              const extraRequirementTemp = _.find(
                action.payload.extraServices?.preExtraServices?.extraRequirementsNegativePosition,
                { id: extraRequirement.id }
              )
              if (!_.isEmpty(extraRequirementTemp)) {
                assign(extraRequirement, {
                  selected: extraRequirementTemp.selected,
                  selected_amount: extraRequirementTemp.selected_amount,
                  selectedPricing: undefined,
                })
              }
            // we do NOT apply check_by_default for by_options extra requirements
          } else if (extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
            && !isUndefined(extraRequirement.pricings)
            ) {
              const allowNone = Utils.checkNonePricing(
                action.payload.timeType,
                extraRequirement.allow_none_option_pricing_list
              )
              assign(extraRequirement, {
                selected: !allowNone,
                selected_amount: allowNone ? 0 : 1,
                selectedPricing: extraRequirement.pricings[0],
              })
            } else if (isInEditMode) {
              const extraRequirementTemp = find(booking.booking_extra_requirements_negative_position, {
                id: extraRequirement.id,
              })
              if (!isEmpty(extraRequirementTemp)) {
                assign(extraRequirement, {
                  selected: extraRequirementTemp.selected,
                  selected_amount: extraRequirementTemp.selected_amount,
                  selectedPricing: undefined,
                })
              } else {
                assign(extraRequirement, {
                  selected_amount: 0,
                  selected: false,
                  selectedPricing: undefined,
                })
              }
            } else {
              assign(extraRequirement, {
                selected: extraRequirement.check_by_default,
                selected_amount: extraRequirement.check_by_default ? 1 : 0,
                selectedPricing: undefined,
              })
            }
          })
        }
        // vehicleTypeBadges
        let vehicleTypeBadges = action.payload.extraServices.vehicle_type_badges.data
        const preVehicleTypeBadges = action.payload.extraServices?.preExtraServices?.vehicleTypeBadges
        if (!isVehicleTypeChanged && !isEmpty(preVehicleTypeBadges)) {
          vehicleTypeBadges = preVehicleTypeBadges
        } else if (!isEmpty(vehicleTypeBadges)) {
          forEach(vehicleTypeBadges, (badge) => {
            if (isInEditMode) {
              const badgeTemp = find(booking.booking_badges, {
                badgeable_relation_id: badge.id,
                badgeable_relation_type: 'VehicleTypeBadge',
              })
              if (!isEmpty(badgeTemp)) {
                assign(badge, {
                  selected: true,
                  badgeable_relation_type: 'VehicleTypeBadge',
                  selected_amount: badgeTemp.selected_amount,
                })
              } else {
                assign(badge, { selected: false, badgeable_relation_type: 'VehicleTypeBadge' })
              }
            } else {
              assign(badge, { selected: false, badgeable_relation_type: 'VehicleTypeBadge' })
            }
          })
        }
        // companyBadges
        const companyBadges = action.payload.extraServices.company_badges.data
        if (!isEmpty(companyBadges)) {
          forEach(companyBadges, (badge) => {
            if (isInEditMode) {
              const badgeTemp = find(booking.booking_badges, {
                badgeable_relation_id: badge.id,
                badgeable_relation_type: 'CompanyBadge',
              })
              if (!isEmpty(badgeTemp)) {
                assign(badge, {
                  selected: true,
                  badgeable_relation_type: 'CompanyBadge',
                  selected_amount: badgeTemp.selected_amount,
                })
              } else {
                assign(badge, { selected: false, badgeable_relation_type: 'CompanyBadge' })
              }
            } else {
              assign(badge, {
                selected: badge.check_by_default,
                badgeable_relation_type: 'CompanyBadge',
                selected_amount: badge.check_by_default ? 1 : 0,
              })
            }
          })
        }
        // VehicleType settings
        const vehicleTypeSettings = action.payload.extraServices.vehicle_type_settings

        // time type
        const timeTypeOfExtraServices = action.payload.timeType
        const isGetExtraService = true

        // assign
        booking.extraServices = {
          companySettings,
          fullDayPricing,
          extraRequirements,
          extraRequirementsNegativePosition,
          vehicleTypeBadges,
          companyBadges,
          vehicleTypeSettings,
          customReimbursements,
          timeTypeOfExtraServices,
          isGetExtraService,
          preExtraServicesAPIData: action.payload?.extraServices?.originalES,
          autoExpandReimbursement: action.payload?.extraServices?.auto_expand_more_options_reimbursement,
        }
      }
    },
    updateExtraRequirement: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]

        const extraRequirements = booking.extraServices.extraRequirements.map((item: any) => {
          if (item.id !== action.payload.id) {
            return item
          }

          return {
            ...item,
            ...action.payload.attrs,
          }
        })
        booking.extraServices.extraRequirements = extraRequirements
      }
    },
    updateSelectedExtraRequirementForPerLocation: (state, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]

        booking.extraServices.selectedExtraRequirementForPerLocation ={
          ...booking.extraServices.selectedExtraRequirementForPerLocation,
          ...action.payload.attrs
        }
      }
    },
    updateExtraRequirementNegativePosition: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const extraRequirementsNegativePosition = booking.extraServices.extraRequirementsNegativePosition.map(
          (item: any) => {
            if (item.id !== action.payload.id) {
              return item
            }
            return {
              ...item,
              ...action.payload.attrs,
            }
          }
        )
        booking.extraServices.extraRequirementsNegativePosition = extraRequirementsNegativePosition
      }
    },
    updateFullDayPricing: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        booking.extraServices.fullDayPricing = {
          ...booking.extraServices.fullDayPricing,
          ...action.payload.attrs,
        }
      }
    },
    updateBadge: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const indexService = booking.extraServices.vehicleTypeBadges.findIndex((item:any) => item.id === action.payload.id)
        if(indexService !== -1) {
          booking.extraServices.vehicleTypeBadges[indexService] = {
            ...booking.extraServices.vehicleTypeBadges[indexService],
            ...action.payload.attrs
          }
        }
      }
    },
    updateCompanyBadge: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const indexService = booking.extraServices.companyBadges.findIndex((item:any) => item.id === action.payload.id)
        if(indexService !== -1) {
          booking.extraServices.companyBadges[indexService] = {
            ...booking.extraServices.companyBadges[indexService],
            ...action.payload.attrs
          }
        }
      }
    },
    updateCompanySetting: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        booking.extraServices.companySettings = {
          ...booking.extraServices.companySettings,
          ...action.payload.attrs,
        }
        
      }
    },
    updateCustomReimbursement: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const indexService = booking.extraServices.customReimbursements.findIndex((item:any) => item.id === action.payload.id)
        if(indexService !== -1) {
          booking.extraServices.customReimbursements[indexService] = {
            ...booking.extraServices.customReimbursements[indexService],
            ...action.payload.attrs
          }
        }
      }
    },
    updateAttachment: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const attachmentIndex = booking.booking_attachments_attributes.findIndex( (item: any) => item.tmp_id === action.payload.id )
        if(attachmentIndex !== -1) {
          booking.booking_attachments_attributes[attachmentIndex] = {
            ...booking.booking_attachments_attributes[attachmentIndex],
            ...action.payload.attrs
          }
        }
      }
    },
    updateDocumentReturn: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        state[index].documentReturn = assign(booking.documentReturn, {
          is_show: booking.documentReturn ? booking.documentReturn.is_show : false,
          is_required: booking.documentReturn ? booking.documentReturn.is_required : false,
          invalidKeys: booking.documentReturn ? booking.documentReturn.invalidKeys : [],
        }, action.payload.attrs)
      }
    },
    updateTallyData: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(state, (booking: any) => +booking.id === +action.payload.bookingID)
      if (index !== -1) {
        state[index] = {
          ...state[index],
          ...action.payload.attrs,
        }

      }
    },
    setPrevSelectedServiceTypeIdAndVehicleTypeId: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        booking.preVehicleTypeId = action.payload.vehicleTypeId
      }
    },
    updateRequirementNotMetReason: (state, action: PayloadAction<any>) => {
      const booking = state.find((booking:any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10))
      if (!_.isEmpty(booking)) {
        booking.reasonReqNotMet = action.payload.payload
      }
    },
    receiveFavoriteDrivers: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const { drivers, recentDriverID, pagination, loadingMore } = action.payload
        const favoriteDrivers = filter(drivers, ({ id }) => id !== recentDriverID)
        const bookingFavoriteDrivers = loadingMore ? state[index].favoriteDrivers : []
        const recentDriver = find(drivers, (driver) => driver.id === recentDriverID)

        state[index].favoriteDrivers = [...bookingFavoriteDrivers, ...favoriteDrivers]
        state[index].pagination = pagination
        if (!loadingMore) {
          state[index].recentDriver = recentDriver || null
        }
      }
    },
    updatePopupIdBooking: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )

      if (index !== -1) {
        state[index].popupID = action.payload.popupID
      }
    },
    updateLocationId: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(
        state,
        (booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10)
      )
      if (index !== -1) {
        const booking = state[index]
        const { attrs } = action.payload
        const { lat, lng, name } = attrs
        const newValue = {
          isChanged: true,
          id: action.payload.id,
          attrs,
        }
        const defaultValue = {
          isChanged: false,
          id: '',
          attrs,
        }
        const locationIdChange = !isEmpty(name) && lat && lng ? newValue : defaultValue
        booking.locationIdChange = locationIdChange
      }
    },
    updateLazyAddressLocationMultiple: (state: any, action: PayloadAction<any>) => {
      const index = state.findIndex((booking: any) => parseInt(booking.id, 10) === parseInt(action.payload.bookingID, 10))
      if (index !== -1) {
        state[index].listErrorLazyAddress[action.payload.locationId] = action.payload.lazyAddressError
      }
    }
  },
  extraReducers: () => {},
})

export const bookingsActionsCreator = bookingsSlice.actions

export default bookingsSlice
