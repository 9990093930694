import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const bookingsCardSlice = createSlice({
  name: 'bookingsCard',
  initialState,
  reducers: {
    updateBookings: (state, action: PayloadAction<any>) => action.payload.bookings,
    updateBooking: (state, action: PayloadAction<any>) => {
      const index = state.findIndex((item: any) => parseInt(item.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        state[index] = {
          ...state[index],
          ...action.payload.attrs,
        }
      }
    },
    updateAttrsBookings: (state, action: PayloadAction<any>) => state.map((i:any) => ({ ...i, ...action.payload.attrs })),
  },
  extraReducers: () => {},
})

export const bookingsCardActionsCreators = bookingsCardSlice.actions

export default bookingsCardSlice
