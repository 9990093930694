import { filter } from 'lodash'
import apiClient from 'services/axiosApp'
import { getDeviceId } from 'utils/cookie'
const DiscountCodeAPI = {
  checkDiscountCode: async ({
    discountCode, companyID, serviceTypeID,
    locations, bookingID, callback
  }) => {
    let requestString = ''
    filter(locations, ({ lat, lng }) => (lat && lng)).forEach((location, index) => {
      const { lat, lng } = location
      requestString += `locations_attributes[][latitude]=${lat}&locations_attributes[][longitude]=${lng}`
      if (index !== locations.size - 1) {
        requestString += '&'
      }
    })

    if (bookingID && bookingID > 0) {
      requestString += `&booking_id=${bookingID}`
    }

    requestString += `&service_type_id=${serviceTypeID}`
    requestString += `&company_id=${companyID}`
    try {
      const res = await apiClient.get(`/api/v3/discounts/${encodeURIComponent(discountCode)}?${decodeURIComponent(requestString)}`, {
        headers: {
          'Device-Id': getDeviceId()
        }
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default DiscountCodeAPI
