import React from 'react'
import PropTypes from 'prop-types'
import { LOG_OUT_ICON_LOGO, LOG_OUT_ICON_LOGO_PH } from 'constants/imageConstants'
import I18n from 'i18n/i18n'
import { withNavigate } from 'hocs/withNavigate'
import CommonUtils from 'utils/common'
import useEscapeEvent from 'hooks/useEscapeEvent'

const ModalSignOut = (props) => {
  const { setIsShowPopupLogout, countryCode } = props

  useEscapeEvent(() => setIsShowPopupLogout(false))
  const handleSignOut = async () => {
    CommonUtils.handleSignOut(() => {
      window.location.href = '/?log_out_success=true'
    })
  }

  const logo = countryCode === 'ph' ? LOG_OUT_ICON_LOGO_PH : LOG_OUT_ICON_LOGO

  return (
    <div className="modal-confirm-logout">
      <div className="Modal vertical-scroll visible" id="Modal-logout-as-admin">
        <div className="Popup">
          <div className="Box Radius-five">
            <div className="confirm-logout--container flex-center flex-column">
              <div className="confirm-logout-logo mt20 mb30">
                <img height="79" src={logo} alt="Icon transportify green" />
              </div>
              <div className="confirm-logout-text larger-xxs-font mt30">
                {I18n.t('batches.label.popup_confirm_title')}
              </div>
            </div>
            <div className="confirm-logout-action flex mt40 ml10 mr10 mar20">
              <button
                className="Button default-font Button-Large default-medium-font gray flex-index mr5"
                data-id="Modal-logout-as-admin"
                type="button"
                onClick={() => setIsShowPopupLogout(false)}
              >
                {I18n.t('webapp.action.cancel')}
              </button>
              <button
                type="button"
                className="Button default-font Button-Large default-medium-font green flex-index ml5"
                id="button-logout"
                onClick={() => handleSignOut()}
              >
                {I18n.t('label.log_out')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ModalSignOut.propTypes = {
  setIsShowPopupLogout: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
}

export default withNavigate(ModalSignOut)
